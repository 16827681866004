import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { AlertSeverity } from 'types';

type BannerProps = {
  show: boolean;
  type: AlertSeverity;
  children: React.ReactNode;
  dataTestId?: string;
  showClasses: string[];
};

const Banner = (props: BannerProps) => {
  const baseClassnames = useMemo(() => {
    const classes = [
      'tw-rounded',
      'tw-text-sm',
      'tw-transition-all',
      'tw-duration-500',
      'tw-ease-in-out',
    ];
    switch (props.type) {
      case 'success':
        classes.push('tw-bg-turqoise-light');
        break;
      case 'error':
        classes.push('tw-bg-red-pink');
        break;
    }
    return classes;
  }, [props.type]);
  const hideClasses = useMemo(
    () => ['tw-opacity-0', 'tw-h-0', 'tw-max-h-0'],
    []
  );
  const showClasses = useMemo(
    () => ['tw-opacity-1000', 'tw-h-auto', 'tw-p-5', ...props.showClasses],
    [props]
  );
  const hiddenClasses = useMemo(
    () => [...baseClassnames, ...hideClasses],
    [baseClassnames, hideClasses]
  );
  const visibleClasses = useMemo(
    () => [...baseClassnames, ...showClasses],
    [baseClassnames, showClasses]
  );

  const [className, setClassName] = useState(hiddenClasses);

  useEffect(() => {
    if (props.show) {
      setClassName(visibleClasses);
    } else {
      setClassName(hiddenClasses);
    }
  }, [props.show, visibleClasses, hiddenClasses]);

  return (
    <div className={classNames(className)} data-testid={props.dataTestId}>
      {props.show && props.children}
    </div>
  );
};

export { Banner };
