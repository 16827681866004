import useCurrentUser from 'contexts/UserContext';
import { Button } from 'Components';
import { ReactComponent as UserAvatar } from 'Assets/icons/user-avatar.svg';
export const LoggedOutNav = () => {
  const { login } = useCurrentUser();

  return (
    <div className="tw-flex">
      <div className="md:tw-flex md:tw-flex">
        <div className="md:tw-flex tw-hidden navbar-item tw-pr-0">
          <Button
            type="quaternary"
            to="/create-account"
            className={['hover:tw-no-underline', 'tw-pr-3']}
          >
            <div className="tw-text-sm">Create account</div>
          </Button>
        </div>
        <div className="md:tw-flex tw-hidden navbar-item tw-pr-0">
          <Button
            type="quaternary"
            onClick={() => login()}
            className={['hover:tw-no-underline']}
          >
            <div className="tw-text-sm">Log in</div>
          </Button>
        </div>
      </div>
      <div
        className="tw-flex md:tw-hidden tw-cursor-pointer"
        onClick={() => login()}
      >
        <UserAvatar />
      </div>
    </div>
  );
};
