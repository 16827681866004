import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { reducer } from './reducer';
import type {
  LoanType,
  AgentUser,
  UserUpdateMessage,
  AbridgedLoanOfficer,
  MarketingRequest,
  MLSAssociation,
  AgentUserStats,
} from 'types';
import { ActionType } from './actions';
import { login as oktaLogin, logout as oktaLogout } from 'lib/okta';
import { loan as loanApi } from 'api';
import {
  addSortDateAndStatusToLoans,
  agentStatsFromLoans,
  sortLoanDateDescending,
  uniqAndActiveLoanOfficersFromLoans,
} from 'lib/loans';
import { initialMarketingMaterialValues } from 'lib/marketing-utils';
import { uniqueLoId } from 'lib/util';

const LOANS_REFRESH_INTERVAL = 15 * 60 * 1000;

export type UserContextType = {
  user?: AgentUser;
  clearLocalStorage: () => void;
  loans: LoanType[];
  loanStats?: AgentUserStats | null;
  loansError: Error | null;
  loansLoading: boolean;
  userLoading: boolean;
  getLoans: () => void;
  login: (redirectPath?: string) => void;
  logout: () => void;
  setLoans: (loans: LoanType[]) => void;
  addLoanOfficer: (officer: AbridgedLoanOfficer) => void;
  setUser: (user: AgentUser) => void;
  userUpdateMessage?: UserUpdateMessage;
  uniqueActiveLoanOfficers: AbridgedLoanOfficer[];
  addedLoanOfficers: AbridgedLoanOfficer[];
  marketingRequest: MarketingRequest;
  setMarketingRequest: (marketingRequest: MarketingRequest) => void;
  hasAttemptedInitialUserDataFetch: boolean;
  setHasAttemptedInitialUserDataFetch: (
    hasAttemptedInitialUserDataFetch: boolean
  ) => void;
  setMlaAssociations: (mlaAsscociation: MLSAssociation[]) => void;
  mlsAssociations: MLSAssociation[];
};

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

const generateInitialState = (
  userLS?: AgentUser,
  mlsAssociationsLS?: MLSAssociation[],
  addedLoanOfficersLS?: AbridgedLoanOfficer[]
): UserContextType => {
  let state: UserContextType = {
    clearLocalStorage: () => {},
    loans: [],
    loanStats: null,
    loansError: null,
    loansLoading: false,
    userLoading: true,
    uniqueActiveLoanOfficers: [] as AbridgedLoanOfficer[],
    getLoans: () => {},
    login: (redirectPath?: string) => {},
    logout: () => {},
    setLoans: () => {},
    addLoanOfficer: () => {},
    addedLoanOfficers: [] as AbridgedLoanOfficer[],
    setUser: () => {},
    marketingRequest: initialMarketingMaterialValues,
    setMarketingRequest: () => {},
    hasAttemptedInitialUserDataFetch: false,
    setHasAttemptedInitialUserDataFetch: () => {},
    setMlaAssociations: () => {},
    mlsAssociations: [] as MLSAssociation[],
  };
  if (userLS) {
    state.user = userLS;
    state.userLoading = false;
  }
  if (mlsAssociationsLS) {
    state.mlsAssociations = mlsAssociationsLS;
  }
  if (addedLoanOfficersLS) {
    state.addedLoanOfficers = addedLoanOfficersLS;
  }
  return state;
};

const mergeUniqueLoanOfficers = (
  existingOfficers: AbridgedLoanOfficer[],
  newOfficers: AbridgedLoanOfficer[]
): AbridgedLoanOfficer[] => {
  const allLoanOfficers = [...existingOfficers];
  newOfficers.forEach((officer) => {
    if (!allLoanOfficers.some((o) => o.uniqueLoId === officer.uniqueLoId)) {
      allLoanOfficers.push(officer);
    }
  });
  return allLoanOfficers;
};

export function UserProvider({ children }: { children: ReactNode }) {
  const [userLS, setUserLS, { removeItem: removeUserLS }] =
    useLocalStorageState<AgentUser | undefined>('agentUser', {
      defaultValue: undefined,
    });

  const [loansLS, setLoansLS, { removeItem: removeLoansLS }] =
    useLocalStorageState<LoanType[]>('loans', {
      defaultValue: [],
    });

  const [
    mlsAssociationsLS,
    setmlsAssociationsLS,
    { removeItem: removemlsAssociationsLS },
  ] = useLocalStorageState<MLSAssociation[]>('agentMlsAssociation');
  const [
    addedLoanOfficersLS,
    setAddedLoanOfficersLS,
    { removeItem: removeAddedLoanOfficersLS },
  ] = useLocalStorageState<AbridgedLoanOfficer[]>('addedLoanOfficers');

  const [state, dispatch] = useReducer(
    reducer,
    generateInitialState(userLS, mlsAssociationsLS, addedLoanOfficersLS)
  );

  const {
    loansLoading,
    loansError,
    loanStats,
    user,
    marketingRequest,
    hasAttemptedInitialUserDataFetch,
    mlsAssociations,
    addedLoanOfficers,
    uniqueActiveLoanOfficers,
    userLoading,
  } = state;

  useEffect(() => {
    dispatch({
      type: ActionType.SET_USER_LOADING,
      payload: !userLS,
    });
  }, [userLS]);

  const login = (redirectPath?: string | null | undefined) => {
    oktaLogin(redirectPath);
  };

  const clearLocalStorage = () => {
    removeUserLS();
    removeLoansLS();
    removemlsAssociationsLS();
  };

  const logout = () => {
    removeAddedLoanOfficersLS();
    dispatch({ type: ActionType.LOGOUT });
    clearLocalStorage();
    oktaLogout();
  };

  const setMarketingRequest = (marketingReqData: MarketingRequest) => {
    dispatch({
      type: ActionType.MARKETING_REQUEST,
      payload: marketingReqData,
    });
  };

  const setHasAttemptedInitialUserDataFetch = (
    hasAttemptedInitialUserDataFetch: boolean
  ) => {
    dispatch({
      type: ActionType.SET_HAS_ATTEMPTED_INITIAL_USER_DATA_FETCH,
      payload: hasAttemptedInitialUserDataFetch,
    });
  };

  const addLoanOfficer = (officer: AbridgedLoanOfficer) => {
    const updatedAddedLoanOfficers = [...addedLoanOfficers, officer];
    setAddedLoanOfficersLS(updatedAddedLoanOfficers);
    const updatedUniqueActiveLoanOfficers = mergeUniqueLoanOfficers(
      uniqueActiveLoanOfficers,
      updatedAddedLoanOfficers
    );
    dispatch({
      type: ActionType.SET_UNIQUE_LOAN_OFFICERS,
      uniqueActiveLoanOfficers: updatedUniqueActiveLoanOfficers,
    });
  };

  const setMlaAssociations = useCallback(
    (mlsAssociations: MLSAssociation[]) => {
      dispatch({
        type: ActionType.SET_MLS_ASSOCIATIONS,
        mlsAssociations,
      });
      setmlsAssociationsLS(mlsAssociations);
    },
    [setmlsAssociationsLS]
  );

  const getLoans = useCallback(async () => {
    dispatch({ type: ActionType.SET_LOANS_LOADING, payload: true });
    const getLoansResponse = await loanApi.allLoans();
    if (getLoansResponse.ok) {
      const { loans = [] } = getLoansResponse;
      const enhancedLoans = sortLoanDateDescending(
        addSortDateAndStatusToLoans(loans)
      );
      setLoansLS(enhancedLoans);
      dispatch({
        type: ActionType.SET_LOAN_STATS,
        payload: agentStatsFromLoans(loans),
      });
      formUniqueActiveLoanOfficers(enhancedLoans);
    } else {
      dispatch({
        type: ActionType.SET_LOANS_ERROR,
        payload:
          getLoansResponse.error instanceof Error
            ? getLoansResponse.error
            : new Error('unknown error'),
      });
    }
    dispatch({ type: ActionType.SET_LOANS_LOADING, payload: false });
  }, [setLoansLS]);

  // silently refreshes loans (no loading spinner, ignores any errors)
  const refreshLoans = useCallback(async () => {
    const getLoansResponse = await loanApi.allLoans();
    if (getLoansResponse.ok) {
      const { loans = [] } = getLoansResponse;
      const enhancedLoans = sortLoanDateDescending(
        addSortDateAndStatusToLoans(loans)
      );
      setLoansLS(enhancedLoans);
      dispatch({
        type: ActionType.SET_LOAN_STATS,
        payload: agentStatsFromLoans(loans),
      });
    }
  }, [setLoansLS]);

  // Refresh loan data periodically
  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        refreshLoans();
      }, LOANS_REFRESH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [refreshLoans, user]);

  const formUniqueActiveLoanOfficers = (loans: LoanType[]) => {
    const uniqueLoanOfficers = uniqAndActiveLoanOfficersFromLoans(loans).map(
      (officer) => ({
        ...officer,
        uniqueLoId: uniqueLoId(officer.company, officer.loanOfficerId),
      })
    );
    const updatedUniqueLoanOfficers = mergeUniqueLoanOfficers(
      uniqueLoanOfficers,
      addedLoanOfficers
    );
    dispatch({
      type: ActionType.SET_UNIQUE_LOAN_OFFICERS,
      uniqueActiveLoanOfficers: updatedUniqueLoanOfficers,
    });
  };

  return (
    <UserContext.Provider
      value={{
        clearLocalStorage,
        user: userLS,
        loanStats,
        getLoans,
        loans: loansLS,
        loansError,
        loansLoading,
        userLoading,
        login,
        logout,
        setLoans: setLoansLS,
        addLoanOfficer,
        addedLoanOfficers,
        setUser: setUserLS,
        uniqueActiveLoanOfficers,
        marketingRequest,
        setMarketingRequest,
        hasAttemptedInitialUserDataFetch,
        setHasAttemptedInitialUserDataFetch,
        mlsAssociations,
        setMlaAssociations,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default function useCurrentUser() {
  return useContext(UserContext);
}
