export type ProfileSizeClassesSize = 8 | 18 | 19 | 20 | 28 | 32;

const profileSizeClasses = (size: number) => {
  let sizeClasses = [];
  switch (size) {
    case 8:
      sizeClasses = ['tw-w-8', 'tw-h-8'];
      break;
    case 18:
      sizeClasses = ['tw-w-18', 'tw-h-18'];
      break;
    case 19:
      sizeClasses = ['tw-w-19', 'tw-h-19'];
      break;
    case 20:
      sizeClasses = ['tw-w-20', 'tw-h-20'];
      break;
    case 28:
      sizeClasses = ['tw-w-18', 'tw-h-18', 'md:tw-w-28', 'md:tw-h-28'];
      break;
    case 32:
    default:
      sizeClasses = ['tw-w-32', 'tw-h-32', 'md:tw-w-32', 'md:tw-h-32'];
      break;
  }
  return sizeClasses;
};

const imageBaseClasses = [
  'tw-overflow-hidden',
  'tw-object-cover',
  'tw-border-0',
];
const profileCircleClasses = (size: ProfileSizeClassesSize) => [
  'tw-rounded-full',
  ...imageBaseClasses,
  ...profileSizeClasses(size),
];

const logoRectClasses = [
  'tw-w-40',
  'tw-max-w-[132px]',
  'md:tw-max-w-[192px]',
  ...imageBaseClasses,
];

const roundedRectClasses = [
  'tw-rounded-xl',
  'md:tw-max-w-[192px]',
  ...imageBaseClasses,
];

export {
  logoRectClasses,
  profileCircleClasses,
  profileSizeClasses,
  roundedRectClasses,
};
