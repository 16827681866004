import type { AgentUserStats } from 'types';

const testStats = {
  rollingYear: {
    averageCtc: 2.6,
  },
  monthToDate: {
    funded: {
      loansCount: 1,
      salesVolume: 340999,
    },
    projectedClosed: {
      loansCount: 2,
      salesVolume: 1100000,
    },
  },
};

const zeroStats = {
  rollingYear: {
    averageCtc: 0,
  },
  monthToDate: {
    funded: {
      loansCount: 0,
      salesVolume: 0,
    },
    projectedClosed: {
      loansCount: 0,
      salesVolume: 0,
    },
  },
};

const showAverageCtc = (stats: AgentUserStats) =>
  !!stats.rollingYear.averageCtc
    ? stats.rollingYear.averageCtc > 0 && stats.rollingYear.averageCtc < 10
    : false;

const totalLoans = (stats: AgentUserStats) =>
  stats.monthToDate.funded.loansCount +
  stats.monthToDate.projectedClosed.loansCount;

const closingsSingularOrPlural = (count: number) =>
  count === 1 ? 'closing' : 'closings';

export {
  showAverageCtc,
  totalLoans,
  closingsSingularOrPlural,
  testStats,
  zeroStats,
};
