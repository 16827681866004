import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)<ButtonProps>(({ theme, color }) => ({
  color: color === 'error' ? '#C12950' : '#2175DA',
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1rem',
  borderRadius: '0.6rem',
  backgroundColor: color === 'error' ? '#EFC9D3' : '#fff',
  borderColor: '#D8D8D8',
  height: '4.5rem',
  padding: '1rem',
  whiteSpace: 'nowrap',
  width: '100%',
  '&:hover': {
    backgroundColor: color === 'error' ? '#EFC9D3' : '#EEF4FC',
  },
  transitionProperty: 'all',
  '&:active': {
    transform: 'scale(0.9)',
  },
}));

const BlueprintOutlineButton = (props: ButtonProps) => (
  <StyledButton
    variant="outlined"
    color="secondary"
    disableRipple={true}
    disableFocusRipple={true}
    disableElevation
    {...props}
  />
);

export { BlueprintOutlineButton };
