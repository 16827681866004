import type { ButtonTypes } from 'types';
import useAppContext from 'contexts/AppContext';
import LoaderWhiteIcon from 'Assets/icons/loader-white.svg';
import LoaderQuatenary from 'Assets/icons/loader-quaternary.svg';
import LoaderBlueIcon from 'Assets/icons/loader-blue.svg';
import LoaderGrayIcon from 'Assets/icons/gray-spinner.svg';

export function Spinner(props: { type: ButtonTypes }) {
  const { type } = props;
  const { tenant } = useAppContext();
  let iconSrc = LoaderWhiteIcon;
  switch (type) {
    case 'secondary':
      if (tenant.id === 'GRA') {
        iconSrc = LoaderBlueIcon;
      } else {
        iconSrc = LoaderGrayIcon;
      }
      break;
    case 'quaternary':
      iconSrc = LoaderQuatenary;
      break;
    default:
      iconSrc = LoaderWhiteIcon;
  }
  return (
    <div className="tw-flex tw-items-center tw-gap-1 tw-font-bold tw-h-6 tw-w-6">
      <img
        src={iconSrc}
        className="tw-animate-spin tw-h-6 tw-w-6"
        role="alert"
        aria-busy="true"
        alt=""
      />
    </div>
  );
}
