import { faker } from '@faker-js/faker';

import type { OpenHouse } from 'types';

const randomBoolean = () => Math.random() < 0.5;

const generateOpenHouseTestRecord = (): OpenHouse => {
  const financing = randomBoolean();
  return {
    id: faker.string.uuid(),
    address: {
      line1: faker.location.streetAddress(),
      city: faker.location.city(),
      state: faker.location.state({ abbreviated: true }),
      zip: faker.location.zipCode(),
    },
    formParams: {
      email: randomBoolean(),
      phone: randomBoolean(),
      hasAgent: randomBoolean(),
      purchaseTimeframe: randomBoolean(),
      sellPrerequisite: randomBoolean(),
      financing: financing,
    },
    loanOfficer: {
      loanOfficerId: financing ? faker.number.int({ min: 1000, max: 9999 }) : 0,
      company: 'gri',
    },
    createdAt: faker.date.past({ years: 1 }),
    modifiedAt: faker.date.past(),
  };
};

const generateOpenHouseTestRecords = (n: number): OpenHouse[] =>
  [...Array(n)].map(() => generateOpenHouseTestRecord());

export { generateOpenHouseTestRecord, generateOpenHouseTestRecords };
