import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { users as usersApi } from 'api';
import { isAuthenticated } from 'lib/okta';
import { dispatchTrackingEvent } from 'lib/tracking';
import useCurrentUser from './UserContext';

/*
  Upon entering a private route, if a user is logged in, the app should attempt to fetch
  the user's private data, such as Agent profile and loans. This should happen once per app load.

  Call this hook in your private routes to ensure that initial fetch is attempted.
*/
export const useEnsurePrivateDataInitialization = () => {
  const navigate = useNavigate();

  const {
    clearLocalStorage,
    getLoans,
    setUser,
    hasAttemptedInitialUserDataFetch,
    setHasAttemptedInitialUserDataFetch,
  } = useCurrentUser();

  // checks if user is authenticated. if so, get the agent's profile data and loans
  useEffect(() => {
    if (!hasAttemptedInitialUserDataFetch) {
      setHasAttemptedInitialUserDataFetch(true);
      if (isAuthenticated()) {
        const setupSession = async () => {
          clearLocalStorage();
          const getUserResponse = await usersApi.getAgentUser();
          if (getUserResponse.ok && getUserResponse.agent) {
            setUser(getUserResponse.agent);
            dispatchTrackingEvent({
              eventName: 'login',
              email: getUserResponse.agent.loginEmail,
            });
            getLoans();
          }
        };
        setupSession();
      }
    }
  }, [
    clearLocalStorage,
    getLoans,
    hasAttemptedInitialUserDataFetch,
    navigate,
    setHasAttemptedInitialUserDataFetch,
    setUser,
  ]);
};
