import { Alert, Button, Dialog } from 'Components';
import { useCallback, useEffect, useState } from 'react';
import {
  EmailVerificationJwtClaims,
  EmailVerificationRequestBody,
} from 'types';
import { users as userApi } from 'api';

interface ExpiredTokenModalProps {
  open: boolean;
  emailVerificationJwtClaims?: EmailVerificationJwtClaims | null;
  onClose: () => void;
}

export const ExpiredTokenModal = ({
  open,
  emailVerificationJwtClaims,
  onClose,
}: ExpiredTokenModalProps) => {
  const [apiStatus, setApiStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');

  const resendVerificationEmail = useCallback(
    async (email: string, loanGuid?: string | undefined) => {
      setApiStatus('loading');
      const verificationEmailBody: EmailVerificationRequestBody = {
        email,
        loanGuid,
      };
      const result = await userApi.sendVerificationEmailForSignup(
        verificationEmailBody
      );
      if (result.ok) {
        setApiStatus('succeeded');
      } else {
        setApiStatus('failed');
      }
    },
    []
  );

  useEffect(() => {
    if (apiStatus === 'idle' && emailVerificationJwtClaims?.sub && open) {
      resendVerificationEmail(
        emailVerificationJwtClaims.sub,
        emailVerificationJwtClaims?.['loan-guid']
      );
    }
  }, [apiStatus, open, emailVerificationJwtClaims, resendVerificationEmail]);

  return (
    <Dialog
      open={open}
      onClose={(_evt, reason) => reason !== 'backdropClick' && onClose()}
    >
      <div className="tw-px-8 tw-pb-8 md:tw-w-[32rem]">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-ravenswood">
          Verification link expired
        </h3>
        <p className="tw-text-base tw-text-gray-ravenswood">
          {apiStatus === 'idle' && (
            <>Looks like this link has already expired.</>
          )}
          {apiStatus === 'loading' && (
            <>
              Looks like this link has already expired.
              <br />
              Hang on, we're sending a new link to{' '}
              <strong>{emailVerificationJwtClaims?.sub}</strong>.
            </>
          )}
          {apiStatus === 'succeeded' && (
            <>
              Looks like this link has already expired.
              <br />
              We've just sent you a new one. Please check your email inbox at{' '}
              <strong>{emailVerificationJwtClaims?.sub}</strong>.
            </>
          )}
          {apiStatus === 'failed' && (
            <>
              <Alert show severity={'error'} message={''} showClasses={[]} />
              Looks like this link has already expired. Please{' '}
              <Button
                type="quaternary"
                className={['tw-underline']}
                to="/create-account"
              >
                click here to send a new link.
              </Button>
              to
            </>
          )}
        </p>
      </div>
    </Dialog>
  );
};
