import RateLogo from 'Assets/logos/Companies/gri/logo.svg';
import graLogo from 'Assets/logos/Companies/gra/logo.svg';
import properRateLogo from 'Assets/logos/Companies/proper-rate/logo.svg';
import citywideLogo from 'Assets/logos/Companies/citywide/logo.png';
import certaintyLogo from 'Assets/logos/Companies/certainty/logo.png';

import { TenantConfig } from 'types';
import { defaultTenantColors, getTenant } from 'lib/tenant';

const tenantDetails: { [companySlug: string]: any } = {
  GRI: {
    copyrightStartYear: 2000,
    companyURL: 'https://rate.com',
    companyAddress: '3940 N. Ravenswood Ave, Chicago, IL 60613',
    dmURL: 'https://apply.guaranteedrate.com',
    flashclose: '/resources/flashclose',
    logo: RateLogo,
    nmls: 'NMLS License #2611',
    showMobileLoanApp: true,
    button: 'is-primary',
    textColor: 'has-text-danger',
    financialSolutions: {
      sameDayMortgage: 'https://www.rate.com/same-day-mortgage',
      homeLoans: 'https://www.rate.com/home-loans',
      refinance: 'https://www.rate.com/refinance-mortgage',
      heloc: 'https://www.rate.com/heloc',
      homeEquityConversion: 'https://www.rate.com/reverse-hecm-mortgage',
      personalLoans: 'https://www.rate.com/personal-loans',
      insurance: 'https://www.rate.com/insurance',
    },
    footer: {
      links: {
        aboutUs: '/about-us',
        careers: 'https://careers.rate.com/',
        insurance: 'https://www.guaranteedrateinsurance.com/',
        delawareLOs: 'https://www.rate.com/delaware-licensed-loan-officers',
        licensing: '/licensing',
        loanOfficers: '/find-loan-officer',
        nmls: 'http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2611',
        doNotSell:
          'https://privacyportal-cdn.onetrust.com/dsarwebform/168096e5-faa8-4fdd-a479-992231adbdc1/70bd9394-cadd-46fa-8eaf-11c1f5523029.html',
        smsTerms: '/sms-terms',
        terms: '/terms',
        news: '/news',
        knowledgeCenter: '/resources',
        branchLocations: '/about-us/office-locations',
        customerReviews: '/about-us/customer-first',
        contactUs: '/about-us/contact-us',
      },
      blurbs: {
        disclaimer:
          'The company name, Guaranteed Rate, should not suggest to a consumer that Guaranteed Rate provides an interest rate guarantee prior to an interest rate lock.',
      },
    },
    social: {
      youtube: 'https://www.youtube.com/channel/UCc4dTfT3Eh1lU59jjsp4EuQ',
      instagram: 'https://www.instagram.com/guaranteedrate/',
      linkedin: 'http://www.linkedin.com/company/guaranteed-rate',
      twitter: 'http://www.twitter.com/guaranteedrate',
      facebook: 'https://www.facebook.com/groups/agentadvantageinsider/',
    },
    colors: defaultTenantColors,
  },
  GRA: {
    copyrightStartYear: 2017,
    companyURL: 'https://grarate.com',
    companyAddress: '1800 W. Larchmont Ave, Chicago, IL 60613',
    dmURL: 'https://apply.grarate.com',
    flashclose: '/flashclose',
    logo: graLogo,
    nmls: 'NMLS License #1598647',
    button: 'gra-button',
    textColor: 'is-gra-blue',
    footer: {
      links: {
        aboutUs: '/about-us',
        careers: 'https://grarate.com/careers',
        disclaimer: '/disclaimer',
        legal: '/legal',
        licensing: '/licensing',
        loanOfficers: '/find-loan-officer',
        nmls: 'http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1598647',
        doNotSell:
          'https://privacyportal-cdn.onetrust.com/dsarwebform/168096e5-faa8-4fdd-a479-992231adbdc1/dcdff4f7-5794-472f-8063-c2e1bc9f0c8f.html',
        smsTerms: '/sms-terms',
        terms: '/termsofuse',
        knowledgeCenter: '/articles',
        contactUs: '/contact-us',
      },
      blurbs: {
        dba: 'Operating in the state of New York as GR Affinity, LLC in lieu of the legal name Guaranteed Rate Affinity, LLC',
      },
    },
    colors: {
      primary: {
        body: {
          base: 'gray-ravenswood',
          dark: 'gray-raven125',
        },
        brand: {
          base: 'blue-gra',
          lightest: 'blue-light',
          dark: 'blue-darker',
          outline: 'blue-gra',
        },
      },
      secondary: {
        action: {
          base: 'blue-link-darker',
          dark: 'blue-link-darkest',
        },
        inactive: {
          light: 'gray-inactive',
        },
      },
    },
  },
  PR: {
    copyrightStartYear: 2020,
    companyURL: 'https://properrate.com',
    companyAddress: '1800 W. Larchmont Ave, Suite 301, Chicago, IL 60613',
    dmURL: 'https://apply.properrate.com',
    logo: properRateLogo,
    nmls: 'NMLS License #1901699',
    button: 'is-primary',
    textColor: 'is-pr-gold',
    footer: {
      links: {
        aboutUs: '/about-us',
        careers: 'https://properrate.com/careers',
        disclaimer: '/disclaimer',
        legal: '/legal',
        licensing: '/licensing',
        loanOfficers: '/find-loan-officer',
        nmls: 'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1901699',
        doNotSell:
          'https://privacyportal-cdn.onetrust.com/dsarwebform/168096e5-faa8-4fdd-a479-992231adbdc1/70bd9394-cadd-46fa-8eaf-11c1f5523029.html',
        smsTerms: '/sms-terms',
        terms: '/termsofuse',
        knowledgeCenter: '/articles',
        contactUs: '/contact-us',
      },
    },
    colors: defaultTenantColors,
  },
  CW: {
    copyrightStartYear: 1998,
    companyURL: 'https://www.citywidehm.com',
    companyAddress: '9785 South Monroe St, Suite 200 Sandy, UT 84070',
    dmURL: 'https://apply.citywidehm.com',
    logo: citywideLogo,
    nmls: 'NMLS License #2611',
    button: 'is-primary',
    textColor: 'has-text-danger',
    footer: {
      links: {
        aboutUs: '/about/',
        branchLocations: '/locations/',
        careers: 'https://www.citywidecareers.com/',
        contactUs: '/contact-us/',
        knowledgeCenter: '/articles',
        licensing: '/licensing-information/',
        loanOfficers: '/loan-officers/',
        nmls: 'https://nmlsconsumeraccess.org/',
        doNotSell:
          'https://privacyportal.onetrust.com/webform/168096e5-faa8-4fdd-a479-992231adbdc1/1e7a5fe9-a396-4b90-afb8-3661e92f6558',
        smsTerms: '/sms-terms',
        terms: '/terms-of-use-privacy-policy/',
      },
    },
    colors: defaultTenantColors,
  },
  CERTAINTY: {
    companyURL: 'https://www.certaintyhomelending.com',
    companyAddress: '3940 N. Ravenswood Ave, Chicago, IL 60613',
    dmURL: 'https://apply.certaintyhomelending.com',
    logo: certaintyLogo,
    nmls: 'NMLS License #2611',
    button: 'is-primary',
    textColor: 'has-text-danger',
    footer: {
      links: {
        aboutUs: '/about-us/',
        careers: 'https://www.certaintyhomelending.com/careers/',
        contactUs: '/contact-us/',
        knowledgeCenter: '/learning-center/',
        licensing: '/licensing/',
        loanOfficers: '/find-your-loan-officer/',
        nmls: 'https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2611',
        doNotSell: 'mailto:privacy@rate.com',
        privacy: '/privacy-center/',
        smsTerms: '/sms-terms',
        terms: '/terms-of-use/',
      },
    },
    colors: defaultTenantColors,
  },
};

let companies: { [companySlug: string]: TenantConfig } = {};

if (window.config) {
  for (const tenant in window.config.tenants) {
    companies[tenant] = {
      ...{
        id: tenant,
        companyName: window.config.tenants[tenant].name || '',
      },
      ...tenantDetails[tenant],
    };
  }
} else {
  companies = {
    GRI: {
      ...{ id: 'GRI', companyName: 'Guaranteed Rate, Inc.' },
      ...tenantDetails['GRI'],
    },
    GRA: {
      ...{ id: 'GRA', companyName: 'Guaranteed Rate Affinity, LLC' },
      ...tenantDetails['GRA'],
    },
    PR: {
      ...{ id: 'PR', companyName: 'Proper Rate, LLC' },
      ...tenantDetails['PR'],
    },
    OP: {
      ...{ id: 'OP', companyName: 'Origin Point, LLC' },
      ...tenantDetails['OP'],
    },
    CW: {
      ...{ id: 'CW', companyName: 'Citywide Home Loans, LLC' },
      ...tenantDetails['CW'],
    },
    CERTAINTY: {
      ...{ id: 'CERTAINTY', companyName: 'Certainty Home Lending' },
      ...tenantDetails['certainty'],
    },
  };
}

let config: TenantConfig | undefined;

const getTenantConfig = (): TenantConfig =>
  config ?? companies[getTenant(window.location.host) || 'GRI'];

type objectType = { [key: string]: any };

const featureConfigByEnv: objectType = {
  referral: {
    mortgageApplication: ['dev', 'staging'],
  },
};

const featureFlags = () => {
  const instanceHosts: { [key: string]: string[] } = {
    dev: [
      'localhost:3001',
      'agents.gr-dev.com',
      'agents-gra.gr-dev.com',
      'agents.prate-dev.com',
    ],
    staging: [],
    prod: ['agents.rate.com', 'agents.grarate.com', 'agents.properrate.com'],
  };
  const hostLowercase = window.location.host.toLowerCase();
  let env = '';
  for (const thisEnv in instanceHosts) {
    if (instanceHosts[thisEnv].includes(hostLowercase)) {
      env = thisEnv;
    }
  }
  const flags: objectType = {};
  for (const module in featureConfigByEnv) {
    flags[module] = {};
    for (const feature in featureConfigByEnv[module]) {
      flags[module][feature] =
        featureConfigByEnv[module][feature].includes(env);
    }
  }
  return flags;
};

export { getTenantConfig, featureFlags };
