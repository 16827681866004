import { Link } from 'react-router-dom';
import type { OpenHouse } from 'types';
import { capitalCase, formatCityStateZip } from 'lib/string';
import { OpenHouseContextMenu } from './ContextMenu';
import { useWindowDimensions } from 'lib/hooks';
import { tabletDimension } from 'Components/Common/Utils';

import { ReactComponent as EllipsisVertical } from 'Assets/icons/ellipsis-vertical.svg';
import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';

const OpenHouseRow = (props: { openHouse: OpenHouse }) => {
  const { openHouse } = props;
  const { id = '', address } = openHouse;
  const { width } = useWindowDimensions();
  const isMobile = width && width <= tabletDimension;

  const line1 = capitalCase(address?.line1 || '');
  const Property = () => (
    <div className="tw-text-gray-ravenswood hover:tw-text-gray-ravenswood active:tw-text-gray-ravenswood tw-leading-6">
      {line1 && (
        <p className="tw-block md:tw-inline tw-m-0">
          {line1}
          <span className="tw-hidden md:tw-inline">, </span>
        </p>
      )}
      {address?.line2 && (
        <p className="tw-block md:tw-inline tw-m-0">
          {address?.line2}
          <span className="tw-hidden md:tw-inline">, </span>
        </p>
      )}
      <p className="tw-block md:tw-inline tw-m-0">
        {formatCityStateZip(
          capitalCase(address.city || ''),
          address.state,
          address.zip
        )}
      </p>
    </div>
  );
  return isMobile ? (
    <div className="tw-border-t tw-border-gray-neutral tw-px-2 tw-py-2 tw-flex tw-flex-row tw-items-center tw-justify-between  md:tw-hidden odd:tw-bg-white even:tw-bg-bluegrey-hover">
      <Property />
      <OpenHouseContextMenu id={id}>
        <EllipsisVertical />
      </OpenHouseContextMenu>
    </div>
  ) : (
    <Link
      className="tw-hidden md:tw-flex tw-py-2 tw-pl-3 tw-pr-2 tw-border tw-border-gray-neutral tw-rounded-xl tw-bg-white tw-flex-row tw-items-center tw-justify-between hover:tw-bg-bluegrey-hover"
      to={`/open-house/${openHouse.id}`}
    >
      <Property />
      <div className="tw-w-7 tw-h-7 tw-flex tw-justify-center tw-items-center">
        <ChevronRight />
      </div>
    </Link>
  );
};

export { OpenHouseRow };
