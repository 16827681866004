import useCurrentUser from 'contexts/UserContext';

import type { AgentUserStats } from 'types';

import { inLocalTestModeByEnvVar, numberFormatOptions } from 'lib/util';
import {
  showAverageCtc,
  totalLoans,
  closingsSingularOrPlural,
  testStats,
  zeroStats,
} from './util';

import { ReactComponent as ChevronRightArrow } from 'Assets/icons/arrow-chevron-right-small.svg';

const useTestData = inLocalTestModeByEnvVar('CLOSING_STATS');

type LoanProgressBarProps = {
  totalLoans: number;
  completedLoansCount: number;
};
const LoansProgressBar = ({
  totalLoans,
  completedLoansCount,
}: LoanProgressBarProps) => {
  const flexBasis =
    totalLoans > 0
      ? `${((totalLoans - completedLoansCount) / totalLoans) * 100}%`
      : '100%';
  return (
    <div className="tw-bg-green-accent tw-h-2 md:tw-h-1 tw-w-full tw-rounded-lg md:tw-rounded tw-flex tw-flex-row tw-justify-end">
      <div className="tw-bg-gray-neutral tw-rounded-lg" style={{ flexBasis }} />
    </div>
  );
};

const ClosingStats = () => {
  const { loansError, loansLoading, loanStats } = useCurrentUser();
  const stats: AgentUserStats = useTestData
    ? testStats
    : loanStats || zeroStats;
  const showAvgCtc = showAverageCtc(stats);
  return (
    <div
      data-testid="agent-closing-stats"
      className={loansError || loansLoading ? 'tw-hidden' : ''}
    >
      <div
        className={`tw-flex tw-flex-col tw-gap-2 ${
          showAvgCtc ? '' : 'tw-mt-3'
        } tw-mb-1`}
      >
        {showAvgCtc && (
          <h4
            className="tw-mb-0 tw-text-gray-ravenswood tw-font-bold tw-text-base md:tw-text-lg tw-flex tw-flex-row tw-items-center tw-gap-2 md:tw-justify-end md:tw-mb-1"
            data-testid="agent-average-ctc"
          >
            <ChevronRightArrow className="tw-hidden md:tw-block" />
            Average CTC: {stats.rollingYear.averageCtc} days
          </h4>
        )}
        <LoansProgressBar
          totalLoans={totalLoans(stats)}
          completedLoansCount={stats.monthToDate.funded.loansCount}
        />
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-0 md:tw-gap-2">
            <p
              className="tw-mb-0 tw-text-gray-ravenswood"
              data-testid="agent-mtd-funded-count"
            >
              {stats.monthToDate.funded.loansCount} completed{' '}
              {closingsSingularOrPlural(stats.monthToDate.funded.loansCount)}
            </p>
            <p
              className="tw-mb-0 tw-text-gray-ravenswood tw-font-bold"
              data-testid="agent-mtd-funded-volume"
            >
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(
                stats.monthToDate.funded.salesVolume
              )}
            </p>
          </div>
          <div className="tw-text-right tw-flex tw-flex-col md:tw-flex-row tw-gap-0 md:tw-gap-2">
            <p
              className="tw-mb-0 tw-text-gray-ravenswood"
              data-testid="agent-mtd-projected-count"
            >
              {stats.monthToDate.projectedClosed.loansCount} projected{' '}
              {closingsSingularOrPlural(
                stats.monthToDate.projectedClosed.loansCount
              )}
            </p>
            <p
              className="tw-mb-0 tw-text-gray-ravenswood tw-font-bold"
              data-testid="agent-mtd-projected-volume"
            >
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(
                stats.monthToDate.projectedClosed.salesVolume
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ClosingStats };
