import { requestWithTimeoutAndOktaLogin } from './util';
import type { ApiResponse, JsonApiType, OpenHouse } from 'types';
import { inLocalTestModeByEnvVar } from 'lib/util';
import { generateOpenHouseTestRecords } from 'lib/test-data/open-house';

interface JsonApiOpenHouse extends JsonApiType {
  attributes: OpenHouse;
}

const useTestOpenHouseData = inLocalTestModeByEnvVar('OPEN_HOUSE');

const formatJsonApiOpenHouses = (openHouses: JsonApiOpenHouse[]): OpenHouse[] =>
  openHouses.map(
    (item: JsonApiOpenHouse) =>
      ({
        id: item.id,
        address: {
          line1: item.attributes.address.line1,
          line2: item.attributes.address?.line2 || '',
          city: item.attributes.address?.city || '',
          state: item.attributes.address?.state || '',
          zip: item.attributes.address?.zip || '',
        },
        formParams: {
          email: !!item.attributes.formParams.email,
          phone: !!item.attributes.formParams.phone,
          hasAgent: !!item.attributes.formParams.hasAgent,
          purchaseTimeframe: !!item.attributes.formParams.purchaseTimeframe,
          sellPrerequisite: !!item.attributes.formParams.sellPrerequisite,
          financing: !!item.attributes.formParams.financing,
        },
        loanOfficer: {
          loanOfficerId: item.attributes?.loanOfficer?.loanOfficerId || 0,
          company: item.attributes?.loanOfficer?.company || '',
        },
        createdAt: item.attributes.createdAt,
        modifiedAt: item.attributes.modifiedAt,
      } as OpenHouse)
  );

type GetPageParams = {
  lastId: string;
};
interface ErrorObject {
  code: string;
  title: string;
}

interface ErrorResponse {
  errors: ErrorObject[];
}

interface GetPageResponse extends ApiResponse {
  openHouses?: OpenHouse[];
  serverError?: ErrorResponse;
}

const getPage = async ({ lastId }: GetPageParams): Promise<GetPageResponse> => {
  if (useTestOpenHouseData) {
    // return {
    //   ok: false,
    //   error: 'Random error',
    // };
    // return {
    //   ok: true,
    //   openHouses: [],
    // };
    return {
      ok: true,
      openHouses: generateOpenHouseTestRecords(20),
    };
  }
  const lastIdInt = lastId ? parseInt(lastId) : 0;
  const url = `/v1/open-houses?last-id=${lastIdInt}`;
  try {
    const response = await requestWithTimeoutAndOktaLogin(url);
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let openHouses: OpenHouse[] = [];

    if (Array.isArray(response.responseObj?.data)) {
      openHouses = formatJsonApiOpenHouses(response.responseObj.data);
    }
    return { ok: true, openHouses };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
      serverError: responseObj,
    };
  }
};

const deleteOpenHouse = async (id: string): Promise<ApiResponse> => {
  const url = '/v1/open-houses/' + id;
  try {
    const response = await requestWithTimeoutAndOktaLogin(url, {
      method: 'DELETE',
    });
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }
    return { ok: true };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
    };
  }
};

export { deleteOpenHouse, getPage };
