import { Button, Dialog } from 'Components';

interface InvalidTokenModalProps {
  open: boolean;
  onClose: () => void;
}

export const InvalidTokenModal = ({
  open,
  onClose,
}: InvalidTokenModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={(_evt, reason) => reason !== 'backdropClick' && onClose()}
    >
      <div className="tw-px-8 tw-pb-8 md:tw-w-[32rem]">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-ravenswood">
          Invalid verification link
        </h3>
        <p className="tw-text-base tw-text-gray-ravenswood">
          Looks like this verification link isn't valid.
          <br />
          Please{' '}
          <Button
            type="quaternary"
            className={['tw-underline']}
            to="/create-account"
          >
            click here
          </Button>{' '}
          to send a new one.
        </p>
      </div>
    </Dialog>
  );
};
