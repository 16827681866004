import {
  AbridgedLoanOfficer,
  createLeadResponseType,
  ReferralFormType,
} from 'types';
import { post } from '.';

type GuidResponse = {
  data: { type: 'loan' | 'dm-invite' | 'email'; id: string };
  errors?: any;
};
type LoInviteData = {
  loanOfficerId: number;
  loanOfficerCompany: string;
  email: string;
  displayName: string;
};
type Borrower = {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  langPref?: string;
};
type CoBorrower = {
  firstName?: string;
  lastName?: string;
};
type CreateLeadRequestBody = {
  loanOfficerId: number;
  loanOfficerCompany: string;
  borrower: Borrower;
  coBorrower: CoBorrower;
};
type InviteRequestBody = {
  guid: string;
  loanOfficer: LoInviteData;
  borrower: Borrower;
  memo: string;
};

export async function createLead(
  referral: ReferralFormType,
  loanOfficer: AbridgedLoanOfficer
): Promise<createLeadResponseType> {
  const loanOfficerId = loanOfficer?.loanOfficerId || 0;
  const errorMessage =
    'Unable to send referral. Please retry or contact administrator.';
  const requestBody: CreateLeadRequestBody = {
    loanOfficerId,
    loanOfficerCompany: loanOfficer?.company || '',
    borrower: {
      firstName: referral.clientFirstName,
      lastName: referral.clientLastName,
    },
    coBorrower: {
      firstName: referral.coBorrowerFirstName,
      lastName: referral.coBorrowerLastName,
    },
  };
  if (referral.langPref) {
    requestBody.borrower.langPref = referral.langPref;
  }
  if (referral.clientPhone) {
    requestBody.borrower.phone = referral.clientPhone;
  }
  if (referral.clientEmail) {
    requestBody.borrower.email = referral.clientEmail;
  }
  try {
    const response = await post('/v1/loans', JSON.stringify(requestBody));
    const results = (await response.json()) as GuidResponse;
    const guid = results?.data?.id;
    if (guid) {
      const dmInviteBody: InviteRequestBody = {
        guid: guid,
        loanOfficer: {
          loanOfficerId,
          loanOfficerCompany: loanOfficer?.company || '',
          email: loanOfficer?.email || '',
          displayName: loanOfficer?.displayName || '',
        },
        borrower: {
          firstName: referral.clientFirstName,
          lastName: referral.clientLastName,
          phone: referral.clientPhone,
          email: referral.clientEmail,
        },
        memo: referral.clientMessage,
      };
      if (referral.langPref) {
        dmInviteBody.borrower.langPref = referral.langPref;
      }
      await Promise.all([
        dmInviteResponse(dmInviteBody),
        emailLeadToLo(dmInviteBody),
      ]);
      return { ok: response.ok, guid: guid };
    } else {
      if (results.errors?.length) {
        console.log(results.errors[0]?.title);
        return {
          ok: false,
          error: errorMessage,
        };
      }
      return {
        ok: false,
        error: errorMessage,
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: errorMessage,
    };
  }
}

async function dmInviteResponse(dmInviteBody: InviteRequestBody) {
  return await post('/v1/dm-invite', JSON.stringify(dmInviteBody));
}
async function emailLeadToLo(dmInviteBody: InviteRequestBody) {
  return await post('/v1/email-lead-to-lo', JSON.stringify(dmInviteBody));
}
