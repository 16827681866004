import { ReactNode } from 'react';
import { Header, Footer } from 'Components';
import { PageBody } from 'Components/Page/Components';

export function Shell({ children }: { children: ReactNode }) {
  return (
    <>
      <Header />
      <PageBody>
        <div className="tw-flex tw-justify-center tw-p-3 tw-w-full tw-mb-40 md:tw-mt-12 tw-text-gray-ravenswood">
          {children}
        </div>
      </PageBody>
      <Footer />
    </>
  );
}
