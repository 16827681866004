import {
  AbridgedLoanOfficer,
  CoBrandedMaterialTypes,
  MarketingRequest,
  MarketingRequestBody,
  MarketingRequestDetail,
  collateralRequestBody,
} from 'types';

const marketingDetailRequest = (
  marketingRequest: MarketingRequest,
  loanOfficerList: AbridgedLoanOfficer[]
): MarketingRequestBody => {
  const findLoanOfficer = (loanOfficerId: string) =>
    loanOfficerList.find((officer) => officer.uniqueLoId === loanOfficerId);

  const getCollateralRequestBody = (
    material: CoBrandedMaterialTypes
  ): collateralRequestBody => {
    switch (material.name) {
      case 'Open house flyer':
        return {
          type: 'open-house-flyer',
          address1:
            marketingRequest.marketingRequestDetail?.openHouseFlyer?.address1,
          address2:
            marketingRequest.marketingRequestDetail?.openHouseFlyer?.address2,
          state: marketingRequest.marketingRequestDetail?.openHouseFlyer?.state,
          mlsListingId:
            marketingRequest.marketingRequestDetail?.openHouseFlyer
              ?.mlsListingId,
          listingStatus:
            marketingRequest.marketingRequestDetail?.openHouseFlyer
              ?.listingStatus,
        };
      case 'Postcard':
        return {
          type: 'postcard',
          address1: marketingRequest.marketingRequestDetail?.postcard?.address1,
          address2: marketingRequest.marketingRequestDetail?.postcard?.address2,
          state: marketingRequest.marketingRequestDetail?.postcard?.state,
          mlsListingId:
            marketingRequest.marketingRequestDetail?.postcard?.mlsListingId,
          listingStatus:
            marketingRequest.marketingRequestDetail?.postcard?.listingStatus,
          postcardType:
            marketingRequest.marketingRequestDetail?.postcard?.postcardType,
        };
      case 'Rate sheet':
        return { type: 'rate-sheet' };
    }
  };

  const loanOfficer = findLoanOfficer(
    marketingRequest.marketingMaterial.clientNewLoanOfficer
  );

  const collateralRequests =
    marketingRequest.marketingMaterial.CoBrandedMaterialTypes.filter(
      (material) => material.checked
    ).map(getCollateralRequestBody);

  const marketingRequestBody: MarketingRequestBody = {
    loanOfficer: {
      loanOfficerId: loanOfficer?.loanOfficerId,
      displayName: loanOfficer?.displayName,
      email: loanOfficer?.email,
      company: loanOfficer?.company,
    },
    collateralRequests,
  };

  if (marketingRequest.marketingRequestDetail?.additionalInfo) {
    marketingRequestBody.additionalInformation =
      marketingRequest.marketingRequestDetail.additionalInfo;
  }

  return marketingRequestBody;
};

const marketingDetailFormValues = (formData: any): MarketingRequestDetail => ({
  openHouseFlyer: {
    address1: formData.openFlyerAddress1,
    address2: formData.openFlyerAddress2,
    listingStatus: formData.openFlyerListingStatus,
    mlsListingId: formData.openFlyerMLSListing,
    state: formData.openFlyerState,
  },
  postcard: {
    address1: formData.postCardAddress1,
    address2: formData.postCardAddress2,
    listingStatus: formData.listingStatusPostcard,
    mlsListingId: formData.postCardMLSlisting,
    state: formData.postCardState,
    postcardType: formData.postCardType,
  },
  additionalInfo: formData.additionalInfo,
});

const initialMarketingMaterialValues: MarketingRequest = {
  isMarketingFlowInProcess: false,
  isMarketingRequestSent: false,
  coBrandedMaterials: 'Open house flyer',
  marketingMaterial: {
    clientLoanOfficerName: '',
    clientNewLoanOfficer: '',
    CoBrandedMaterialTypes: [
      { name: 'Open house flyer', checked: false },
      { name: 'Postcard', checked: false },
      { name: 'Rate sheet', checked: false },
    ],
  },
};

const postCardMenuOptions = [
  { label: 'Seasonal', value: 'Seasonal' },
  { label: 'New Listing', value: 'New Listing' },
  { label: 'GR Loan Product', value: 'GR Loan Product' },
  { label: 'First Time Home Buyer', value: 'First Time Home Buyer' },
];

const listingMenuStatus = [
  { label: 'Active', value: 'Active' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Contingent', value: 'Contingent' },
  { label: 'Coming soon', value: 'Coming soon' },
];

export {
  marketingDetailRequest,
  marketingDetailFormValues,
  initialMarketingMaterialValues,
  postCardMenuOptions,
  listingMenuStatus,
};
