import React, { useEffect, useState, useMemo } from 'react';
import { StateSelect, TextField } from 'Components';
import { SelectChangeEvent } from '@mui/material';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash-blue.svg';
import AddIcon from '@mui/icons-material/Add';
import { AgentUserStateLicense, confirmationDialogConfig } from 'types';

interface LicenseTouchedState {
  [key: string]: boolean;
}

interface CustomErrors {
  [key: string]: string | undefined; // You can change this if you want to be more specific
}

export function LicenseListSection(props: {
  formik: any;
  setConfirmationConfig: (config: confirmationDialogConfig) => void;
  deleteWithoutConfirmation: (index: number) => void;
  onLicenseErrorChange: (hasErrors: boolean) => void;
}) {
  const {
    formik,
    setConfirmationConfig,
    deleteWithoutConfirmation,
    onLicenseErrorChange,
  } = props;

  const [licenseTouched, setLicenseTouched] = useState<LicenseTouchedState>({});
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});

  const updatedLicense = useMemo(() => {
    let counter = 1;
    return (
      formik.values.license?.map((value: AgentUserStateLicense) => {
        if (!value.hasOwnProperty('visible')) {
          value.visible = true;
        }
        if (value.visible) {
          value.visibleIndex = counter++;
        }
        return value;
      }) || []
    );
  }, [formik.values.license]);

  const existingLicenseStates = useMemo(
    () =>
      updatedLicense
        .filter(
          (license: AgentUserStateLicense) => license.visible && license.state
        )
        .map((license: AgentUserStateLicense) => license.state),
    [updatedLicense]
  );

  const getExistingLicenseStates = (currentIndex: number): string[] =>
    existingLicenseStates.filter(
      (state: string, index: number) => index !== currentIndex
    );

  const deleteLicense = (index: number, visibleIndex: number) => {
    if (
      formik.touched[`state${index}`] ||
      formik.touched[`content${index}`] ||
      formik.values.license[index].state ||
      formik.values.license[index].content
    ) {
      setConfirmationConfig({
        index: index,
        show: true,
        text: `Are you sure you want to delete license ${visibleIndex}`,
        subText:
          'This will remove the license from your account once you click the save button.',
        type: 'License',
        isDelete: true,
      });
    } else {
      deleteWithoutConfirmation(index);
    }
  };

  const findIsError = (errorObj: any, key: string) => {
    return errorObj && errorObj[key];
  };

  const isTouched = (index: number): boolean => {
    return !!licenseTouched[`License${index}`];
  };

  const isStateDuplicate = (state: string, index: number) => {
    return updatedLicense.some(
      (license: AgentUserStateLicense, i: number) =>
        license.state === state && license.visible
    );
  };

  useEffect(() => {
    const findRepeatingLicenses = (licenses: AgentUserStateLicense[]) => {
      const stateMap = new Set();
      const repeatedLicenseIndices: number[] = [];
      licenses.forEach((license) => {
        const { visible, state, visibleIndex } = license;
        if (visible && state) {
          if (stateMap.has(state)) {
            repeatedLicenseIndices.push(Number(visibleIndex));
          } else {
            stateMap.add(state);
          }
        }
      });
      return repeatedLicenseIndices;
    };
    const repeatedLicenseIndices = findRepeatingLicenses(updatedLicense);
    const updatedErrors: CustomErrors = {};
    repeatedLicenseIndices.forEach((index) => {
      updatedErrors[`license[${index}].state`] =
        'License for this state has already been created';
    });
    setCustomErrors(updatedErrors);
    const hasErrors = Object.keys(updatedErrors).length > 0;
    onLicenseErrorChange(hasErrors);
  }, [updatedLicense, setCustomErrors, onLicenseErrorChange]);

  return (
    <div>
      {updatedLicense.length > 0 && (
        <div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-x-6">
          {updatedLicense.map((license: AgentUserStateLicense, index: number) =>
            license.visible ? (
              <div key={index}>
                <span className="text-gray-ravenswood tw-font-bold tw-text-md">
                  License {license.visibleIndex}
                </span>
                <div className="tw-pt-3 tw-pb-2 tw-grid tw-grid-cols-2 tw-gap-3">
                  <StateSelect
                    id={`state${index}`}
                    name={`state${index}`}
                    label="License state"
                    required
                    value={formik.values.license[index].state}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const val = event.target.value;
                      formik.setFieldValue(`license[${index}].state`, val);
                      setLicenseTouched((prev) => ({
                        ...prev,
                        [`License${index}`]: true,
                      }));
                      const isDuplicate = isStateDuplicate(val, index);
                      setCustomErrors((prev) => ({
                        ...prev,
                        [`license[${index}].state`]: isDuplicate
                          ? 'License for this state has already been created'
                          : '',
                      }));
                    }}
                    error={
                      (!!findIsError(formik.errors.license?.[index], 'state') &&
                        !!isTouched(index)) ||
                      !!customErrors[`license[${license.visibleIndex}].state`]
                    }
                    helperText={
                      findIsError(formik.errors.license?.[index], 'state') &&
                      isTouched(index)
                        ? `${findIsError(
                            formik.errors.license?.[index],
                            'state'
                          )}`
                        : ''
                    }
                    onBlur={formik.handleBlur}
                    dataTestId="dt-state-openFlyerListingStatus"
                    existingLicenseStates={getExistingLicenseStates(index)}
                  />
                  {customErrors[`license[${license.visibleIndex}].state`] && (
                    <p className="tw-ml-0.5 tw-text-xs tw-text-red-error-text tw-leading-[1.66] tw-mb-0">
                      {customErrors[`license[${license.visibleIndex}].state`]}
                    </p>
                  )}
                  <TextField
                    id={`content${index}`}
                    name={`content${index}`}
                    label="License number"
                    required
                    inputProps={{
                      'data-testid': `dt-profile-content${index}`,
                    }}
                    value={formik.values.license[index].content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue(
                        `license[${index}].content`,
                        event.target.value
                      );
                      setLicenseTouched((prev) => {
                        return { ...prev, [`License${index}`]: true };
                      });
                    }}
                    error={
                      !!findIsError(
                        formik.errors.license?.[index],
                        'content'
                      ) && !!isTouched(index)
                    }
                    helperText={
                      findIsError(formik.errors.license?.[index], 'content') &&
                      isTouched(index)
                        ? `${findIsError(
                            formik.errors.license?.[index],
                            'content'
                          )}`
                        : ''
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div
                  className="tw-font-bold tw-text-blue-link-darker tw-cursor-pointer tw-mb-5 tw-flex tw-items-center tw-w-fit"
                  onClick={() =>
                    deleteLicense(index, license?.visibleIndex || 0)
                  }
                >
                  <TrashIcon className="tw-text-blue-link-darker tw-text-base tw-items-center tw-font-bold" />
                  <p className="tw-m-0 tw-font-bold">Delete license</p>
                </div>
              </div>
            ) : null
          )}
        </div>
      )}
      <div className="tw-font-bold tw-text-blue-link-darker tw-cursor-pointer tw-mb-4 tw-w-fit">
        <div
          onClick={() => {
            formik.setFieldValue('license', [
              ...formik.values.license,
              { state: '', content: '', visible: true },
            ]);
          }}
          className="tw-flex tw-items-center"
        >
          <AddIcon /> <p className="tw-m-0 tw-font-bold">Add license</p>
        </div>
      </div>
    </div>
  );
}
