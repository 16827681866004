import { useNavigate } from 'react-router-dom';
import { Button } from 'Components';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';

const ReferButton = (props: { size?: 'large' | 'small' }) => {
  const { size = 'large' } = props;
  const { isRunningInShellApp } = useAppContext();
  const navigate = useNavigate();
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          isRunningInShellApp
            ? sendMessageToShellApp({ type: 'OPEN_AGENTS_REFER_CLIENT' })
            : navigate('/refer-a-client');
        }}
        className={
          size === 'small'
            ? ['tw-text-sm']
            : ['tw-w-full', 'md:tw-w-auto', 'tw-text-base']
        }
        dataTestId="refer-button"
        padding={
          size === 'small' ? ['tw-px-2', 'tw-py-2'] : ['tw-px-10', 'tw-py-4']
        }
      >
        <span className="tw-whitespace-nowrap">Refer client</span>
      </Button>
    </>
  );
};

export { ReferButton };
