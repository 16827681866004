import { Link } from 'react-router-dom';
import { Shell } from 'Components/Page';
import { Container } from 'Components';

export function NoMatch() {
    return (
        <Shell>
            <Container>
                <h2 className="tw-mb-5 tw-font-semibold tw-tracking-tight">The page you are looking for is temporarily unavailable.</h2>
                <p>
                    <Link to="/">Go Home</Link>
                </p>
            </Container>
        </Shell>
    );
}
