import { ReactNode } from 'react';
import { Button } from 'Components';
import { ButtonProps } from 'types';

interface FormButtonProps {
  children?: ReactNode | string;
  onClick?: () => void;
  dataTestId?: string;
  isDisabled?: boolean;
}

interface SubmitButtonProps extends FormButtonProps {
  isLoading?: boolean;
  externalHref?: string;
}

type FormButtonRowProps = {
  cancel: FormButtonProps;
  submit: SubmitButtonProps;
};
const FormButtonRow = (props: FormButtonRowProps) => {
  const { cancel, submit } = props;
  if (!cancel.hasOwnProperty('children')) {
    cancel.children = 'Cancel';
  }
  if (!submit.hasOwnProperty('children')) {
    submit.children = <div className="tw-mx-8">Save</div>;
  }

  const cancelButtonProps: ButtonProps = {
    as: 'button',
    type: 'tertiary',
    children: cancel.children,
  };
  if (cancel?.onClick && typeof cancel.onClick === 'function') {
    cancelButtonProps.onClick = cancel.onClick;
  }
  if (cancel.hasOwnProperty('isDisabled')) {
    cancelButtonProps.isDisabled = cancel.isDisabled;
  }

  const submitButtonProps: ButtonProps = {
    as:
      submit?.onClick && typeof submit.onClick === 'function'
        ? 'button'
        : 'submit',
    type: 'primary',
    children: submit.children,
    className: ['tw-w-full', 'md:tw-w-auto'],
  };
  if (submit?.onClick && typeof submit.onClick === 'function') {
    submitButtonProps.onClick = submit.onClick;
  }
  const submitOpts: (keyof SubmitButtonProps)[] = [
    'isDisabled',
    'isLoading',
    'externalHref',
    'dataTestId',
  ];
  submitOpts.map((opt) => {
    if (submit.hasOwnProperty(opt)) {
      submitButtonProps[opt] = submit[opt];
    }
    return null;
  });

  return (
    <div className="tw-flex tw-justify-end tw-items-center tw-flex-col-reverse md:tw-flex-row tw-gap-5 md:tw-gap-9">
      <Button {...cancelButtonProps} />
      <Button {...submitButtonProps} />
    </div>
  );
};

export { FormButtonRow };
