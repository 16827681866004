/*
  messages sent to the shell app to indicate various events in the web app
*/
type ShellAppMessageType =
  | 'LOGOUT_REQUEST' // user wants to log out, shell app should close WebView
  | 'SHARE_APP'
  | 'FACEBOOK_SHARE_LINK' // shell app should initiate SDK to ShareLinkContent
  | 'FINANCIAL_SOLUTION_DIVERSEPRODUCTS'
  | 'FINANCIAL_SOLUTION_SAMEDAYMORTGAGE'
  | 'FINANCIAL_SOLUTION_HOMELOANS'
  | 'FINANCIAL_SOLUTION_REFINANCE'
  | 'FINANCIAL_SOLUTION_HELOC'
  | 'FINANCIAL_SOLUTION_HOMEEQUITYCONVERSION'
  | 'FINANCIAL_SOLUTION_PERSONALLOANS'
  | 'FINANCIAL_SOLUTION_INSURANCE'
  | 'OPEN_AGENTS_MARKETING'
  | 'OPEN_AGENTS_REFER_CLIENT'
  | 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL'
  | 'LOAD_AGENTS_PROFILE'
  | 'HANDLE_BASE64_BLOB';

interface ShellAppMessage {
  type: ShellAppMessageType;
}

interface ShellAppFacebookShareLinkMessage extends ShellAppMessage {
  type: 'FACEBOOK_SHARE_LINK';
  app_id: string;
  href: string;
  hashtag: string;
}

interface ShellAppHandleBlobMessage extends ShellAppMessage {
  type: 'HANDLE_BASE64_BLOB';
  filename?: string; // present only for download
  payload: string; // base64 encoded binary content
  intent: 'download' | 'open' | 'print';
  title?: string; // hint for shell app for display purposes
  description?: string; // hint for shell app for display purposes
  contentType?: string; // type of file, e.g. application/pdf or image/jpeg
}
interface ShellAppHandleLoanDetailMessage extends ShellAppMessage {
  type: 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL';
  payload: string;
}

type AnyShellAppMessage = ShellAppFacebookShareLinkMessage | ShellAppMessage;

/*
  sends a message to the shell app (which is running on iOS or Android).
  technique is copied from: https://medium.com/@sreeharikv112/communication-from-webview-to-native-ios-android-app-6d842cefe02d
*/
/* istanbul ignore next */
const _sendMessageToShellAppPrivate = (message: AnyShellAppMessage): void => {
  const messageStr = JSON.stringify(message);

  const windowAny: any = window as any;

  // iOS
  const hasIosPostMessage =
    !!windowAny.webkit?.messageHandlers?.jsMessageHandler?.postMessage;
  if (hasIosPostMessage) {
    console.log(`posting message ${message.type} to native iOS app`);
    try {
      windowAny.webkit?.messageHandlers?.jsMessageHandler?.postMessage(
        messageStr
      );
    } catch (e: any) {
      console.error(
        `failed to post message to native iOS app: ${e.toString()}`,
        e
      );
    }
  }

  // android
  const hasAndroidPostMessage = !!windowAny.JSBridge?.postMessage;
  if (hasAndroidPostMessage) {
    console.log(`posting message ${message.type} to native Android app`);
    try {
      windowAny.JSBridge?.postMessage(messageStr);
    } catch (e: any) {
      console.error(
        `failed to post message to native Android app: ${e.toString()}`,
        e
      );
    }
  }

  // if neither android nor ios work
  if (!hasIosPostMessage && !hasAndroidPostMessage) {
    console.error(
      `no iOS or Android JS bridge detected, unable to post message '${messageStr}' to native app`
    );
  }
};

let _queuedShellAppMessages: AnyShellAppMessage[] = [];
let _shellAppSendMessageTimer: string | number | NodeJS.Timeout | undefined;
const _executeShellAppSendMessages = () => {
  // the following messages are only allowed to be sent once per execution of this function
  // to prevent sending hundreds of the same message after resuming from background
  let hasSentLogoutMessage = false;

  for (let i = 0; i < _queuedShellAppMessages.length; i++) {
    const currentMessage: AnyShellAppMessage = _queuedShellAppMessages[i];

    if (currentMessage.type === 'LOGOUT_REQUEST') {
      if (hasSentLogoutMessage) {
        continue;
      } else {
        hasSentLogoutMessage = true;
      }
    }

    _sendMessageToShellAppPrivate(currentMessage);
  }
  _queuedShellAppMessages = [];

  if (_shellAppSendMessageTimer) {
    clearTimeout(_shellAppSendMessageTimer);
    _shellAppSendMessageTimer = undefined;
  }
};

/*
  queues a message to send to the shell app (which is running on iOS or Android)
  it is executed after a timeout in order to prevent duplicate messages from being sent
*/
/* istanbul ignore next */
const sendMessageToShellApp = (message: AnyShellAppMessage): void => {
  _queuedShellAppMessages.push(message);
  if (!_shellAppSendMessageTimer) {
    _shellAppSendMessageTimer = setTimeout(_executeShellAppSendMessages, 50);
  }
};

export type {
  ShellAppMessageType,
  ShellAppMessage,
  ShellAppFacebookShareLinkMessage,
  ShellAppHandleBlobMessage,
  ShellAppHandleLoanDetailMessage,
};

export { sendMessageToShellApp };
