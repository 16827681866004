import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import WarningTriangleIcon from 'Assets/icons/warning-triangle.svg';
import ErrorTriangleIcon from 'Assets/icons/error-triangle.svg';
import CircleTickIcon from 'Assets/icons/check-tick-circle.svg';
import LoaderIcon from 'Assets/icons/loader-red.svg';
import type { AlertMessage, AlertSeverity, AlertType } from 'types';
import { CloseButton } from 'Components';

export interface AlertProps {
  show: boolean;
  severity: AlertSeverity;
  type?: AlertType;
  message: AlertMessage;
  dataTestId?: string;
  showClasses: string[];
  controlLabel?: string;
  controlOnClick?: () => void;
  controlPosition?: 'inline' | 'right';
  loading?: boolean;
  isCloseIcon?: boolean;
  paddingY?: string;
}
const Alert = (props: AlertProps) => {
  const {
    severity,
    type = 'inline',
    show,
    message,
    controlLabel = '',
    controlOnClick,
    dataTestId = '',
    loading = false,
    controlPosition = 'right',
    isCloseIcon = false,
    paddingY = '3',
  } = props;

  const hasControl = useMemo(
    () => typeof controlOnClick === 'function',
    [controlOnClick]
  );

  const baseClassnames = useMemo(() => {
    const classes = [
      'tw-border',
      'tw-rounded-xl',
      `tw-py-${paddingY}`,
      'tw-px-3',
      'tw-text-sm',
      'tw-transition-all',
      'tw-duration-500',
      'tw-ease-in-out',
    ];
    switch (severity) {
      case 'error':
        classes.push(...['tw-border-red-critical', 'tw-bg-red-pink']);
        break;
      case 'info':
        classes.push(...['tw-border-[#2B85A8]', 'tw-bg-[#E7F2F5]']);
        break;
      case 'warning':
        classes.push(...['tw-border-warning-base', 'tw-bg-warning-lightest']);
        break;
      case 'success':
        classes.push(...['tw-border-green-accent', 'tw-bg-green-light']);
        break;
    }
    if (hasControl) {
      classes.push(...['tw-flex', 'tw-justify-between']);
    } else {
      classes.push(...['tw-flex', 'tw-justify-start']);
    }
    if (type === 'banner') {
      classes.push('tw-items-start');
    } else {
      classes.push('tw-items-start md:tw-items-center');
    }
    return classes;
  }, [hasControl, severity, type, paddingY]);

  const icon = useMemo(() => {
    let icon;
    switch (severity) {
      case 'error':
        icon = ErrorTriangleIcon;
        break;
      case 'warning':
        icon = WarningTriangleIcon;
        break;
      case 'success':
        icon = CircleTickIcon;
        break;
    }
    return icon;
  }, [severity]);
  const iconMessageClassname = useMemo(() => {
    let classname = 'tw-flex tw-justify-center tw-gap-3';
    return type === 'inline'
      ? `${classname} tw-items-start md:tw-items-center`
      : `${classname} tw-items-start`;
  }, [type]);
  const iconClassname = useMemo(
    () => (type === 'banner' ? 'tw-mt-1' : ''),
    [type]
  );
  const controlClassname = useMemo(() => {
    let classname = 'tw-text-base ';
    switch (severity) {
      case 'error':
        classname +=
          'tw-text-red-critical hover:tw-text-red-error-text hover:tw-underline tw-font-bold';
        break;
      case 'warning':
        classname +=
          'tw-text-yellow-dark hover:tw-text-yellow-dark hover:tw-underline tw-font-bold';
        break;
      case 'success':
        classname +=
          'tw-text-turqoise-dark hover:tw-text-turqoise-dark hover:tw-underline tw-font-bold';
        break;
    }
    return classname;
  }, [severity]);
  const hideClasses = useMemo(() => ['tw-opacity-0', 'tw-m-0'], []);
  const showClasses = useMemo(
    () => ['tw-opacity-1000', 'tw-h-auto', 'tw-p-5', ...props.showClasses],
    [props]
  );
  const hiddenClasses = useMemo(
    () => [...baseClassnames, ...hideClasses],
    [baseClassnames, hideClasses]
  );
  const visibleClasses = useMemo(
    () => [...baseClassnames, ...showClasses],
    [baseClassnames, showClasses]
  );

  const [className, setClassName] = useState(hiddenClasses);

  useEffect(() => {
    if (show) {
      setClassName(visibleClasses);
    } else {
      setClassName(hiddenClasses);
    }
  }, [show, visibleClasses, hiddenClasses]);

  return (
    <div className={classNames(className)} data-testid={dataTestId}>
      <div className={iconMessageClassname}>
        {loading ? (
          <img src={LoaderIcon} className="tw-animate-spin" alt="Loading..." />
        ) : (
          <img src={icon} className={iconClassname} alt="" />
        )}
        <div className="tw-text-base">
          {message}{' '}
          {hasControl && !!controlLabel && controlPosition === 'inline' && (
            <button className={controlClassname} onClick={controlOnClick}>
              {controlLabel}
            </button>
          )}
        </div>
      </div>
      {hasControl &&
        controlPosition === 'right' &&
        (isCloseIcon ? (
          <CloseButton
            className={controlClassname}
            onClick={controlOnClick}
            iconStyle="tw-w-4"
            dataTestId="alert-close-icon"
          />
        ) : (
          controlLabel && (
            <button
              className={controlClassname}
              onClick={controlOnClick}
              data-testid="alert-close-label"
            >
              {controlLabel}
            </button>
          )
        ))}
    </div>
  );
};
export { Alert };
