import classNames from 'classnames';
import styles from './Checkbox.module.css';

type CheckboxProps = {
  id?: string;
  name?: string;
  label?: string;
  checked: boolean;
  onChange: (e: any) => void;
  'data-testid'?: string;
  dataTestId?: string;
  classnames?: string[];
};

const Checkbox = (props: CheckboxProps) => {
  const attributes = { ...props };
  if (attributes?.dataTestId) {
    attributes['data-testid'] = attributes.dataTestId;
    delete attributes.dataTestId;
  }
  if (attributes?.label) {
    delete attributes.label;
  }
  return (
    <label
      className={classNames([
        'tw-inline-flex',
        'tw-relative',
        'tw-cursor-pointer',
        'tw-text-gray-ravenswood',
        styles.checkbox,
        attributes?.classnames ? attributes.classnames : '',
      ])}
    >
      <input
        className="tw-appearance-none tw-rounded-s tw-border tw-border-blue-link-hover tw-bg-white checked:tw-bg-blue-dull checked:tw-border-blue-darker focus:tw-outline-none tw-transition tw-duration-200 tw-bg-no-repeat tw-bg-center tw-bg-contain tw-shrink-0"
        type="checkbox"
        {...attributes}
      />
      <span
        className={classNames([
          'tw-pl-3',
          attributes?.classnames ? attributes.classnames : '',
        ])}
      >
        {props?.label}
      </span>
    </label>
  );
};

export { Checkbox };
