import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ProfileSizeClassesSize, profileCircleClasses } from 'styles/image';
import { ReactComponent as ImageIcon } from 'Assets/icons/image.svg';

type CircleAvatarProps = {
  photoUrl?: string;
  alt: string;
  size?: ProfileSizeClassesSize;
  className?: string;
};

const CircleAvatar = (props: CircleAvatarProps) => {
  const { photoUrl = '', alt = '', size = 32, className = '' } = props;
  const [loadFailed, setLoadFailed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [figureClasses, setFigureClasses] = useState([
    ...profileCircleClasses(size),
    'tw-flex',
    'tw-items-center',
    'tw-animate-pulse',
    className,
  ]);

  let circleClasses = profileCircleClasses(size);

  const profileImageBaseClasses = [
    ...circleClasses.filter(
      (item) =>
        !['tw-h-20', 'tw-h-18', 'md:tw-h-28', 'tw-h-32', 'md:tw-h-32'].includes(
          item
        )
    ),
    'tw-h-auto',
    'tw-transition-all',
    'tw-duration-150',
    'tw-ease-in-out',
  ];

  const emptyClasses = ['tw-w-7', 'tw-h-7', 'tw-m-auto'];

  const [profileImageClassName, setProfileImageClassName] = useState([
    ...profileImageBaseClasses,
    'tw-opacity-0',
  ]);
  const nonLoadingfigureClasses = [
    ...profileCircleClasses(size),
    'tw-flex',
    'tw-items-center',
  ];
  const cleanup = () => {
    setLoaded(false);
    setFigureClasses([
      ...profileCircleClasses(size),
      'tw-flex',
      'tw-items-center',
      'tw-animate-pulse',
    ]);
    setLoadFailed(false);
    setProfileImageClassName([...profileImageBaseClasses, 'tw-opacity-0']);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (photoUrl !== '') {
      const img = new Image();
      img.src = photoUrl;
      img
        .decode?.()
        .then(() => {
          setLoaded(true);
          setFigureClasses(profileCircleClasses(size));
          setProfileImageClassName([
            ...profileImageBaseClasses,
            'tw-opacity-1000',
          ]);
        })
        .catch(() => {
          setLoadFailed(true);
          setFigureClasses(nonLoadingfigureClasses);
        });
    } else {
      setFigureClasses(nonLoadingfigureClasses);
    }
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className={`tw-overflow-visible ${className}`}>
      <figure
        className={classNames(figureClasses)}
        style={{ backgroundColor: '#d8d8d8' }}
      >
        {photoUrl !== '' && loaded && !loadFailed ? (
          <img
            className={classNames(profileImageClassName)}
            src={photoUrl}
            alt={alt}
          />
        ) : (
          size > 8 && (
            <ImageIcon
              className={classNames(emptyClasses)}
              style={{
                filter:
                  'invert(94%) sepia(14%) saturate(0%) hue-rotate(189deg) brightness(78%) contrast(91%)',
              }}
            />
          )
        )}
      </figure>
    </div>
  );
};

export { CircleAvatar };
