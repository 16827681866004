import { Button, Dialog } from 'Components';

import rateAgentsQRCode from 'Assets/Images/Pages/Home/qrcode.jpg';

interface LoginThroughRateAppModalProps {
  open: boolean;
  onClose: () => void;
}
const storeURL = process.env?.REACT_APP_STORE_URL;
const LoginThroughRateAppModal = (props: LoginThroughRateAppModalProps) => (
  <Dialog {...props}>
    <div className="tw-px-8 tw-pb-8 md:tw-w-[32rem]">
      <h3 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-ravenswood">
        Looks like this email address is already associated with us!
      </h3>
      <p className="tw-text-base tw-mb-4 tw-text-gray-ravenswood">
        <Button type="quaternary" className={['tw-underline']} to="/log-in">
          Log in
        </Button>{' '}
        through this website or the Rate App!
      </p>
      <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center tw-gap-5">
        <p className="tw-text-base tw-text-gray-ravenswood">
          Download the free{' '}
          <Button type="quaternary" externalHref={storeURL} targetBlank={true}>
            Rate App
          </Button>{' '}
          here:
        </p>
        <img
          src={rateAgentsQRCode}
          alt="Download Rate Agents app"
          className="tw-w-20"
        />
      </div>
    </div>
  </Dialog>
);

export { LoginThroughRateAppModal };
