import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LoggedOutNav } from 'Components';
import { RateAgentsLogo } from '../NavBar/RateAgentsLogo';
import styles from 'Components/LoginHeader/LoginHeader.module.css';
import { useEffect, useState } from 'react';

type HeaderProps = {
  showNav?: boolean;
};

export const Header = (props: HeaderProps) => {
  const { showNav = true } = props;
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        role="navigation"
        aria-label="navigation"
        className={classNames([
          'tw-px-4',
          'tw-bg-white',
          'tw-w-full',
          'tw-z-50',
          'tw-sticky',
          'tw-top-0',
          'tw-flex',
          'tw-justify-center',
          scrolled && 'tw-drop-shadow-md',
        ])}
      >
        <div
          className={classNames([
            'tw-h-17',
            'tw-w-full',
            'tw-flex',
            'tw-items-center',
            'lg:tw-min-h-68',
            'tw-max-w-1170px',
            'fullhd:tw-max-w-1392px',
            'tw-justify-between',
            'lg:tw-align-center',
          ])}
        >
          <div className="tw-flex tw-justify-between tw-items-center">
            <Link
              to="/"
              className={`${styles.logoLink} tw-flex tw-items-center tw-justify-start tw-absolute tw-top-0 tw-h-17 tw-w-52 md:tw-relative md:tw-relative md:tw-top-0 md:tw-h-auto`}
            >
              <RateAgentsLogo />
            </Link>{' '}
          </div>
          {showNav && (
            <div className="tw-flex">
              <LoggedOutNav />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
