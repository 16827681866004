import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  SearchField,
  Button,
  Spinner,
  LoanOfficerCard,
  Header1,
  Alert,
} from 'Components';
import { useWindowDimensions } from 'lib/hooks';
import { loanOfficers as loApi } from 'api';
import { LoanOfficer, SelectedLoanOfficer } from 'types';
import { tabletDimension } from 'Components/Common/Utils';
import useAppContext from 'contexts/AppContext';

const LoanOfficerSearch = (props: {
  backbuttonclick: () => void;
  setSelectedLO: (lo: SelectedLoanOfficer) => void;
}) => {
  const { height, width } = useWindowDimensions();
  const { isRunningInShellApp } = useAppContext();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [loanOfficers, setLoanOfficers] = useState<LoanOfficer[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { backbuttonclick, setSelectedLO } = props;

  const doSearch = async (isInitialSearch: boolean) => {
    try {
      if (search) {
        const offset = isInitialSearch ? 0 : pageNumber;
        setLoading(true);
        setShowAlert(false);
        const results = await loApi.search({
          searchTerm: search,
          offset: (offset * 20).toString(),
        });
        if (results.ok && results.loanOfficers) {
          if (!results.loanOfficers.length) {
            setShowAlert(true);
            setAlertMessage(
              'No loan officers were found. Please revise your search.'
            );
            setHasMore(false);
            setPageNumber(0);
          } else {
            setHasMore(true);
            setLoanOfficers(
              isInitialSearch
                ? results.loanOfficers
                : [...loanOfficers, ...results.loanOfficers]
            );
            setPageNumber(pageNumber + 1);
          }
        } else if (results.error) {
          const errorMessage = results.serverError?.errors?.[0]?.title;
          setAlertMessage(errorMessage || 'Error retrieving loan officer data');
          setShowAlert(true);
        }
      } else {
        setShowAlert(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      setAlertMessage('Error retrieving loan officer data');
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };
  const backButtonClick = () => {
    setLoanOfficers([]);
    backbuttonclick();
    setLoading(false);
    setShowAlert(false);
  };
  const minHeight = useMemo(() => {
    if (width && width <= tabletDimension) {
      // Adjust height based on whether the app is running in a shell
      return height - (isRunningInShellApp ? 240 : 337); // Subtract 240px for shell apps to exclude footer and header from native screens
    }
    return 'auto';
  }, [width, height]);
  return (
    <div>
      <div>
        <Header1 classes={['tw-my-0']}>Find a loan officer</Header1>
        <p className="tw-pt-2 md:tw-pt-5 tw-m-0">
          Search by name, city, state or zip code.
        </p>
        <div className="tw-pt-6 tw-mb-2 md:tw-w-96">
          <SearchField
            searchKey={(e) => {
              setSearch(e);
              setPageNumber(0);
            }}
            doSearch={() => {
              setLoanOfficers([]);
              setHasMore(true);
              doSearch(true);
            }}
            classsNames="tw-w-96"
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={loanOfficers.length}
        next={() => {
          doSearch(false);
        }}
        hasMore={hasMore}
        loader={<div />}
      >
        <div
          style={{ minHeight }}
          className="tw-flex tw-flex-col tw-py-4 tw-gap-5"
        >
          {loanOfficers && loanOfficers.length > 0
            ? loanOfficers.map((lo, index) => {
                return (
                  <LoanOfficerCard
                    key={`${lo?.employeeId}-${index}`}
                    loanOfficer={lo}
                    backclick={backButtonClick}
                    setSelectedLO={setSelectedLO}
                  />
                );
              })
            : showAlert && (
                <Alert
                  type="inline"
                  severity="error"
                  show={showAlert}
                  showClasses={['md:tw-w-96', 'tw-items-center']}
                  message={alertMessage}
                />
              )}
          {loading ? (
            <div className="tw-flex tw-justify-center">
              <Spinner type="quaternary" />
            </div>
          ) : null}
        </div>
        {!loanOfficers.length ? (
          <Button
            type="primary"
            fullWidth={true}
            onClick={() => {
              doSearch(true);
            }}
            isDisabled={!search || loading}
            className={[
              'tw-gap-1',
              'tw-py-3',
              'tw-h-auto',
              'tw-px-16',
              'tw-w-full',
              'tw-text-xl',
              'md:tw-w-96',
            ]}
          >
            Next
          </Button>
        ) : null}
      </InfiniteScroll>
    </div>
  );
};

export { LoanOfficerSearch };
