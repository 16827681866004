import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './LoggedOutMobileNav.module.css';
import useAppContext from 'contexts/AppContext';

type MobileMenuProps = {
  children: ReactNode | string;
};

const MobileMenu = (props: MobileMenuProps) => {
  const { mobileNavIsOpen } = useAppContext();

  return (
    <>
      <div
        className={classNames(
          'tw-transition-all',
          'tw-duration-300',
          'tw-ease-in-out',
          'md:tw-hidden',
          mobileNavIsOpen ? 'tw-block' : 'tw-hidden',
          styles.overlay
        )}
      />
      <nav
        role="navigation"
        aria-label="dropdown navigation"
        className={classNames(
          'tw-inset-x-0',
          'tw-bg-white',
          'tw-text-gray-text',
          'tw-z-40',
          'tw-pt-8',
          'tw-h-screen',
          'tw-w-screen',
          'tw-absolute',
          'tw-transition-all',
          'tw-duration-300',
          'tw-ease-in-out',
          'tw-left-0',
          mobileNavIsOpen ? styles.top325 : styles.topMinus100vh,
          mobileNavIsOpen ? 'tw-opacity-100' : 'tw-opacity-0',
          'lg:tw-hidden',
          'tw-px-4',
          'is-transparent',
          styles.inner
        )}
      >
        <div className="container">{props.children}</div>
      </nav>
    </>
  );
};

export { MobileMenu };
