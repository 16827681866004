import { Fragment, PropsWithChildren } from 'react';

import useAppContext from 'contexts/AppContext';

import YoutubeLogo from 'Assets/icons/social/YouTube.svg';
import InstagramLogo from 'Assets/icons/social/Instagram.svg';
import LinkedInLogo from 'Assets/icons/social/Linkedin.svg';
import FacebookLogo from 'Assets/icons/social/Facebook.svg';
import TwitterLogo from 'Assets/icons/social/X.svg';

import EHLIcon from 'Assets/logos/EqualHousing/ehl-light.svg';
import logo from 'Assets/logos/Companies/gri/logo.svg';
import styles from './Footer.module.css';

import { Button } from 'Components';
import type { TenantConfig } from 'types';

const currentYear = new Date().getFullYear();

type TextLinkProps = {
  id: string;
  href: string;
  text: string;
  mode: 'mobile' | 'desktop';
};

const setTextLinksToDesktopMode = (links: TextLinkProps[]) => {
  if (Array.isArray(links)) {
    return links.map((textLink) => {
      const props = { ...textLink };
      props.mode = 'desktop';
      return props;
    });
  }
  return links;
};

const TextLink = (props: TextLinkProps) => (
  <Button
    type="quaternary"
    externalHref={props.href}
    className={['tw-text-sm']}
  >
    {props.text}
  </Button>
);

const TextLinkRow = (props: { links: TextLinkProps[] }) => {
  const { links } = props;
  if (Array.isArray(links) && links.length > 0) {
    return (
      <div className="tw-flex tw-flex-row tw-gap-2">
        {links.map<React.ReactNode>((textLink: TextLinkProps, index) => (
          <Fragment key={`footer-link-${textLink.id}-${textLink.mode}`}>
            {index > 0 && (
              <div className="tw-inline-block tw-border-l tw-border-gray-neutral tw-w-0" />
            )}
            <TextLink {...textLink} />
          </Fragment>
        ))}
      </div>
    );
  }
  return <></>;
};

type SocialNetwork = {
  id: keyof TenantConfig['social'];
  logo: string;
  alt: string;
};

type SocialMediaLinkProps = {
  href: string;
  src: string;
  alt: string;
};

const SocialMediaLink = (props: SocialMediaLinkProps) => (
  <a
    href={props.href}
    className="tw-text-white tw-my-0 visited:tw-text-white"
    rel="noreferrer"
    target="_blank"
  >
    <img
      src={props.src}
      className={`tw-w-5 tw-h-5 ${styles.socialMediaIcon}`}
      alt={props.alt}
    />
  </a>
);

const Footer = ({
  children,
  hideStandardFooterContent = false,
  isExtendedWidth = false,
}: PropsWithChildren<{
  isExtendedWidth?: boolean;
  hideStandardFooterContent?: boolean; // hides all footer content except for the provided children
}>) => {
  const { tenant } = useAppContext();

  const {
    companyName,
    companyAddress = '',
    companyURL,
    copyrightStartYear = 0,
    footer = {},
    nmls,
    social,
  } = tenant;

  const sectionLinksA1: TextLinkProps[] = [
    {
      id: 'accessibility',
      mode: 'mobile',
      href: `${companyURL}/accessibility`,
      text: 'Accessibility',
    },
  ];
  if (footer?.links?.licensing) {
    sectionLinksA1.push({
      id: 'licensing',
      mode: 'mobile',
      href: `${companyURL}${footer?.links?.licensing}`,
      text: 'Licensing',
    });
  }
  sectionLinksA1.push({
    id: 'notice-to-vendor',
    mode: 'mobile',
    href: `${companyURL}/notice-to-vendor`,
    text: 'Notice to vendors',
  });

  const sectionLinksA2: TextLinkProps[] = [
    {
      id: 'privacy',
      mode: 'mobile',
      href: `${companyURL}/${footer?.links?.privacy || 'privacy'}`,
      text: 'Privacy Policies',
    },
  ];
  if (footer?.links?.smsTerms) {
    sectionLinksA2.push({
      id: 'smsTerms',
      mode: 'mobile',
      href: `${companyURL}${footer?.links?.smsTerms}`,
      text: 'SMS Terms',
    });
  }
  if (footer?.links?.terms) {
    sectionLinksA2.push({
      id: 'terms',
      mode: 'mobile',
      href: `${companyURL}${footer?.links?.terms}`,
      text: 'Terms of use',
    });
  }

  const sectionLinksA3: TextLinkProps[] = [];
  if (footer?.links?.nmls) {
    sectionLinksA3.push({
      id: 'nmls',
      mode: 'mobile',
      href: footer?.links?.nmls,
      text: 'NMLS Consumer Access',
    });
  }

  const sectionLinksB1: TextLinkProps[] = [];
  if (footer?.links?.delawareLOs) {
    sectionLinksB1.push({
      id: 'delawareLOs',
      mode: 'mobile',
      href: footer?.links?.delawareLOs,
      text: 'Delaware licensed loan officers',
    });
  }

  const sectionLinksB2: TextLinkProps[] = [
    {
      id: 'texas-consumers',
      mode: 'mobile',
      href: `${companyURL}/texas-consumers-how-to-file-complaint`,
      text: 'Texas consumers: How to file a complaint',
    },
  ];

  const sectionLinksB3: TextLinkProps[] = [];
  if (footer?.links?.doNotSell) {
    sectionLinksB3.push({
      id: 'doNotSell',
      mode: 'mobile',
      href: footer?.links?.doNotSell,
      text: 'Do not sell my personal information',
    });
  }

  const copyrightClassnames = 'tw-text-gray-ravenswood tw-text-sm tw-mb-0';
  const contactParts = [];
  if (companyAddress) {
    contactParts.push(companyAddress);
  }
  const contactLine = contactParts.length > 0 ? contactParts.join(' - ') : '';
  const containerWidth = isExtendedWidth
    ? 'tw-mx-auto tw-w-full fullhd:tw-max-w-1392px tw-pt-4'
    : 'tw-mx-auto tw-w-full fullhd:tw-max-w-1440px tw-pt-4';

  const socialNetworks: SocialNetwork[] = [
    {
      id: 'facebook' as keyof TenantConfig['social'],
      logo: FacebookLogo,
      alt: 'Facebook',
    },
    {
      id: 'twitter' as keyof TenantConfig['social'],
      logo: TwitterLogo,
      alt: 'Twitter',
    },
    {
      id: 'linkedin' as keyof TenantConfig['social'],
      logo: LinkedInLogo,
      alt: 'LinkedIn',
    },
    {
      id: 'youtube' as keyof TenantConfig['social'],
      logo: YoutubeLogo,
      alt: 'Youtube',
    },
    {
      id: 'instagram' as keyof TenantConfig['social'],
      logo: InstagramLogo,
      alt: 'Instagram',
    },
  ];

  return !hideStandardFooterContent ? (
    <footer
      className="tw-bg-white tw-w-full tw-flex-col tw-items-center tw-relative tw-pt-6 md:tw-pt-12 tw-pb-6 md:tw-block tw-px-4"
      data-testid="page-footer"
    >
      <div className={containerWidth}>
        {children}
        <div className="tw-border-t tw-border-gray-neutral tw-pt-8" />
        <div className="tw-flex tw-flex-col tw-gap-4 md:tw-gap-3">
          <div className="tw-block md:tw-hidden tw-flex tw-flex-col tw-gap-3">
            <TextLinkRow links={sectionLinksA1} key="links-A1" />
            <TextLinkRow links={sectionLinksA2} key="links-A2" />
            <TextLinkRow links={sectionLinksA3} key="links-A3" />
          </div>
          <div className="tw-hidden md:tw-block">
            <TextLinkRow
              links={setTextLinksToDesktopMode([
                ...sectionLinksA1,
                ...sectionLinksA2,
                ...sectionLinksA3,
              ])}
              key="links-A"
            />
          </div>
          <div className="tw-border-t tw-border-gray-neutral tw-h-0" />
          <div className="tw-grid tw-auto-cols-auto tw-grid-cols-1 tw-gap-5 md:tw-grid-cols-[auto_169px]">
            <div className="tw-block md:tw-hidden tw-flex tw-flex-col tw-gap-2">
              <TextLinkRow links={sectionLinksB1} key="links-B1" />
              <TextLinkRow links={sectionLinksB2} key="links-B2" />
              <TextLinkRow links={sectionLinksB3} key="links-B3" />
            </div>
            <div className="tw-hidden md:tw-block">
              <TextLinkRow
                links={setTextLinksToDesktopMode([
                  ...sectionLinksB1,
                  ...sectionLinksB2,
                  ...sectionLinksB3,
                ])}
                key="links-B"
              />
            </div>
            <div className="md:tw-row-span-2 tw-pt-1">
              <img src={logo} alt="Rate Agents" className="tw-h-[33px]" />
              <p className="tw-mt-5 tw-mb-3">Follow us on social:</p>
              <div className="tw-mt-0 tw-flex tw-flex-row tw-gap-3  md:tw-justify-between md:tw-gap-0">
                {socialNetworks.map((network) => {
                  const socialUrl = (social && social[network.id]) || '';
                  if (socialUrl) {
                    return (
                      <SocialMediaLink
                        key={`social-network-${network.id}`}
                        href={socialUrl}
                        src={network.logo}
                        alt={network.alt}
                      />
                    );
                  }
                  return <></>;
                })}
              </div>
            </div>
            <div className={`md:tw-mt-3 ${styles.extraInfo}`}>
              <div className="tw-mb-4">
                <img
                  src={EHLIcon}
                  alt="Equal Housing Lender"
                  className="tw-mx-0 tw-inline tw-w-[131px] tw-h-[27px]"
                />
              </div>
              <p className={copyrightClassnames}>
                Copyright © {copyrightStartYear ? `${copyrightStartYear}-` : ''}
                {currentYear} {companyName}
                {companyName.slice(-1) === '.' ? '' : '.'} All rights reserved.
              </p>
              {footer?.blurbs?.dba && (
                <p className={copyrightClassnames}>{footer.blurbs.dba}</p>
              )}
              <p className={copyrightClassnames}>{nmls}</p>
              {contactLine && (
                <p className={copyrightClassnames}>{contactLine}</p>
              )}
              {footer?.blurbs?.disclaimer && (
                <p className={copyrightClassnames}>
                  {footer.blurbs.disclaimer}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  ) : null;
};

export { Footer };
