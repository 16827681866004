// Defines the event name for messages from shell app to web app
export const SHELL_APP_TO_WEB_APP_MESSAGE_EVENT = 'shellAppToWebAppMessage';

export interface ShellAppToWebAppNavigationWithLoanGuidMessage {
  type: 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL';
  payload: string;
}

export interface ShellAppToWebAppMessageEvent extends CustomEvent {
  detail: ShellAppToWebAppNavigationWithLoanGuidMessage;
}

(window as any).receiveMessageFromShellApp = (
  message: ShellAppToWebAppNavigationWithLoanGuidMessage
) => {
  const event: ShellAppToWebAppMessageEvent = new CustomEvent(
    SHELL_APP_TO_WEB_APP_MESSAGE_EVENT,
    {
      detail: message,
    }
  );
  window.dispatchEvent(event);
};
