import type { DateType } from 'types';

const millisecondsPerday = 86400000;

const daysAgoDate = (daysAgo: number) =>
  new Date(Date.now() - millisecondsPerday * daysAgo);

const daysFutureDate = (daysAgo: number) => daysAgoDate(-1 * daysAgo);

const dateTimeStringHasUtcMidnight = (datetime: string) =>
  datetime.trim().endsWith('T00:00:00Z');

const parseDateFromDateTime = (datetime: string): DateType => {
  const emptyDate = { year: 0, month: 0, day: 0 };
  let parsedDate = emptyDate;
  if (datetime) {
    if (dateTimeStringHasUtcMidnight(datetime)) {
      const dateWithoutTime = datetime.substring(0, 10);
      const dateParts = dateWithoutTime.split('-');
      parsedDate = {
        year: parseInt(dateParts[0]),
        month: parseInt(dateParts[1]),
        day: parseInt(dateParts[2]),
      };
    } else {
      const dateObj = new Date(datetime);
      if (dateObj) {
        parsedDate = {
          year: dateObj.getFullYear(),
          month: dateObj.getMonth() + 1,
          day: dateObj.getDate(),
        };
      }
    }
  }
  return parsedDate;
};

const formatDatetime = (
  datetime: string | Date | null | undefined,
  short = false
) => {
  let formattedDate = '';
  if (typeof datetime === 'string') {
    const parsedDate = parseDateFromDateTime(datetime);
    formattedDate = `${parsedDate.month}/${parsedDate.day}/${parsedDate.year
      .toString()
      .substring(2)}`;
  } else {
    if (datetime) {
      formattedDate = new Date(datetime).toLocaleDateString('en-US', {
        year: short ? '2-digit' : 'numeric',
        month: short ? 'numeric' : '2-digit',
        day: short ? 'numeric' : '2-digit',
      });
    }
  }
  return formattedDate;
};

const updateToUTC = (date: Date): Date => {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
};

const treatAsUTC = (dateString: string): Date => {
  const date = dateString ? new Date(dateString) : new Date(Date.now());
  return updateToUTC(date);
};

const diffInDays = (
  startDateString: string,
  endDateString: string = ''
): number => {
  const diffTime =
    treatAsUTC(endDateString).getTime() - treatAsUTC(startDateString).getTime();
  const millisecondsPerday = 86400000;
  return Math.round(diffTime / millisecondsPerday);
};

const humanizeDateTime = (datetime: string) => {
  const SECOND = 1;
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const secondsAgo = Math.round(
    (Date.now() - new Date(datetime).getTime()) / 1000
  );

  if (secondsAgo < 5) {
    return 'just now';
  }

  let divisor = 1,
    unit = '';

  if (secondsAgo < MINUTE) {
    [divisor, unit] = [SECOND, 'second'];
  } else if (secondsAgo < HOUR) {
    [divisor, unit] = [MINUTE, 'minute'];
  } else if (secondsAgo < DAY) {
    [divisor, unit] = [HOUR, 'hour'];
  } else {
    return formatDatetime(datetime, true);
  }

  const count = Math.floor(secondsAgo / divisor);
  return `${count} ${unit}${count > 1 ? 's' : ''} ago`;
};

const isInFuture = (dateString: string): boolean =>
  treatAsUTC(dateString).getTime() > new Date(Date.now()).getTime();

const isToday = (dateString: string): boolean => {
  const nowDate = new Date(Date.now());
  const targetDate = treatAsUTC(dateString);
  return (
    nowDate.getMonth() === targetDate.getUTCMonth() &&
    nowDate.getFullYear() === targetDate.getUTCFullYear() &&
    nowDate.getUTCDate() === targetDate.getUTCDate()
  );
};

const isInPastOrToday = (dateString: string): boolean =>
  !isInFuture(dateString) || isToday(dateString);

const nonNullDates = (dateObject: { [key: string]: string | null }): string[] =>
  Object.values(dateObject)
    .filter((date) => !!date && new Date(date).getTime() < new Date().getTime())
    .map((date) => `${date}`);

const getLatest = (
  dateObject: { [key: string]: string | null },
  isInprogress: boolean
): string => {
  const ifClosedAndIsClosedDate = (!isInprogress && dateObject.closed) || '';
  if (ifClosedAndIsClosedDate) return ifClosedAndIsClosedDate;
  const nonNull = nonNullDates(dateObject);
  if (nonNull.length > 0) {
    const sorted = nonNull
      .filter((dt) => dt < Date())
      .sort(
        (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
      );
    return sorted[sorted.length - 1];
  }
  return '';
};

const createDayOfMonthLocal = (day: number) => {
  const date = new Date(Date.now());
  date.setUTCDate(day);
  const dateInLocalTZ = new Date(
    date.getTime() + new Date().getTimezoneOffset() * 60000
  );
  return dateInLocalTZ;
};

const zerofillMonth = (month: number): string => ('0' + month).slice(-2);

export {
  createDayOfMonthLocal,
  diffInDays,
  formatDatetime,
  getLatest,
  humanizeDateTime,
  isInFuture,
  isInPastOrToday,
  isToday,
  millisecondsPerday,
  daysAgoDate,
  daysFutureDate,
  parseDateFromDateTime,
  zerofillMonth,
};
