const linkClasses = [
  'tw-py-5',
  'tw-px-1',
  'md:tw-px-3',
  'lg:tw-px-4',
  'tw-space-x-2',
  'tw-text-base',
  'tw-font-bold',
  'tw-text-gray-text',
  'tw-whitespace-nowrap',
  'hover:tw-underline',
  'hover:tw-underline-offset-8',
];
const activeSideNavClasses = ['tw-underline', 'tw-underline-offset-8'];

export { linkClasses, activeSideNavClasses };
