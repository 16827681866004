import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { muiSelectStyles } from 'styles/mui';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { ReactComponent as ChevronDown } from 'Assets/icons/chevron-down-small.svg';
import { ReactComponent as ChevronDownRed } from 'Assets/icons/chevron-down-small-red.svg';

import { SelectedLoanOfficer } from 'types';
import useAppContext from 'contexts/AppContext';
import { LoaderSmall } from 'Components/Loaders';
import useCurrentUser from 'contexts/UserContext';

const GrStyledFormControl = styled(FormControl)<FormControlProps>(
  () => muiSelectStyles
);

export type OptionProps = {
  value: string;
  label: string | React.ReactElement;
};

type SelectLoanOfficerProps = {
  field: string;
  dataTestId?: string;
  selectProps: SelectProps;
  options: OptionProps[];
  helperText?: string;
  includeNoneOption: boolean;
  setSelectedLO: (lo: SelectedLoanOfficer) => void;
  onChange: (event: SelectChangeEvent<any>) => void;
  autoFocus?: boolean;
};

const SelectLoanOfficer = (props: SelectLoanOfficerProps) => {
  const {
    selectProps,
    dataTestId,
    field,
    options,
    helperText = '',
    setSelectedLO,
    onChange,
    autoFocus,
  } = props;
  const [menuItems, setMenuItems] = useState<OptionProps[]>(options);
  const { isRunningInShellApp } = useAppContext();
  const { loansLoading } = useCurrentUser();

  useEffect(() => {
    const items = [...options];
    setMenuItems(items);
  }, [options]);

  const selectLoanOfficerProps = { ...selectProps };
  if (dataTestId) {
    selectLoanOfficerProps.inputProps = {
      ...selectLoanOfficerProps?.inputProps,
      ...{ 'data-testid': dataTestId },
    };
  }

  const handleAddLoanOfficer = () => {
    setSelectedLO({ loanOfficerCompany: '', loanOfficerId: 1 });
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    if (event.target.value === '') {
      handleAddLoanOfficer();
    } else {
      onChange(event);
    }
  };

  const menuItemsArray = menuItems.map((option, index) => (
    <MenuItem
      key={`${field}-option-${index}`}
      value={option.value}
      disableRipple
      disableTouchRipple
      style={isRunningInShellApp ? { background: 'none' } : {}}
    >
      {option.label}
    </MenuItem>
  ));
  loansLoading &&
    menuItemsArray.push(
      <MenuItem
        key="loading"
        disableRipple
        disableTouchRipple
        style={{
          color: '#2175DA',
          fontSize: 16,
          marginLeft: -15,
          background: 'none',
          cursor: 'unset',
        }}
      >
        <div className="tw-px-3">
          <LoaderSmall />
        </div>
      </MenuItem>
    );
  menuItemsArray.push(
    <MenuItem
      key={`${field}-option-${menuItemsArray.length++}`}
      disableRipple
      disableTouchRipple
      style={!isRunningInShellApp ? { background: 'none' } : {}}
      onClick={(e) => {
        setSelectedLO({ loanOfficerCompany: '', loanOfficerId: 1 });
      }}
    >
      <IconButton
        aria-label="add"
        disableRipple={true}
        disableFocusRipple={true}
        data-testid="dialog-add-icon-button"
        style={{
          color: '#2175DA',
          fontSize: 16,
          marginLeft: -15,
          background: 'none',
        }}
        className="tw-font-bold tw-bg-transparent"
      >
        <AddIcon /> Add new loan officer
      </IconButton>
    </MenuItem>
  );
  const MenuProps = {
    PaperProps: {
      style: {
        width: 80,
        marginTop: 10,
        boxShadow:
          '0px 2.5px 2.5px -1.5px rgba(0,0,0,0.08), 0px 4px 5px 0.5px rgba(0,0,0,0.08), 0px 1.5px 7px 1px rgba(0,0,0,0.08)',
      },
    },
    disableAutoFocusItem: true,
  };

  return (
    <GrStyledFormControl variant="filled" fullWidth>
      <InputLabel htmlFor={field}>{selectProps.label}</InputLabel>
      <Select
        labelId={field}
        id={field}
        {...selectLoanOfficerProps}
        onChange={handleChange} // Ensure this is bound to handleChange
        disableUnderline
        MenuProps={MenuProps}
        IconComponent={selectProps.error ? ChevronDownRed : ChevronDown}
        autoFocus={autoFocus}
        defaultValue="" // Ensure no item is pre-selected
      >
        {menuItemsArray}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </GrStyledFormControl>
  );
};

export { SelectLoanOfficer };
