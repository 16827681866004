import { extractJsonApiErrors, requestWithTimeoutAndOktaLogin } from './util';

import type {
  AgentUserUpdateEmailType,
  ApiResponse,
  JsonApiType,
  MLSAssociation,
  UpdateBrandingImageResponse,
  UpdateProfileImageResponse,
} from 'types';

type UpdateProfileResponse =
  | {
      ok: true;
    }
  | {
      ok: false;
      responseStatusText: string;
      errorSummary?: string[];
    };

// Added a new errorSummary key for showing the detailed error which is recieved from server

const getErrorSummary = (errorData: any): string[] => {
  return errorData?.errors
    ?.map((err: any) => err?.detail?.errorCauses[0]?.errorSummary)
    .filter((summary: any) => summary) as string[];
};

async function updatePassword(
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
): Promise<UpdateProfileResponse> {
  const response = await fetch('/v1/update-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: currentPassword,
      newPassword,
      passwordConfirmation: confirmPassword,
    }),
  });

  if (response.status !== 200) {
    const errorData = await response.json();
    return {
      ok: false,
      responseStatusText: response.statusText,
      errorSummary: getErrorSummary(errorData),
    };
  }

  return { ok: true };
}

async function updateProfileImage(
  file: File
): Promise<UpdateProfileImageResponse> {
  const data = new FormData();
  data.append('file', file);
  const response = await fetch('/v1/profile-image', {
    method: 'POST',
    body: data,
  });

  if (response.status !== 200) {
    return { ok: false, responseStatusText: response.statusText };
  }

  const jsonResponse = await response.json();

  return { ok: true, ...jsonResponse };
}

async function updateBrandingImage(
  file: File
): Promise<UpdateBrandingImageResponse> {
  const data = new FormData();
  data.append('file', file);
  const response = await fetch('/v1/branding-image', {
    method: 'POST',
    body: data,
  });

  if (response.status !== 200) {
    return { ok: false, responseStatusText: response.statusText };
  }

  const jsonResponse = await response.json();

  return { ok: true, ...jsonResponse };
}

interface JsonApiMlsAssociation extends JsonApiType {
  attributes: {
    id: number;
    name: string;
  };
}

const formatJsonApiMlsAssociations = (
  data: JsonApiMlsAssociation[]
): MLSAssociation[] =>
  data.map((item: JsonApiMlsAssociation) => ({
    id: `${item.attributes?.id}`,
    name: item.attributes.name,
  }));

interface GetMlsAssociationsResponse extends ApiResponse {
  associations?: MLSAssociation[];
}

async function getMlsAssociations(): Promise<GetMlsAssociationsResponse> {
  try {
    const ret = await requestWithTimeoutAndOktaLogin('/v1/mls-associations');

    if (!ret.response?.ok || ret.response.status !== 200) {
      const jsonApiErrors = await extractJsonApiErrors(ret.response);
      return {
        ok: false,
        jsonApiErrors,
      };
    }

    return {
      ok: true,
      associations: formatJsonApiMlsAssociations(ret.responseObj?.data),
    };
  } catch (error) {
    console.error(`failed to retrieve MLS associations`, error);
    return {
      ok: false,
      error,
    };
  }
}

const sendVerificationEmailForEmailUpdate = async (
  email: string
): Promise<ApiResponse> => {
  if (!!process.env.REACT_APP_TEST_UPDATE_EMAIL_VERIFICATION) {
    if (process.env.REACT_APP_TEST_UPDATE_EMAIL_VERIFICATION === 'OK') {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        jsonApiErrors: [
          {
            code: process.env.REACT_APP_TEST_UPDATE_EMAIL_VERIFICATION || '',
            title: '',
          },
        ],
      };
    }
  }
  try {
    const ret = await requestWithTimeoutAndOktaLogin(
      '/v1/send-email-update-verification-email',
      {
        method: 'POST',
        body: JSON.stringify({ email }),
      }
    );
    if (!ret.response?.ok || ret.response.status !== 200) {
      const jsonApiErrors = await extractJsonApiErrors(ret.response);
      return {
        ok: false,
        jsonApiErrors,
      };
    }
    return {
      ok: true,
    };
  } catch (error) {
    console.error(`failed to send email update verification email`, error);
    return {
      ok: false,
      error,
    };
  }
};

const updateEmail = async (
  verificationToken: string,
  ssnLastFour?: string
): Promise<ApiResponse> => {
  if (!!process.env.REACT_APP_TEST_UPDATE_EMAIL) {
    if (process.env.REACT_APP_TEST_UPDATE_EMAIL === 'OK') {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        jsonApiErrors: [
          {
            code: process.env.REACT_APP_TEST_UPDATE_EMAIL || '',
            title: '',
          },
        ],
      };
    }
  }
  const payload: AgentUserUpdateEmailType = { verificationToken };
  if (ssnLastFour) {
    payload.ssnLastFour = ssnLastFour;
  }
  try {
    const ret = await requestWithTimeoutAndOktaLogin('/v1/update-email', {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (!ret.response?.ok || ret.response.status !== 200) {
      const jsonApiErrors = await extractJsonApiErrors(ret.response);
      return {
        ok: false,
        jsonApiErrors,
      };
    }
    return {
      ok: true,
    };
  } catch (error) {
    console.error(`failed to update email`, error);
    return {
      ok: false,
      error,
    };
  }
};

export {
  sendVerificationEmailForEmailUpdate,
  getMlsAssociations,
  updateBrandingImage,
  updateEmail,
  updatePassword,
  updateProfileImage,
};
