import Cookies from 'universal-cookie';
import {
  LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY,
  UNACCEPTABLE_LOGIN_RETURN_PATHS,
} from './constants';

const login = (redirectPath?: string | null | undefined) => {
  if (redirectPath?.trim() && typeof redirectPath === 'string') {
    if (!UNACCEPTABLE_LOGIN_RETURN_PATHS.has(redirectPath)) {
      localStorage.setItem(LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY, redirectPath);
    }
    // do nothing, if there exists an existing redirect path, leave it alone
  } else {
    localStorage.removeItem(LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY);
  }

  const href = '/auth/login';
  window.location.href = href;
};

const logout = () => {
  window.location.href = '/auth/logout';
};

const getOktaCookie = (): any => {
  const cookies = new Cookies();
  return cookies.get('agentsOktaUser');
};

const isAuthenticated = (): boolean => {
  const oktaUserCookie = getOktaCookie();
  return oktaUserCookie && oktaUserCookie?.status === 'loggedIn';
};

export { getOktaCookie, isAuthenticated, login, logout };
