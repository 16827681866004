import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import useAppContext from 'contexts/AppContext';
import useCurrentUser from 'contexts/UserContext';
import {
  AuthMainNav,
  AuthMobileWebMenu,
  SubNavigationMenu,
  HiUser,
  MobileNavToggle,
  ReferButton,
} from 'Components';

import { RateAgentsLogo } from '../NavBar/RateAgentsLogo';
import { ReactComponent as AvatarPlaceholder } from 'Assets/icons/avatar-placeholder-small.svg';

import styles from './LoginHeader.module.css';

const LoginHeader = () => {
  const { isRunningInShellApp, setMobileNavIsOpen } = useAppContext();
  const { user } = useCurrentUser();
  const userInfo = user || { photoUrl: '', firstName: '', lastName: '' };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClassname = useMemo(() => {
    let className =
      'tw-sticky tw-top-0 tw-z-50 tw-h-17 tw-bg-white tw-w-full tw-pr-4 md:tw-pl-4';
    className += scrolled ? ' tw-drop-shadow-md' : '';
    return className;
  }, [scrolled]);

  const profileCircleClassNames =
    'tw-rounded-full tw-object-cover tw-h-6 md:tw-h-9 tw-w-6 md:tw-w-9 tw-max-w-none tw-bg-gradient-to-b tw-from-gray-100 tw-to-gray-200 tw-border-0';

  const placeholderAvatarClassNames =
    'tw-h-6 md:tw-h-9 tw-w-6 md:tw-w-9 tw-rounded-full';

  const Avatar =
    userInfo && userInfo.photoUrl ? (
      <img
        src={userInfo.photoUrl}
        className={profileCircleClassNames}
        alt="profile"
      />
    ) : (
      <AvatarPlaceholder className={placeholderAvatarClassNames} />
    );
  return isRunningInShellApp ? (
    <></>
  ) : (
    <>
      <nav
        role="navigation"
        aria-label="navigation"
        className={headerClassname}
      >
        <div className="tw-w-full fullhd:tw-max-w-1440px tw-h-full tw-flex tw-items-center tw-justify-between fullhd:tw-mx-auto">
          <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center">
              {!isRunningInShellApp && <MobileNavToggle />}
              <Link
                to="/dashboard"
                className={`${styles.logoLink} ${
                  isRunningInShellApp ? 'tw-ml-4' : ''
                } tw-flex tw-items-center tw-h-17 tw-mb-1 md:tw-relative md:tw-relative md:tw-top-0 md:tw-h-auto md:tw-mr-4 tw-text-gray-ravenswood focus:tw-text-gray-ravenswood hover:tw-text-gray-ravenswood active:tw-text-gray-ravenswood`}
                onClick={()=>setMobileNavIsOpen(false)}
              >
                <RateAgentsLogo auth={true} />
              </Link>
              <AuthMainNav />
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 md:tw-hidden">
              <Link to="/professional-profile" onClick={()=>setMobileNavIsOpen(false)}>{Avatar}</Link>
            </div>
          </div>
          <div className="tw-hidden md:tw-flex md:tw-items-center md:tw-gap-2 lg:tw-gap-4 xl:tw-gap-8">
            <ReferButton size="small" />
            <div className="tw-flex md:tw-items-center tw-gap-4">
              <Link
                to="/professional-profile"
                className="tw-text-sm tw-text-gray-ravenswood hover:tw-text-gray-raven125 hover:tw-underline active:tw-scale-90 tw-whitespace-nowrap"
              >
                <HiUser size={4} />
              </Link>
              <SubNavigationMenu>{Avatar}</SubNavigationMenu>
            </div>
          </div>
        </div>
      </nav>
      <AuthMobileWebMenu />
    </>
  );
};

export { LoginHeader };
