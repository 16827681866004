import { FC } from 'react';

import useAppContext from 'contexts/AppContext';

export const PageBody: FC = (props) => {
  const { mobileNavIsOpen } = useAppContext();
  return (
    <div
      className={`${
        mobileNavIsOpen ? 'tw-hidden' : ''
      } tw-text-gray-ravenswood`}
    >
      {props.children}
    </div>
  );
};
