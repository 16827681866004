import { Link } from 'react-router-dom';

import useAppContext from 'contexts/AppContext';
import useCurrentUser from 'contexts/UserContext';

import { Button, Footer, Header1 } from 'Components';
import { MobileMenu } from './MobileMenu';

import type { ButtonProps } from 'types';

import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';
import { ReactComponent as FeedbackIcon } from 'Assets/icons/dual/feedback.svg';

const HelpButton = () => {
  const { setMobileNavIsOpen } = useAppContext();
  return (
    <Link
      to="/help"
      onClick={() => {
        setMobileNavIsOpen(false);
      }}
      className="tw-flex tw-justify-start tw-items-center tw-gap-5 tw-border tw-border-blue-dull tw-p-6 tw-rounded-xl tw-border-blue-link-hover"
    >
      <FeedbackIcon />
      <div className="tw-flex tw-flex-col tw-gap-1">
        <div className="tw-font-bold tw-text-gray-ravenswood">
          How are we doing?
        </div>
        <div className="tw-font-bold tw-text-blue-link-darker">
          Tell us about it
        </div>
      </div>
    </Link>
  );
};

type LinkType = {
  id: string;
  text: string;
  onClick?: () => void;
  to?: string;
};

type MenuSectionType = {
  id: string;
  headerLink: LinkType;
  links: LinkType[];
};

const MenuSection = (props: MenuSectionType) => {
  const { headerLink, links } = props;
  const { setMobileNavIsOpen } = useAppContext();
  const liClassname = 'tw-list-none tw-my-2';

  const handleButtonClick = (link: LinkType) => {
    setMobileNavIsOpen(false);
    link.onClick?.();
    if (link.id === 'home-affordability-calculator') {
      window.open(link.to, '_blank');
    }
  };
  return (
    <div className="tw-border tw-border-gray-neutral tw-rounded-xl tw-px-6 tw-py-4">
      <ul className="tw-list-none">
        <li className={liClassname}>
          <Button
            type="quaternary"
            to={headerLink.to}
            onClick={() => {
              setMobileNavIsOpen(false);
            }}
            className={[
              'tw-text-base',
              'tw-flex',
              'tw-flex-row',
              'tw-items-center',
              'tw-gap-2',
            ]}
          >
            {headerLink.text}
            <ChevronRight className="tw-w-[8px] tw-h-[13px]" />
          </Button>
        </li>
        {links.map((link) => {
          const buttonProps: ButtonProps = {
            as: 'button',
            type: 'tertiary',
            className: ['tw-text-base', 'tw-text-left'],
            children: link.text,
            onClick: () => handleButtonClick(link),
          };

          if (link.to && link.id !== 'home-affordability-calculator') {
            buttonProps.to = link.to;
          }

          return (
            <li className={liClassname} key={`menu-web-link-${link.id}`}>
              <Button {...buttonProps} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const AuthMobileWebMenu = () => {
  const { user } = useCurrentUser();
  const { setMobileNavIsOpen } = useAppContext();

  const sections: MenuSectionType[] = [
    {
      id: 'dashboard',
      headerLink: {
        id: 'dashboard',
        to: '/dashboard',
        text: 'Dashboard',
      },
      links: [
        {
          id: 'refer',
          to: '/refer-a-client',
          text: 'Refer client',
        },
        {
          id: 'rate-app',
          to: '/dashboard/rate',
          text: 'Download Rate Agent app',
        },
      ],
    },
    {
      id: 'closings',
      headerLink: {
        id: 'closings',
        to: '/closings',
        text: 'Closings',
      },
      links: [
        {
          id: 'in-progress-closings',
          to: '/closings/in-progress',
          text: 'In progress',
        },
        {
          id: 'closed-closings',
          to: '/closings/closed',
          text: 'Closed',
        },
      ],
    },
    {
      id: 'tools',
      headerLink: {
        id: 'tools',
        to: '/tools',
        text: 'Tools',
      },
      links: [
        {
          id: 'mortgage-payment-calculator',
          to: '/tools/mortgage-calculator',
          text: 'Mortgage payment calculator',
        },
        {
          id: 'home-affordability-calculator',
          to: 'https://www.rate.com/mortgage-calculators/how-much-home-can-i-buy-calculator',
          text: 'Home affordability calculator',
        },
        {
          id: 'marketing',
          to: '/marketing-request',
          text: 'Marketing',
        },
        {
          id: 'open-house',
          to: '/open-house',
          text: 'Open house sign-ins',
        },
      ],
    },
    {
      id: 'profile',
      headerLink: {
        id: 'profile',
        to: '/professional-profile',
        text: 'My profile',
      },
      links: [
        {
          id: 'change-password',
          to: '/change-password',
          text: 'Update password',
        },
        {
          id: 'change-email',
          to: '/change-email',
          text: 'Update email',
        },
        {
          id: 'logout',
          to: '/logging-out',
          onClick: () => {
            setMobileNavIsOpen(false);
          },
          text: `${
            user?.loginEmail ? `Log out of ${user.loginEmail}` : 'Log out'
          }`,
        },
      ],
    },
  ];

  return (
    <MobileMenu>
      <Header1>Menu</Header1>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-5">
        {sections.map((section) => (
          <MenuSection key={`menu-web-section-${section.id}`} {...section} />
        ))}
        <HelpButton />
      </div>
      <Footer />
    </MobileMenu>
  );
};

export { AuthMobileWebMenu };
