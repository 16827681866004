import { useState } from 'react';

const Carousel = (props: {
  cards: React.ReactElement[];
  slug: string;
  heightClass: string;
}) => {
  const { cards, slug, heightClass } = props;
  const [cardShowingIndex, setCardShowingIndex] = useState(0);
  return (
    <div>
      <div className="tw-relative tw-w-full">
        <div className={`tw-relative tw-overflow-hidden ${heightClass}`}>
          {cards.map((card, index) => (
            <div
              key={`carousel-card-${slug}-${index}`}
              className={`tw-duration-700 tw-ease-in-out tw-absolute tw-inset-0 tw-transition-transform tw-transform ${
                index === cardShowingIndex
                  ? 'tw-translate-x-0'
                  : cardShowingIndex - index > 1 ||
                    (index < cardShowingIndex && cardShowingIndex - index < 4)
                  ? '-tw-translate-x-full'
                  : 'tw-translate-x-full'
              } ${index === cardShowingIndex ? 'tw-z-20' : 'tw-z-10'} ${
                Math.abs(index - cardShowingIndex) === 2 ||
                Math.abs(index - cardShowingIndex) === 3
                  ? 'tw-hidden'
                  : ''
              }`}
            >
              <div className="tw-absolute tw-block tw-w-full -tw-translate-x-1/2 -tw-translate-y-1/2 tw-top-1/2 tw-left-1/2">
                {card}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="tw-mt-3 tw-flex tw-justify-center tw-gap-6">
        {cards.map((card, index) => (
          <button
            key={`carousel-indicator-${slug}-${index}`}
            className={`tw-w-3 tw-h-3 tw-rounded-full tw-inset-0 tw-transition-transform tw-transform ${
              index === cardShowingIndex
                ? 'tw-bg-gray-ravenswood'
                : 'tw-bg-[#929394]'
            }`}
            aria-current={index === cardShowingIndex ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
            onClick={() => {
              setCardShowingIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { Carousel };
