import { NodeOrString } from 'types';

const capitalizeWord = (str?: string): string => {
  if (str && str.length) {
    const lowercase = str.toLowerCase();
    return lowercase[0].toUpperCase() + lowercase.substring(1);
  } else {
    return '';
  }
};

const capitalCase = (str?: string | null): string =>
  str
    ?.split(' ')
    .map((word) => capitalizeWord(word))
    .join(' ') || '';

const genUuid = () =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );

const includesCaseInsensitive = (
  str: string | undefined | null,
  searchValue: string
): boolean => str?.toLowerCase().includes(searchValue.toLowerCase()) || false;

const stripNonNumeric = (str?: string): string => str?.replace(/\D/g, '') || '';

const formatCityStateZip = (
  city?: string,
  state?: string,
  zip?: string
): NodeOrString => {
  let output = `${state || ''} ${zip || ''}`.trim();
  if (city) {
    output = city + (output ? ', ' : '') + output;
  }
  return output;
};

const formatPhoneNumber = (phoneNumber?: string | null): string | null => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

const parseUrlForDomain = (url?: string | null): string | null | undefined => {
  try {
    if (url) {
      return new URL(url).hostname;
    }
  } catch {}
  return url;
};

export {
  capitalCase,
  formatCityStateZip,
  formatPhoneNumber,
  genUuid,
  includesCaseInsensitive,
  parseUrlForDomain,
  stripNonNumeric,
};
