import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { muiInputFilledStyles } from 'styles/mui';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={
          ref as React.RefCallback<HTMLTextAreaElement | HTMLInputElement>
        }
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const GrStyledTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => muiInputFilledStyles
);

const GrTextField = (props: TextFieldProps) => {
  const fieldProps = { ...props };
  fieldProps.InputLabelProps = {
    ...fieldProps.InputLabelProps,
  };
  fieldProps.InputProps = {
    ...fieldProps.InputProps,
    ...{ disableUnderline: true, inputComponent: TextMaskCustom as any },
  };
  return (
    <GrStyledTextField
      color="secondary"
      variant="filled"
      fullWidth
      {...fieldProps}
    />
  );
};

export { GrTextField as PhoneField };
