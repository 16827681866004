import { Button, Dialog } from 'Components';
import { confirmationDialogConfig } from 'types';

export const DeleteConfirmationDialog = (props: {
  confirmationConfig: confirmationDialogConfig;
  updateConfirmationConfig: (config: confirmationDialogConfig) => void;
}) => {
  const { confirmationConfig, updateConfirmationConfig } = props;

  const buttonClasses = [
    'tw-w-full',
    'md:tw-w-52',
    'md:tw-px-14',
    'tw-text-[20px]',
    'tw-font-black',
    'tw-py-4',
    'tw-self-center',
    'tw-whitespace-nowrap',
  ];
  return (
    <Dialog
      open={confirmationConfig.show}
      onClose={() => {
        updateConfirmationConfig({
          ...confirmationConfig,
          show: false,
          isDelete: false,
        });
      }}
      data-testid="closings-filter-dialog"
    >
      <div className="tw-min-w-full md:tw-w-[912px] tw-px-4 md:tw-px-12 tw-pb-12">
        <div className="tw-flex tw-flex-col tw-justify-start md:tw-items-center md:tw-justify-center">
          <div className="md:tw-w-[672px] tw-flex tw-flex-col">
            <h1 className="tw-font-black tw-text-3xl md:tw-text-[32px] tw-mt-0 tw-text-gray-ravenswood tw-tracking-normal tw-justify-self-start">
              {confirmationConfig.text}
            </h1>
            <span>{props.confirmationConfig.subText}</span>
            <div className="tw-flex tw-justify-center tw-gap-6 tw-mt-6 tw-flex-col md:tw-flex-row">
              <Button
                type="secondary"
                onClick={() => {
                  updateConfirmationConfig({
                    ...confirmationConfig,
                    show: false,
                    isDelete: false,
                  });
                }}
                className={buttonClasses}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  updateConfirmationConfig({
                    ...confirmationConfig,
                    show: false,
                    isDelete: true,
                  });
                }}
                dataTestId="dt-delete-confirmation"
                className={buttonClasses}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
