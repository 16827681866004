import LoaderIcon from 'Assets/icons/loader-quaternary.svg';

const LoaderSmall = () => (
  <div className="tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-gray-ravenswood tw-font-semibold tw-text-base">
    <img
      src={LoaderIcon}
      alt="Loading..."
      className="tw-animate-spin tw-h-4 tw-w-4"
      role="alert"
      aria-busy="true"
    />
    <span>Loading...</span>
  </div>
);

export { LoaderSmall };
