import { ReactComponent as ChevronLeftIcon } from 'Assets/icons/chevron-left-small.svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { LinkButton, LinkButtonProps } from './LinkButton';
import useAppContext from 'contexts/AppContext';

export interface BackButtonProps extends Omit<LinkButtonProps, 'data-testid'> {}

export const BackButton = ({
  onClick,
  disabled = false,
  className,
  title,
}: BackButtonProps) => {
  const navigate = useNavigate();
  const { isPrevUrlDashboard, isRunningInShellApp } = useAppContext();
  const IsCurrentURLClosings = window.location.pathname.includes('/closings');
  const handleButtonClick = () => {
    if (onClick) {
      onClick(); // an onClick prop takes highest precedence
    } else {
      navigate(-1); // default behavior is to navigate back through the browser's history
    }
  };
  const handleShellAppBackButtonClick = () => {
    if (isPrevUrlDashboard && !IsCurrentURLClosings) {
      navigate('/closings');
    } else {
      navigate(-1); // Navigate back in browser history for other URLs}
    }
  };

  const handleClick = isRunningInShellApp
    ? handleShellAppBackButtonClick
    : handleButtonClick;

  return (
    <LinkButton
      onClick={handleClick}
      dataTestId="dt-back-button"
      disabled={disabled}
      className={classNames(
        'tw-flex tw-items-center tw-justify-center',
        className
      )}
      title={title}
    >
      <ChevronLeftIcon
        style={{
          filter:
            'invert(38%) sepia(41%) saturate(3673%) hue-rotate(199deg) brightness(90%) contrast(88%)',
        }}
      />
      <div>Back</div>
    </LinkButton>
  );
};
