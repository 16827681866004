import { useMemo } from 'react';

import { Button, CircleAvatar } from 'Components';
import type {
  AbridgedLoanOfficer,
  LoanOfficer,
  SelectedLoanOfficer,
} from 'types';
import { ReactComponent as ViaEmail } from 'Assets/Images/via_email.svg';
import { ReactComponent as ViaPhone } from 'Assets/Images/via_phone.svg';
import useCurrentUser from 'contexts/UserContext';
import { uniqueLoId } from 'lib/util';
import { formatCityStateZip, formatPhoneNumber } from 'lib/string';

type LoanOfficerCardProps = {
  loanOfficer: LoanOfficer;
  backclick: () => void;
  setSelectedLO: (lo: SelectedLoanOfficer) => void;
};

const LoanOfficerCard = (props: LoanOfficerCardProps) => {
  const { addLoanOfficer, uniqueActiveLoanOfficers } = useCurrentUser();
  const { loanOfficer, backclick, setSelectedLO } = props;
  let {
    company,
    employeeId,
    fullName,
    title = 'VP of Mortgage Lending',
    photoUrl,
    profileImageUrl,
    email,
    city,
    state,
    nmlsId,
  } = loanOfficer;

  title = title.replace('Vice President', 'VP');

  const imgSrc = photoUrl || profileImageUrl;
  const savePartner = () => {
    const loExists = uniqueActiveLoanOfficers.filter(
      (el) =>
        uniqueLoId(el.company, el.loanOfficerId) ===
        uniqueLoId(company, employeeId)
    );
    if (!loExists.length) {
      const newLo: AbridgedLoanOfficer = {
        loanOfficerId: employeeId,
        city: city || '',
        company: company,
        displayName: fullName || '',
        state: state || '',
        email: email || '',
        uniqueLoId: uniqueLoId(company, employeeId),
        employeeActive: true,
        photoUrl: imgSrc || '',
      };
      addLoanOfficer(newLo);
    }
    setSelectedLO({
      loanOfficerCompany: company,
      loanOfficerId: employeeId,
      loanOfficerName: fullName,
    });
    backclick();
  };

  const cityStateFormatted = useMemo(() => {
    return formatCityStateZip(city, state, '');
  }, [city, state]);

  const styles = {
    info: 'tw-text-gray-ravenswood tw-text-base tw-mb-1',
    contact:
      'tw-flex tw-items-center tw-gap-1 tw-text-base tw-text-gray-ravenswood tw-mb-2 tw-font-bold',
  };

  return (
    <div className="tw-bg-white tw-rounded-xl tw-border tw-border-gray-lightborder tw-px-6 tw-pt-6 tw-pb-6 md:tw-pb-4">
      <div className="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-12 md:tw-gap-6">
        <div className="tw-flex tw-flex-col tw-flex-nowrap md:tw-col-span-5 tw-gap-2">
          <div className="tw-flex tw-flex-row tw-gap-4 tw-flex-nowrap">
            <CircleAvatar
              photoUrl={imgSrc ?? ''}
              alt={fullName ?? ''}
              size={20}
            />
            <div className="tw-whitespace-normal">
              {fullName && (
                <p className="tw-font-bold tw-text-gray-ravenswood tw-text-base tw-mb-1">
                  {fullName}
                </p>
              )}
              {title && <p className={styles.info}>{title}</p>}
              {cityStateFormatted && (
                <p className={styles.info}>{cityStateFormatted}</p>
              )}
              {nmlsId && <p className={styles.info}>NMLS {nmlsId}</p>}
            </div>
          </div>
        </div>
        <div className="tw-text-gray-ravenswood tw-flex tw-flex-col md:tw-items-start md:tw-col-span-4">
          {loanOfficer?.email && (
            <div>
              <p className={styles.contact}>
                <ViaEmail className="tw-h-3" />
                {loanOfficer.email}
              </p>
            </div>
          )}
          {loanOfficer?.officePhone && (
            <p className={`${styles.contact} -tw-mt-1`}>
              <ViaPhone className="tw-h-3.5" />
              {formatPhoneNumber(loanOfficer.officePhone)}
            </p>
          )}
        </div>
        <div className="tw-flex tw-justify-end md:tw-col-span-3">
          <Button
            type="redSecondary"
            onClick={savePartner}
            fullWidth={true}
            className={[
              'tw-inline-block',
              'tw-h-fit',
              'tw-text-base',
              'tw-w-full',
              'md:tw-w-auto',
            ]}
          >
            <div className="tw-px-6">Select partner</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export { LoanOfficerCard };
