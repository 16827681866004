import { useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { OpenHouseDeleteConfirmation, StyledMenu } from 'Components';
import type { NodeOrString } from 'types';

type OpenHouseContextMenuProps = {
  id: string;
  children: NodeOrString;
};

const OpenHouseContextMenu = (props: OpenHouseContextMenuProps) => {
  const { id } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    handleClose();
    navigate(path);
  };

  const buttonId = `oh-mobile-btn-${id}`;
  const menuId = `oh-context-menu-${id}`;

  return (
    <div>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{ backgroundColor: 'transparent', minWidth: 0, padding: 0 }}
        disableRipple
      >
        {props.children}
      </Button>
      <StyledMenu
        id={menuId}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleMenuItemClick(`/open-house/${id}/preview-form`)}
          disableRipple
        >
          Preview
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick(`/open-house/${id}/leads`)}
          disableRipple
        >
          View leads
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick(`/open-house/${id}/preview-share`)}
          disableRipple
        >
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setDeleteDialogOpen(true);
          }}
          disableRipple
        >
          Delete
        </MenuItem>
      </StyledMenu>
      <OpenHouseDeleteConfirmation
        id={id}
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </div>
  );
};

export { OpenHouseContextMenu };
