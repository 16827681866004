const filenameWithoutPath = (filename: string) =>
  filename !== '' ? filename.split('/').pop() : '';

const filenameExtension = (filename: string): string =>
  filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
const uniqueLoId = (company: string, loId: number) => {
  return `${company.toLocaleLowerCase()}-${loId}`;
};
function formatAddress({
  addressOne,
  addressTwo,
  city,
  state,
  zipCode,
}: {
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}) {
  let returnAddress: string[] = [];

  if (addressOne) {
    returnAddress = [`${addressOne}`];
  }

  if (addressOne && addressTwo) {
    returnAddress = [`${addressOne} ${addressTwo}`];
  }

  if (city) {
    returnAddress = [...returnAddress, city];
  }

  if (state) {
    returnAddress = [...returnAddress, state];
  }

  if (zipCode) {
    returnAddress = [...returnAddress, zipCode];
  }

  return returnAddress.join(', ');
}

function matchedObject(
  items: object[],
  key: string,
  value?: string | number
): any {
  if (value) {
    return items.find((assoc: any) => assoc[key] === value);
  }
  return false;
}

const numberFormatOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const inLocalTestModeByEnvVar = (envVar: string): boolean | null =>
  !!process.env['REACT_APP_USE_TEST_' + envVar]
    ? process.env['REACT_APP_USE_TEST_' + envVar] === 'true' &&
      process.env.NODE_ENV !== 'test'
      ? true
      : false
    : null;

export {
  inLocalTestModeByEnvVar,
  filenameExtension,
  filenameWithoutPath,
  formatBytes,
  formatAddress,
  matchedObject,
  numberFormatOptions,
  uniqueLoId,
};
