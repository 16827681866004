import { Button } from 'Components';
import { formatPhoneNumber } from 'lib/string';

const LoanNoSsnErrorMessage = () => (
  <>
    We need more information before proceeding. Please contact us at{' '}
    <Button externalHref={`tel:8669347283`} type="quaternary">
      {formatPhoneNumber('8669347283')}
    </Button>{' '}
    or{' '}
    <Button externalHref={`mailto:customercare@rate.com`} type="quaternary">
      customercare@rate.com
    </Button>
    .{' '}
  </>
);

export { LoanNoSsnErrorMessage };
