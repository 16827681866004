import { useEffect, useState } from 'react';

import type {
  AbridgedLoanOfficer,
  LOSelectPickerProps,
  OptionProps,
  SelectedLoanOfficer,
} from 'types';
import { SelectLoanOfficer } from 'Components';
import { uniqueLoId } from 'lib/util';
import useCurrentUser from 'contexts/UserContext';

const sortLoanOfficerAlpha = (los: AbridgedLoanOfficer[]) =>
  los.sort((a, b) =>
    a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0
  );

const LoanOfficerPicker = (props: LOSelectPickerProps) => {
  const {
    onChange,
    onPartnerAdd,
    helperText = '',
    selectProps,
    autoFocus,
    dataTestId,
  } = props;
  const [options, setOptions] = useState<OptionProps[]>([]);
  const { uniqueActiveLoanOfficers } = useCurrentUser();

  useEffect(() => {
    setOptions(
      sortLoanOfficerAlpha(uniqueActiveLoanOfficers)?.map(
        ({ loanOfficerId, company, displayName }) => ({
          value: uniqueLoId(company, loanOfficerId),
          label: displayName,
        })
      )
    );
  }, [uniqueActiveLoanOfficers]);

  const setSelectedLo = (e: SelectedLoanOfficer) => {
    setOptions(
      sortLoanOfficerAlpha(uniqueActiveLoanOfficers)?.map(
        ({ loanOfficerId, company, displayName }) => ({
          value: uniqueLoId(company, loanOfficerId),
          label: displayName,
        })
      )
    );
    onPartnerAdd(e);
  };
  const selectedProps = { ...selectProps };
  return (
    <SelectLoanOfficer
      field="clientNewLoanOfficer"
      options={options}
      selectProps={selectedProps}
      includeNoneOption={false}
      setSelectedLO={setSelectedLo}
      helperText={helperText}
      onChange={onChange}
      autoFocus={autoFocus}
      dataTestId={dataTestId}
    />
  );
};

export { LoanOfficerPicker };
