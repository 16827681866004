import { ReactNode } from 'react';
import classNames from 'classnames';

const Header3 = (props: { children: ReactNode; classes?: string[] }) => (
  <h3
    className={classNames(
      'tw-font-bold',
      'tw-text-xl',
      'md:tw-text-2xl',
      'tw-text-gray-ravenswood',
      ...(props.classes ?? [])
    )}
  >
    {props.children}
  </h3>
);

export { Header3 };
