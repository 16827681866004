import { useCallback, useState } from 'react';

import { Banner, Button, Dialog } from 'Components';
import { openHouse as openHouseApi } from 'api';

interface OpenHouseDeleteConfirmationProps {
  open: boolean;
  id: string;
  onClose: () => void;
}

const OpenHouseDeleteConfirmation = (
  props: OpenHouseDeleteConfirmationProps
) => {
  const { open, id, onClose } = props;

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const buttonClasses = [
    'tw-w-full',
    'md:tw-w-52',
    'md:tw-px-14',
    'tw-text-[20px]',
    'tw-font-black',
    'tw-py-4',
    'tw-self-center',
    'tw-whitespace-nowrap',
  ];

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={loading}
      data-testid="open-house-delete-confirmation-dialog"
    >
      <div className="tw-min-w-full md:tw-w-[912px] tw-px-4 md:tw-px-12 tw-pb-12">
        <div className="tw-flex tw-flex-col tw-justify-start md:tw-items-center md:tw-justify-center">
          <div className="md:tw-w-[672px] tw-flex tw-flex-col">
            <h1 className="tw-font-black tw-text-3xl md:tw-text-[32px] tw-mt-0 tw-text-gray-ravenswood tw-tracking-normal tw-justify-self-start">
              Are you sure you want to delete this listing?
            </h1>
            <span>
              Doing so will remove this link and all access to attendance date,
              and it cannot be recovered.
            </span>
            <Banner
              show={showError}
              type="error"
              dataTestId="oh-delete-error-banner"
              showClasses={['tw-my-3']}
            >
              <div className="tw-flex tw-flex-row tw-gap-3 tw-justify-between">
                <div>
                  <h4 className="tw-text-lg tw-font-bold tw-mb-0 tw-text-gray-ravenswood">
                    Error
                  </h4>
                  <p className="tw-text-base tw-font-medium tw-text-gray-ravenswood">
                    Sorry, there was a problem deleting this open house.
                  </p>
                </div>
              </div>
            </Banner>
            <div className="tw-flex tw-justify-center tw-gap-6 tw-mt-6 tw-flex-col md:tw-flex-row">
              <Button
                type="secondary"
                onClick={handleClose}
                isDisabled={loading}
                className={buttonClasses}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                isLoading={loading}
                isDisabled={loading}
                onClick={async () => {
                  setLoading(true);
                  setShowError(false);
                  const results = await openHouseApi.deleteOpenHouse(id);
                  setLoading(false);
                  if (results.ok) {
                    onClose();
                  } else {
                    setShowError(true);
                  }
                }}
                dataTestId="dt-oh-delete-confirmation"
                className={buttonClasses}
              >
                {loading ? 'Deleting ...' : 'Yes, delete'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { OpenHouseDeleteConfirmation };
