export * as image from './image';
export * as users from './users';
export * as loanOfficers from './loan-officers';
export * as loan from './loan';
export * as referAClient from './referral';
export * as openHouse from './open-house';
export * as profile from './profile';
export * as support from './support';
export * as documents from './documents';

export function get(url: string) {
  var headers = new Headers({
    'Content-Type': 'application/json',
  });

  const response = fetch(url, {
    method: 'GET',
    headers: headers,
  });

  return response;
}

export function post(url: string, data?: string) {
  var headers = new Headers({
    'Content-Type': 'application/json',
  });

  const response = fetch(url, {
    method: 'POST',
    headers: headers,
    body: data,
  });

  return response;
}

export function patch(url: string, data?: string) {
  var headers = new Headers({
    'Content-Type': 'application/json',
  });

  const response = fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: data,
  });

  return response;
}

export function dele(url: string, data?: string) {
  var headers = new Headers({
    'Content-Type': 'application/json',
  });

  const response = fetch(url, {
    method: 'DELETE',
    headers: headers,
    body: data,
  });

  return response;
}
