import { addDefaultFetchOptions, requestWithTimeout } from './util';
import type { GetImageValidationResponse } from 'types';

// image validation Cloudflare worker: https://github.com/Guaranteed-Rate/agents-image-validation
const serviceHost = process.env?.REACT_APP_IMAGE_VALIDATION_SERVICE;

const getImageValidation = async (
  url: string
): Promise<GetImageValidationResponse> => {
  const options = addDefaultFetchOptions({
    headers: new Headers({
      'x-application': 'agents',
    }),
  });
  try {
    const response = await requestWithTimeout(serviceHost + url, options);
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    const responseText = await response.text();
    try {
      const responseObj = JSON.parse(responseText);
      if (!response?.ok) {
        throw responseObj || responseText;
      }
      return {
        ok: true,
        errors: responseObj?.errors,
        size: responseObj?.size || 0,
      };
    } catch (responseObj: any) {
      throw responseObj;
    }
  } catch (responseObj: any) {
    if (
      responseObj instanceof Error &&
      responseObj.message === 'Unauthorized'
    ) {
      return {
        ok: false,
        status: 401,
      };
    } else {
      return {
        ok: false,
      };
    }
  }
};

const deleteImage = async (imageType: 'profile' | 'logo') => {
  const imageTypeSlug =
    imageType === 'profile' ? 'profile-image' : 'branding-image';
  const url = `/v1/${imageTypeSlug}`;
  try {
    const response = await requestWithTimeout(
      url,
      addDefaultFetchOptions({
        method: 'DELETE',
      })
    );
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    return { ok: true };
  } catch (responseObj: any) {
    if (
      responseObj instanceof Error &&
      responseObj.message === 'Unauthorized'
    ) {
      return {
        ok: false,
        status: 401,
      };
    } else {
      return {
        ok: false,
      };
    }
  }
};

export { deleteImage, getImageValidation, serviceHost };
