import {
  InputAdornment,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { muiInputOutlinedStyles } from 'styles/mui';

const GrStyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  ...{
    '& .MuiOutlinedInput-input ': {
      '::placeholder': {
        color: '#25282A',
        opacity: 1,
      },
    },
  },
  ...muiInputOutlinedStyles,
}));
const SearchField = (props: {
  searchKey: (e: string) => void;
  doSearch: (e: number) => void;
  classsNames?: string;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { searchKey, doSearch, classsNames = '' } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
    searchKey(event.target.value);
  };
  let textInput = useRef<HTMLInputElement>(null);

  return (
    <GrStyledTextField
      className={classsNames}
      fullWidth={true}
      placeholder="Search"
      size="medium"
      variant="outlined"
      onChange={handleChange}
      value={searchValue}
      onKeyPress={(event) => {
        if (event?.key === 'Enter') {
          doSearch(0);
        }
      }}
      autoFocus
      inputRef={textInput}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className="tw-text-gray-ravenswood" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              display: searchValue === '' ? 'none' : 'flex',
            }}
            onClick={() => {
              setSearchValue('');
              searchKey('');
              textInput.current && textInput.current.focus();
            }}
          >
            <ClearIcon className="tw-cursor-pointer" />
          </InputAdornment>
        ),
        style: {
          height: 55,
        },
      }}
    />
  );
};

export { SearchField };
