import { tabletDimension } from 'Components/Common/Utils';
import { useState, useEffect } from 'react';
interface WindowDimensionsInformation {
  width: number;
  height: number;
  isMobileScreenWidth: boolean;
}

const getWindowDimensions = (): WindowDimensionsInformation => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isMobileScreenWidth: width < tabletDimension,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
