import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';

import { ReactComponent as ChevronDown } from 'Assets/icons/chevron-down-small.svg';
import { ReactComponent as ChevronDownRed } from 'Assets/icons/chevron-down-small-red.svg';

import { muiSelectStyles } from 'styles/mui';
import { OptionProps } from 'types';

const GrStyledFormControl = styled(FormControl)<FormControlProps>(
  ({ theme }) => muiSelectStyles
);

type SelectInputProps = {
  field: string;
  label: string | React.ReactElement;
  dataTestId?: string;
  selectProps: SelectProps;
  options: OptionProps[];
  helperText?: string;
  includeNoneOption: boolean;
  required?: boolean;
};

const SelectInput = (props: SelectInputProps) => {
  const {
    selectProps,
    dataTestId,
    field,
    label,
    options,
    helperText = '',
    includeNoneOption,
    required = false,
  } = props;
  const [menuItems, setMenuItems] = useState<OptionProps[]>(options);

  useEffect(() => {
    const items = [...options];
    if (includeNoneOption) {
      items.unshift({
        value: '',
        label: <em>None</em>,
        disabled: false,
      });
    }
    setMenuItems(items);
  }, [options, includeNoneOption]);

  const selectInputProps = { ...selectProps };
  if (dataTestId) {
    selectInputProps.inputProps = {
      ...selectInputProps?.inputProps,
      ...{ 'data-testid': dataTestId },
    };
  }

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: 10,
      },
    },
  };

  return (
    <GrStyledFormControl variant="filled" fullWidth required={required}>
      <InputLabel htmlFor={field} error={selectProps.error}>
        {label}
      </InputLabel>
      <Select
        labelId={field}
        id={field}
        {...selectInputProps}
        disableUnderline={true}
        MenuProps={MenuProps}
        IconComponent={selectProps?.error ? ChevronDownRed : ChevronDown}
      >
        {menuItems.map((option, index) => (
          <MenuItem
            key={`${field}-option-${index}`}
            value={option.value}
            disabled={option.disabled}
            disableRipple
            disableTouchRipple
            className={option.disabled ? 'tw-opacity-50' : ''}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </GrStyledFormControl>
  );
};

export { SelectInput };
