import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useMatch } from 'react-router-dom';
import { ApiState, DocStorageServiceDocument, LoanType } from 'types';
import useCurrentUser from './UserContext';
import { has } from 'lodash';
import { getDocumentsForEncompassLoanGuid } from 'api/documents';

interface MatchParams {
  params: {
    loanGuid?: string;
  };
}

export interface UseCurrentLoanContextType {
  dispatch: React.Dispatch<LoanAction>;
  loan: LoanType | null;
  loansLoading: boolean;
  documents: ApiState<DocStorageServiceDocument[]>;
  fetchDocuments: () => void;
}

const getInitialApiState = (): ApiState<any> => {
  return {
    data: null,
    isLoading: false,
    error: null,
  };
};

interface CurrentLoanState {
  documents: ApiState<DocStorageServiceDocument[]>;
}

type LoanAction =
  | { type: 'GET_DOCUMENTS' }
  | { type: 'GET_DOCUMENTS_SUCCESS'; payload: DocStorageServiceDocument[] }
  | { type: 'SET_DOCUMENTS_FETCH_ERROR'; payload: Error }
  | { type: 'RESET_DOCUMENTS' };

const currentLoanReducer = (
  state: CurrentLoanState,
  action: LoanAction
): CurrentLoanState => {
  switch (action.type) {
    case 'GET_DOCUMENTS':
      return {
        ...state,
        documents: {
          ...state.documents,
          isLoading: true,
          error: null,
        },
      };
    case 'GET_DOCUMENTS_SUCCESS':
      return {
        ...state,
        documents: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case 'SET_DOCUMENTS_FETCH_ERROR':
      return {
        ...state,
        documents: {
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    case 'RESET_DOCUMENTS':
      return {
        ...state,
        documents: getInitialApiState(),
      };
    default:
      return state;
  }
};

const initialState: CurrentLoanState = {
  documents: getInitialApiState(),
};

// Context that holds information about the individual loan that is currently open in the app.
export const CurrentLoanContext = createContext<UseCurrentLoanContextType>({
  dispatch: () => {},
  loan: null,
  loansLoading: false,
  documents: getInitialApiState(),
  fetchDocuments: () => {},
});

export const CurrentLoanProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(currentLoanReducer, initialState);

  const matchParams = useMatch('/closings/:loanGuid') as MatchParams;

  const loanGuid = useMemo(
    () =>
      has(matchParams, 'params.loanGuid') ? matchParams.params.loanGuid : '',
    [matchParams]
  );
  const { loans, loansLoading } = useCurrentUser();

  const loan: LoanType | null = useMemo(() => {
    if (loans && loanGuid) {
      const loan = loans.find(
        (loan) => loanGuid === loan?.loanDetails?.grLoanGuid
      );
      return loan || null;
    }
    return null;
  }, [loans, loanGuid]);

  const prevLoanIdFetchedForDocuments = useRef<string>(''); // helps prevent re-fetch of documents when loans are re-fetched

  const fetchDocuments = useMemo(() => {
    return async () => {
      dispatch({ type: 'GET_DOCUMENTS' });

      try {
        const encompassGuid = loan?.loanDetails?.encompassLoanGuid;
        const tenant = loan?.company;
        if (!encompassGuid || !tenant) {
          throw new Error(`missing encompassGuid or tenant for the loan`);
        }

        const response = await getDocumentsForEncompassLoanGuid(
          encompassGuid,
          tenant
        );
        if (response.ok) {
          dispatch({
            type: 'GET_DOCUMENTS_SUCCESS',
            payload: response?.documents || [],
          });
        } else {
          dispatch({
            type: 'SET_DOCUMENTS_FETCH_ERROR',
            payload: response.error as Error,
          });
        }
      } catch (error) {
        dispatch({
          type: 'SET_DOCUMENTS_FETCH_ERROR',
          payload: error as Error,
        });
      }
    };
  }, [loan]);

  useEffect(() => {
    // reset loan-specific data if there is no loan
    if (!loan) {
      prevLoanIdFetchedForDocuments.current = '';
      return dispatch({ type: 'RESET_DOCUMENTS' });
    }

    // reset loan-specific data if there is no consent
    if (!loan.consent?.borrower) {
      return dispatch({ type: 'RESET_DOCUMENTS' });
    }

    // else, re-fetch loan-specific data when current loan changes
    if (
      loan.loanDetails?.grLoanGuid !== prevLoanIdFetchedForDocuments.current
    ) {
      prevLoanIdFetchedForDocuments.current =
        loan.loanDetails?.grLoanGuid || '';
      fetchDocuments();
    }
  }, [loan, fetchDocuments]);

  const contextValue: UseCurrentLoanContextType = {
    dispatch,
    loan,
    loansLoading,
    fetchDocuments,
    documents: state.documents,
  };

  return (
    <CurrentLoanContext.Provider value={contextValue}>
      {children}
    </CurrentLoanContext.Provider>
  );
};

export const useCurrentLoan = () => {
  return useContext(CurrentLoanContext);
};
