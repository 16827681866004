import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { muiInputFilledStyles } from 'styles/mui';

const GrStyledTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => muiInputFilledStyles
);

const GrTextField = (props: TextFieldProps) => {
  const fieldProps = { ...props };
  fieldProps.InputLabelProps = {
    ...fieldProps.InputLabelProps,
  };
  fieldProps.InputProps = {
    ...fieldProps.InputProps,
    ...{ disableUnderline: true },
  };
  fieldProps.FormHelperTextProps = {
    role: 'alert',
  };
  return (
    <GrStyledTextField
      color="secondary"
      variant="filled"
      fullWidth
      {...fieldProps}
    />
  );
};

export { GrTextField as TextField };
