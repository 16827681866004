import { FC, Children, cloneElement } from 'react';
import classNames from 'classnames';
import styles from './Section.module.css';
import { SectionProps } from 'types';

function hasMultipleChildren(
  children: JSX.Element | JSX.Element[]
): children is JSX.Element[] {
  return Array.isArray(children);
}

export const Section: FC<SectionProps> = (props) => {
  const { children, className, contentProps = {} } = props;
  if (!children) {
    return null;
  }
  const styledChildren = () => {
    const cloneChildWithStyle = (child: JSX.Element) =>
      cloneElement(child, {
        className: `desktop:tw-mb-8 ${styles.content} ${
          child.props.className || ''
        }`,
      });

    if (hasMultipleChildren(children)) {
      return Children.map(children, cloneChildWithStyle);
    }
    return cloneChildWithStyle(children);
  };

  const { className: contentClassName, ...otherContentProps } = contentProps;

  const outerClasses = ['tw-flex', 'tw-justify-center', 'tw-items-center'];
  const innerClasses = ['tw-flex', 'tw-flex-col', 'desktop:tw-flex-row'];

  let outerClassname = classNames(outerClasses, className);
  let innerClassname = classNames(innerClasses, contentClassName);
  if (props.addMargin) {
    outerClassname = classNames(
      outerClasses,
      'tw-py-8',
      'desktop:tw-py-16',
      'tablet:tw-py-12',
      className
    );
    innerClassname = classNames(
      innerClasses,
      'tw-mx-6',
      'md:tw-mx-0',
      'md:tw-w-2/3',
      'tw-pt-2',
      'desktop:tw-max-w-screen-desktop',
      contentClassName
    );
  }

  return (
    <div className={outerClassname}>
      <div className={innerClassname} {...otherContentProps}>
        {styledChildren()}
      </div>
    </div>
  );
};
Section.defaultProps = {
  addMargin: true,
};
