import { Alert } from 'Components/Alert';
import { AlertProps } from 'Components/Alert/Alert';
import { loanIsInProgress } from 'lib/loans';
import React, { useMemo } from 'react';
import { LoanType } from 'types';
import {
  CONSENT_DENIED_MESSAGE,
  CONSENT_REQUESTED_MESSAGE,
} from './borrower-consent-alert.constants';

interface BorrowerConsentAlertProps
  extends Omit<AlertProps, 'message' | 'show'> {
  loan: LoanType | null | undefined;
}

/*
  if the loan is in progress and consent is not given,
  displays an appropriate warning regarding borrower consent
  (either consent requested or explicitly denied)
*/
export const BorrowerConsentAlert = (props: BorrowerConsentAlertProps) => {
  const { loan } = props;
  const consentAlertMessage: string | null = useMemo(() => {
    if (!loan) {
      return null;
    }
    const isLoanInProgress = loanIsInProgress(loan);
    if (!isLoanInProgress) {
      return null;
    }

    const consent = loan.consent?.borrower;
    if (consent === 1) {
      return null;
    } else if (consent === 0) {
      return CONSENT_DENIED_MESSAGE;
    } else {
      return CONSENT_REQUESTED_MESSAGE;
    }
  }, [loan]);

  return consentAlertMessage ? (
    <Alert {...props} show message={consentAlertMessage} />
  ) : null;
};
