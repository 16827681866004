import { extractJsonApiErrors, requestWithTimeoutAndOktaLogin } from './util';

import type {
  ApiResponse,
  JsonApiType,
  UpdateUserResponseType,
  AgentUser,
  AgentUserCreateType,
  UserSettings,
  EmailVerificationJwtClaims,
  EmailVerificationRequestBody,
  AgentUserCreateResponseType,
} from 'types';

type GetAgentResponse = {
  ok: boolean;
  agent?: AgentUser;
  error?: any;
};

interface JsonApiAgentUser extends JsonApiType {
  attributes: AgentUser;
}

const formatJsonApiAgentUser = (
  jsonApiAgentUser: JsonApiAgentUser
): AgentUser => {
  const agent: any = jsonApiAgentUser?.attributes || {};
  agent.agentId = parseInt(jsonApiAgentUser?.id);
  agent.id = jsonApiAgentUser?.id;
  if (!agent.hasOwnProperty('loginEmail')) {
    agent.loginEmail = '';
  }
  return agent as AgentUser;
};

const getAgentUser = async (): Promise<GetAgentResponse> => {
  try {
    const ret = await requestWithTimeoutAndOktaLogin('/v1/user');
    if (!ret.response?.ok || ret.response.status !== 200) {
      throw ret.responseObj;
    }
    return { ok: true, agent: formatJsonApiAgentUser(ret.responseObj?.data) };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
    };
  }
};

const sendVerificationEmailForSignup = async (
  body: EmailVerificationRequestBody
): Promise<ApiResponse> => {
  if (!!process.env.REACT_APP_TEST_CREATE) {
    if (process.env.REACT_APP_TEST_CREATE === 'OK') {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        jsonApiErrors: [
          {
            code: process.env.REACT_APP_TEST_CREATE || '',
            title: '',
          },
        ],
      };
    }
  }
  try {
    const modifedBody = {
      ...body,
    };
    if (!modifedBody.loanGuid) {
      delete modifedBody.loanGuid;
    }
    const ret = await requestWithTimeoutAndOktaLogin(
      '/v1/send-verification-email',
      {
        method: 'POST',
        body: JSON.stringify(modifedBody),
      }
    );
    if (!ret.response?.ok || ret.response.status !== 200) {
      const jsonApiErrors = await extractJsonApiErrors(ret.response);
      return {
        ok: false,
        jsonApiErrors,
      };
    }
    return {
      ok: true,
    };
  } catch (error) {
    console.error(`failed to create agent user`, error);
    return {
      ok: false,
      error,
    };
  }
};

interface AgentCreateResponse extends ApiResponse {
  agent?: AgentUserCreateResponseType;
}

const createAgentUser = async (
  attributes: AgentUserCreateType
): Promise<AgentCreateResponse> => {
  if (!attributes.ssnLastFour) {
    delete attributes.ssnLastFour;
  }
  const body = JSON.stringify({
    data: {
      type: 'agent',
      attributes,
    },
  });
  if (!!process.env.REACT_APP_TEST_CREATE) {
    if (process.env.REACT_APP_TEST_CREATE === 'OK') {
      const jwt: EmailVerificationJwtClaims = JSON.parse(
        window.atob(attributes.verificationToken.split('.')[1])
      );
      return {
        ok: true,
        agent: {
          firstName: attributes.firstName,
          lastName: attributes.lastName,
          loginEmail: jwt.sub,
        },
      };
    } else {
      return {
        ok: false,
        jsonApiErrors: [
          {
            code: process.env.REACT_APP_TEST_CREATE || '',
            title: '',
          },
        ],
      };
    }
  }
  try {
    const ret = await requestWithTimeoutAndOktaLogin('/v1/user', {
      method: 'POST',
      body,
    });
    if (!ret.response?.ok || ret.response.status !== 201) {
      const jsonApiErrors = await extractJsonApiErrors(ret.response);
      return {
        ok: false,
        jsonApiErrors,
      };
    }
    return {
      ok: true,
      agent: formatJsonApiAgentUser(ret.responseObj?.data),
    };
  } catch (error) {
    console.error(`failed to create agent user`, error);
    return {
      ok: false,
      error,
    };
  }
};

async function updateAgentUser(
  user: any,
  id: string
): Promise<UpdateUserResponseType> {
  const payload = user;
  if (user.mlsAssociationId) {
    payload.mlsAssociationId = parseInt(user.mlsAssociationId);
  } else {
    payload.mlsAssociationId = 0;
  }
  delete payload.agentId;
  try {
    const jsonApiFormattedPayload = {
      data: {
        id: id,
        type: 'agents',
        attributes: payload,
      },
    };
    const response = await fetch('/v1/user', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonApiFormattedPayload),
    });

    const responseObject = await response.json();

    const updateUserResponse: UpdateUserResponseType = {
      statusCode: response.status,
      ok: response.status === 500 ? false : true,
    };

    if (updateUserResponse.ok) {
      if (
        responseObject.hasOwnProperty('data') &&
        responseObject.data.hasOwnProperty('attributes')
      ) {
        updateUserResponse.user = responseObject.data
          .attributes as unknown as AgentUser;
        updateUserResponse.user.agentId = responseObject.data.id;
      }
    } else {
      updateUserResponse.responseStatusText = response.statusText;
    }

    if (responseObject.hasOwnProperty('message')) {
      updateUserResponse.message = responseObject.message;
    }
    if (responseObject.hasOwnProperty('status')) {
      updateUserResponse.status = responseObject.status;
    }

    return updateUserResponse;
  } catch (error) {
    return { ok: false, error };
  }
}
type UpdateSettingsResponse = {
  ok: boolean;
  settings: UserSettings;
};

async function updateSettings(
  agentId: number,
  settings: UserSettings
): Promise<UpdateSettingsResponse> {
  const response = await fetch('/v1/user-settings', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ agentId, ...settings }),
  });
  if (response.status !== 200) {
    return { ok: false, settings };
  }
  return { ok: true, settings };
}

export {
  sendVerificationEmailForSignup,
  createAgentUser as create,
  getAgentUser,
  updateSettings,
  updateAgentUser as updateUser,
};
