import { testDocuments } from 'lib/test-data';
import { inLocalTestModeByEnvVar } from 'lib/util';
import { ApiResponse, DocStorageServiceDocument, JsonApiType } from 'types';
import { requestWithTimeout, requestWithTimeoutAndOktaLogin } from './util';

export interface GetDocumentsResponse extends ApiResponse {
  documents?: DocStorageServiceDocument[];
}

export interface GetDocumentResponse extends ApiResponse {
  blob?: Blob;
}

export interface JsonApiDocStorageServiceDocument extends JsonApiType {
  attributes: DocStorageServiceDocument;
}

export const formatJsonApiDocuments = (
  los: JsonApiDocStorageServiceDocument[]
): DocStorageServiceDocument[] =>
  los.map(
    (item: JsonApiDocStorageServiceDocument) =>
      ({
        documentId: item.attributes.documentId,
        docHolderId: item.attributes?.docHolderId || '',
        encompassAttachmentId: item.attributes?.encompassAttachmentId || '',
        fileName: item.attributes.fileName || '',
        name: item.attributes.name || '',
        description: item.attributes.description || '',
        contentType: item.attributes.contentType || '',
        created: item.attributes?.created || '',
        createdBy: item.attributes?.createdBy || '',
        source: item.attributes?.source || '',
      } as DocStorageServiceDocument)
  );

export const getDocumentsForEncompassLoanGuid = async (
  encompassLoanGuid: string,
  tenant: string
): Promise<GetDocumentsResponse> => {
  if (inLocalTestModeByEnvVar('LOANS')) {
    const documents = testDocuments();
    return {
      ok: true,
      documents,
    };
  }
  try {
    const url = `/v1/loan-docs/${encodeURIComponent(
      tenant
    )}/${encodeURIComponent(encompassLoanGuid)}`;
    const response = await requestWithTimeoutAndOktaLogin(url);
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let documents: DocStorageServiceDocument[] = [];

    if (Array.isArray(response.responseObj?.data)) {
      documents = formatJsonApiDocuments(response.responseObj?.data);
    }

    return {
      ok: true,
      documents,
    };
  } catch (error) {
    console.error(`failed to fetch loan documents`, error);
    return {
      ok: false,
      error,
    };
  }
};

export const downloadLoanDocument = async (
  tenant: string,
  encompassLoanGuid: string,
  document: DocStorageServiceDocument
): Promise<GetDocumentResponse> => {
  const source = document.source || 'managed';
  const { encompassAttachmentId, documentId } = document;
  try {
    const url = new URL(
      `/v1/loan-docs/${encodeURIComponent(tenant)}/${encodeURIComponent(
        encompassLoanGuid
      )}/${encodeURIComponent(documentId)}`,
      `${window.location.protocol}//${window.location.host}`
    );
    url.searchParams.set('source', source);
    if (source === 'unmanaged') {
      if (!encompassAttachmentId) {
        throw new Error(`missing encompassAttachmentId`);
      }
      url.searchParams.set('encompassAttachmentId', encompassAttachmentId);
    }
    const urlString = url.toString();

    const response = await requestWithTimeout(urlString);
    if (!response?.ok || response.status !== 200) {
      throw response;
    }

    const blob = await response.blob();

    return {
      ok: true,
      blob,
    };
  } catch (error) {
    console.error(
      `failed to fetch loan document ${documentId} (${source} / ${encompassAttachmentId}) for loan ${encompassLoanGuid} in tenant ${tenant}`,
      error
    );
    return {
      ok: false,
      error,
    };
  }
};
