import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';
import { LinkButton } from 'Components/Button';
import useAppContext from 'contexts/AppContext';
import {
  ShellAppHandleLoanDetailMessage,
  sendMessageToShellApp,
} from 'lib/shell-app';

const ChevronRightIconButton = (props: {
  pathname: string;
  ShellAppMessage?: ShellAppHandleLoanDetailMessage;
  isSendMessageToShellApp?: boolean;
}) => {
  const { pathname, ShellAppMessage, isSendMessageToShellApp = false } = props;
  const { isRunningInShellApp, setIsPrevUrlDashboard } = useAppContext();
  const navigate = useNavigate();

  return (
    <LinkButton
      onClick={() => {
        if (isRunningInShellApp && isSendMessageToShellApp && ShellAppMessage) {
          setIsPrevUrlDashboard(true);
          sendMessageToShellApp(ShellAppMessage);
        } else {
          navigate(pathname);
        }
      }}
      className={classNames([
        'tw-text-gray-ravenswood',
        'focus:tw-text-gray-ravenswood',
        'hover:tw-text-gray-ravenswood',
        'active:tw-text-gray-ravenswood',
        'tw-transition-all',
        'tw-duration-50',
        'tw-ease-in-out',
        'tw-w-8',
        'tw-h-8',
        'tw-rounded-full',
        'tw-flex',
        'tw-justify-center',
        'tw-items-center',
        'hover:tw-bg-bluegrey-hover',
        'active:tw-scale-90',
      ])}
    >
      <ChevronRight />
    </LinkButton>
  );
};

export { ChevronRightIconButton };
