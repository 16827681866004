import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

import useAppContext from 'contexts/AppContext';
import useCurrentUser from 'contexts/UserContext';
import { dispatchTrackingEvent } from 'lib/tracking';

type MetaProps = {
  title?: string;
  pageInstanceID?: string;
  primaryCategory?: string;
  pageType?: string;
};

export const Meta = (props: MetaProps) => {
  const { tenant } = useAppContext();
  const { user } = useCurrentUser();
  const {
    title = '',
    pageInstanceID = '',
    primaryCategory = '',
    pageType = '',
  } = props;
  const appName = 'Rate Agents';
  let titleParts = [appName, tenant.companyName];
  if (title) {
    titleParts.unshift(title);
  }
  const langAndRegion = {
    country: 'US',
    language: 'en-US',
  };
  const pageName = `${appName} - ${title}`;

  useEffect(() => {
    dispatchTrackingEvent({
      pageInstanceID: pageInstanceID || pageName,
      page: {
        pageInfo: {
          ...{
            pageName,
            company: tenant.id,
            host: window.location.host,
          },
          ...langAndRegion,
        },
        category: {
          primaryCategory,
          pageType,
        },
        attributes: langAndRegion,
      },
      user,
    });
  }, []);

  return (
    <Helmet>
      <title>{titleParts.join(' | ')}</title>
    </Helmet>
  );
};
