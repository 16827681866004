import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const DsmTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4A4A4A',
    boxShadow: theme.shadows[4],
    fontSize: 15,
    padding: 18,
    border: '1px solid #d8d8d8',
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: 'transparent',
    '&:before': {
      border: '1px solid #d8d8d8',
    },
    color: theme.palette.common.white,
  },
}));

export { DsmTooltip as Tooltip };
