import { map } from 'lodash';
import { get } from '.';

import { requestWithTimeoutAndOktaLogin } from './util';
import type { ApiResponse, JsonApiType, LoanOfficer } from 'types';

interface JsonApiLoanOfficer extends JsonApiType {
  attributes: {
    loanOfficerId: string;
    company: string;
    firstName: string;
    lastName: string;
    address?: {
      line1?: string;
      line2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
    title?: string;
    officePhone?: string;
    mobilePhone?: string;
    email: string;
    employeeStatus: string;
    photoUrl?: string;
  };
}

const formatJsonApiLoanOfficers = (los: JsonApiLoanOfficer[]): LoanOfficer[] =>
  los.map(
    (item: JsonApiLoanOfficer) =>
      ({
        company: item.attributes.company,
        employeeId: parseInt(item.attributes.loanOfficerId),
        email: item.attributes.email,
        firstName: item.attributes.firstName,
        lastName: item.attributes.lastName,
        fullName: `${item.attributes.firstName} ${item.attributes.lastName}`,
        nmlsId: item.id,
        officePhone: item.attributes?.officePhone || '',
        city: item.attributes?.address?.city || '',
        state: item.attributes?.address?.state || '',
        title: item.attributes?.title || '',
        photoUrl: item.attributes?.photoUrl || '',
      } as LoanOfficer)
  );

type SearchParams = {
  searchTerm: string;
  offset: string;
};
interface ErrorObject {
  code: string;
  title: string;
}

interface ErrorResponse {
  errors: ErrorObject[];
}

interface SearchResponse extends ApiResponse {
  loanOfficers?: LoanOfficer[];
  serverError?: ErrorResponse;
}

async function search({
  searchTerm,
  offset,
}: SearchParams): Promise<SearchResponse> {
  const url =
    '/v1/loan-officers/search?search-term=' + searchTerm + '&offset=' + offset;
  try {
    const response = await requestWithTimeoutAndOktaLogin(url);
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let loanOfficers: LoanOfficer[] = [];

    if (Array.isArray(response.responseObj?.data)) {
      loanOfficers = formatJsonApiLoanOfficers(response.responseObj.data);
    }
    return { ok: true, loanOfficers };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
      serverError: responseObj,
    };
  }
}
async function active(): Promise<any> {
  const response = await get('/v1/active-loan-officers');

  if (response.status !== 200) {
    return { ok: false, results: [] };
  }

  const rawJson = await response.json();
  const results = map(rawJson);

  return { ok: true, results };
}

export { active, search };
