import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { linkClasses, activeSideNavClasses } from './styles';

type AuthMainNavItemProps = {
  to: string;
  label: string;
};

const AuthMainNavItem = ({ to, label }: AuthMainNavItemProps) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      classNames(
        isActive ? [...linkClasses, ...activeSideNavClasses] : linkClasses
      )
    }
  >
    {label}
  </NavLink>
);

const mainNavOptions: AuthMainNavItemProps[] = [
  {
    to: '/dashboard',
    label: 'Dashboard',
  },
  { to: '/closings', label: 'Closings' },
  { to: '/tools', label: 'Tools' },
  {
    to: '/marketing-request',
    label: 'Marketing',
  },
];

const AuthMainNav = () => {
  return (
    <div className="tw-hidden md:tw-flex tw-flex-row tw-bg-white tw-basis-1/2 tw-mt-1">
      <nav>
        {mainNavOptions.map((sideNavOption) => (
          <AuthMainNavItem {...sideNavOption} key={sideNavOption.to} />
        ))}
      </nav>
    </div>
  );
};

export { AuthMainNav };
