import { forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import WarningTriangleIcon from 'Assets/icons/warning-triangle.svg';
import ErrorTriangleIcon from 'Assets/icons/error-triangle.svg';
import CircleTickIcon from 'Assets/icons/check-tick-circle.svg';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert ref={ref} variant="filled" {...props} />;
});

function TransitionRight(props: any) {
  return <Slide {...props} direction="up" />;
}

const Toast = (props: {
  message: React.ReactNode | string;
  open: boolean;
  onClose: () => void;
  severity: AlertColor;
}) => {
  let icon;
  let sx = {
    borderRadius: '0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#25282A',
    boxShadow: '0 3px 12px 1px rgba(37,40,42,0.15)',
  };
  switch (props.severity) {
    case 'success':
      icon = CircleTickIcon;
      sx = {
        ...sx,
        ...{
          width: '100%',
          backgroundColor: '#edf6f5',
          color: '#25282A',
          border: '1px solid #53A8A0',
        },
      };
      break;
    case 'error':
      icon = ErrorTriangleIcon;
      sx = {
        ...sx,
        ...{
          width: '100%',
          backgroundColor: '#f7e9ed',
          border: '1px solid #C12950',
          color: '#25282A',
        },
      };
      break;
    case 'info':
      icon = WarningTriangleIcon;
      sx = {
        ...sx,
        ...{
          width: '100%',
          backgroundColor: '#e7f2f5',
          borderColor: '#398ead',
          border: '1px solid #1780A3',
        },
      };
      break;
    case 'warning':
      icon = WarningTriangleIcon;
      sx = {
        ...sx,
        ...{
          width: '100%',
          backgroundColor: '#fff5e8',
          borderColor: '#ffb449',
          border: '1px solid #FFAA2B',
        },
      };
      break;
  }
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={TransitionRight}
      className="tw-mx-2 tw-mb-8"
    >
      <Alert
        onClose={props.onClose}
        severity={props.severity}
        sx={sx}
        icon={
          <img
            src={icon}
            alt=""
            style={{ alignSelf: 'baseline' }}
            className="tw-max-w-none tw-pt-0.5"
          />
        }
        elevation={0}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export { Toast };
