import { SelectChangeEvent } from '@mui/material/Select';

import { usStates } from 'Components/Common/Utils';
import { SelectInput } from './SelectInput';

const StateSelect = (props: {
  id: string;
  value: string;
  dataTestId?: string;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (event: SelectChangeEvent<any>) => void;
  error?: boolean;
  label?: string;
  helperText?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  existingLicenseStates?: string[];
}) => {
  const {
    id,
    value,
    onBlur,
    onChange,
    error,
    label,
    helperText,
    name,
    disabled = false,
    required = false,
    existingLicenseStates = [],
  } = props;

  const options = usStates.map((state) => ({
    value: state.abbreviation,
    label: state.fullName,
    disabled: existingLicenseStates.includes(state.abbreviation),
  }));
  return (
    <SelectInput
      field="state"
      label={label || 'State'}
      dataTestId={props.dataTestId}
      options={options}
      selectProps={{ id, value, onBlur, onChange, error, name, disabled }}
      includeNoneOption={true}
      helperText={helperText}
      required={required}
    />
  );
};

export { StateSelect };
