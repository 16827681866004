import { Header3 } from 'Components';

import { ImageUploadField } from '../../ImageUploadField/ImageUploadField';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash-blue.svg';
import { confirmationDialogConfig } from 'types';

export function ImagesSection(props: {
  photoUrl: string;
  profileImageFile: File | undefined;
  logoUrl: string;
  brandingImageFile: File | undefined;
  setConfirmationConfig: (config: confirmationDialogConfig) => void;
  onProfilePhotoChange: (file: File | undefined) => void;
  onBrandingPhotoChange: (file: File | undefined) => void;
  isBrandingPhotoValid: (value: boolean) => void;
  isProfilePhotoValid: (value: boolean) => void;
}) {
  const {
    photoUrl,
    profileImageFile,
    logoUrl,
    brandingImageFile,
    setConfirmationConfig,
    onProfilePhotoChange,
    onBrandingPhotoChange,
    isProfilePhotoValid,
    isBrandingPhotoValid,
  } = props;
  const deleteHeadShot = (url: string | undefined) => {
    setConfirmationConfig({
      show: true,
      text: `Are you sure you want to delete your headshot`,
      type: 'Headshot',
      subText:
        'This will remove the image from your account once you click the save button.',
      isDelete: true,
    });
  };
  const deleteBrandingImage = (url: string | undefined) => {
    setConfirmationConfig({
      show: true,
      text: `Are you sure you want to delete your branding image`,
      subText:
        'This will remove the image from your account once you click the save button.',
      type: 'Branding',
      isDelete: true,
    });
  };
  return (
    <div>
      <Header3>Images</Header3>
      <div className="tw-pb-4 md:tw-pb-11 md:tw-pb-3 tw-grid tw-grid-cols-2 tw-gap-6">
        <div className="tw-flex tw-flex-col">
          <span className="text-gray-ravenswood tw-mb-1">Headshot</span>
          <ImageUploadField
            descriptor=""
            buttonText="Browse files"
            roundedPreview={false}
            existingUrl={photoUrl}
            inputName="profileImageFile"
            value={profileImageFile}
            onChange={(newFile) => {
              onProfilePhotoChange(newFile);
            }}
            className="tw-mb-1"
            setValidationError={(value) => {
              isProfilePhotoValid(value);
            }}
            dataTestId="profile-edit-profileImageFile"
          />
          {(photoUrl || profileImageFile) && (
            <div
              className="tw-font-bold tw-text-blue-link-darker tw-cursor-pointer tw-flex tw-items-center tw-w-fit"
              onClick={() => deleteHeadShot(photoUrl)}
            >
              <TrashIcon className="tw-text-blue-link-darker tw-text-base tw-font-bold" />
              <p
                className="tw-mb-0 tw-ml-1 tw-font-bold"
                data-testid="dt-edit-deleteHeadShot"
              >
                Delete image
              </p>
            </div>
          )}
        </div>
        <div className="tw-flex tw-flex-col">
          <span className="text-gray-ravenswood tw-mb-1">Branding image</span>
          <ImageUploadField
            descriptor=""
            buttonText="Browse files"
            roundedPreview={false}
            existingUrl={logoUrl}
            inputName="brandingImageFile"
            value={brandingImageFile}
            onChange={(newFile) => {
              onBrandingPhotoChange(newFile);
            }}
            className="tw-mb-1"
            setValidationError={(value) => {
              isBrandingPhotoValid(value);
            }}
            dataTestId="dt-edit-brandingImageFile"
          />{' '}
          {(logoUrl || brandingImageFile) && (
            <div
              className="tw-font-bold tw-text-blue-link-darker tw-cursor-pointer tw-flex tw-items-center tw-w-fit"
              onClick={() => deleteBrandingImage(logoUrl)}
            >
              <TrashIcon className="tw-text-blue-link-darker tw-text-base tw-font-bold" />
              <p
                className="tw-mb-0 tw-ml-1 tw-font-bold"
                data-testid="dt-edit-Branding"
              >
                Delete image
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
