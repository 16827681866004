import { Header1 } from 'Components';
import { NodeOrString } from 'types';

type TitleProps = {
  title: NodeOrString;
  subtitle?: NodeOrString;
};

export function Title(props: TitleProps) {
  const { title, subtitle } = props;

  return (
    <>
      <Header1>{title}</Header1>
      {subtitle && <p className="tw-text-lg tw-text-gray-text">{subtitle}</p>}
    </>
  );
}
