function dispatchTrackingEvent(data: any): void {
  window.digitalData = {
    ...{
      project: 'Agent Advantage',
    },
    ...data,
  };
  document.dispatchEvent(new CustomEvent(data.eventName, { detail: data }));
}

export { dispatchTrackingEvent };
