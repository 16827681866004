import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { Alert, Button } from 'Components';
import {
  referralSchema,
  regex,
  schema,
  messages as validationMessages,
} from '../../../lib/validation';
import useCurrentUser from 'contexts/UserContext';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import {
  AgentUser,
  AgentUserStateLicense,
  AgentUserTeamMember,
  AlertMessage,
  AlertSeverity,
  confirmationDialogConfig,
  MLSAssociation,
} from 'types';
import {
  image as imageApi,
  profile as profileApi,
  users as userApi,
} from 'api';
import { dispatchTrackingEvent } from 'lib/tracking';
import { ImagesSection } from './ImageSection';
import { AgentInfoSection } from './AgentInfoSection';
import { LicenseListSection } from './LicenseListSection';
import { TeamMembersListSection } from './TeamMembersListSection';
import { sendMessageToShellApp } from 'lib/shell-app';
import { usStates } from 'Components/Common/Utils';

interface StateFullNameMap {
  [abbreviation: string]: string;
}

type profileeditProps = {
  title: string;
  subTitle: string;
};
export function AgentProfileEditForm(props: profileeditProps) {
  const { title, subTitle } = props;
  const { mlsAssociations, user, setUser } = useCurrentUser();
  const [alertCommonSeverity, setAlertCommonSeverity] =
    useState<AlertSeverity>('error');
  const [profilePhotoValid, setProfilePhotoValid] = useState<boolean>(true);
  const [brandingPhotoValid, setBrandingPhotoValid] = useState<boolean>(true);
  const [alertCommonMessage, setAlertCommonMessage] =
    useState<AlertMessage[]>();
  const [showCommonAlert, setShowCommonAlert] = useState<boolean>(false);
  const [confirmationConfig, setConfirmationConfig] =
    useState<confirmationDialogConfig>({
      index: 0,
      show: false,
      text: '',
      type: 'TeamMember',
      subText: '',
      isDelete: false,
    });
  const [loading, setLoading] = useState<boolean>(false);
  const [licenseHasErrors, setLicenseHasErrors] = useState(false);
  const [isInvalidFormAlert, setisInvalidFormAlert] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const navigate = useNavigate();
  const profileEditForm = {
      profileFirstName: '',
      profileLastName: '',
      profileTitle: '',
      profileBrokerage: '',
      cellPhone: '',
      officePhone: '',
      address1: '',
      address2: '',
      city: '',
      profileState: '',
      profileImageFile: undefined,
      brandingImageFile: undefined,
      profileZip: '',
      website: '',
      logoUrl: '',
      photoUrl: '',
      mlsAssociation: [] as MLSAssociation[],
      mlsAssociationId: '' as string | undefined,
      profileMLSAgentId: '',
      license: [] as AgentUserStateLicense[],
      teamMembers: [] as AgentUserTeamMember[],
      deleteProfile: false,
      deleteBranding: false,
    },
    validationSchema = Yup.object().shape({
      profileFirstName: Yup.string().required(validationMessages.required),
      profileLastName: Yup.string().required(validationMessages.required),
      cellPhone: Yup.string()
        .max(
          referralSchema.clientPhone.max,
          validationMessages.max(referralSchema.clientPhone.max)
        )
        .matches(regex.phone, validationMessages.phone),
      officePhone: Yup.string()
        .max(
          referralSchema.clientPhone.max,
          validationMessages.max(referralSchema.clientPhone.max)
        )
        .matches(regex.phone, validationMessages.phone),
      website: Yup.string()
        .matches(regex.website, validationMessages.website)
        .max(schema.website.max, validationMessages.max(schema.website.max)),
      profileZip: Yup.string().matches(
        regex.zipCode,
        validationMessages.zipCode
      ),
      teamMembers: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required(validationMessages.required),
          lastName: Yup.string().required(validationMessages.required),
          email: Yup.string()
            .required(validationMessages.required)
            .email(validationMessages.emailFormat),
          phone: Yup.string()
            .max(
              referralSchema.clientPhone.max,
              validationMessages.max(referralSchema.clientPhone.max)
            )
            .matches(regex.phone, validationMessages.phone),
        })
      ),
      license: Yup.array().of(
        Yup.object().shape({
          state: Yup.string().required(validationMessages.required),
          content: Yup.string().required(validationMessages.required),
        })
      ),
    }),
    formik = useFormik({
      initialValues: profileEditForm,
      validationSchema: validationSchema,
      onSubmit: async (values) => {},
    });
  const handleSubmit = async (formik: any) => {
    setLoading(true);
    const licenses = JSON.parse(JSON.stringify(formik.values.license))
      .filter((lic: AgentUserStateLicense) => {
        return lic.visible && lic.state && lic.content;
      })
      .map((lic: AgentUserStateLicense) => {
        delete lic.visible;
        delete lic.visibleIndex;
        return lic;
      });
    const teamMembers = JSON.parse(JSON.stringify(formik.values.teamMembers))
      .filter((teamMember: AgentUserTeamMember) => {
        return (
          teamMember.visible &&
          teamMember.firstName &&
          teamMember.lastName &&
          teamMember.email
        );
      })
      .map((teamMember: AgentUserTeamMember) => {
        delete teamMember.visible;
        delete teamMember.visibleIndex;
        return teamMember;
      });
    const commonAlertMessages: AlertMessage[] = [];
    formik.values.deleteProfile && deleteHeadshot();
    formik.values.deleteBranding && deleteBranding();
    const [profileImageResult, brandingImageResult] = await Promise.all([
      formik.values.profileImageFile &&
        profileApi.updateProfileImage(formik.values.profileImageFile as File),
      formik.values.brandingImageFile &&
        profileApi.updateBrandingImage(formik.values.brandingImageFile as File),
    ]);
    if (profileImageResult) {
      if (profileImageResult.ok) {
        if (profileImageResult.profileImgUrl) {
          formik.values.photoUrl = profileImageResult.profileImgUrl;
        }
        formik.setFieldValue('profileImageFile', undefined);
      } else {
        commonAlertMessages.push('Profile image upload failed.');
      }
    }
    if (brandingImageResult) {
      if (brandingImageResult.ok) {
        if (brandingImageResult.brandingImgUrl) {
          formik.values.logoUrl = brandingImageResult.brandingImgUrl;
        }
        formik.setFieldValue('brandingImageFile', undefined);
      } else {
        commonAlertMessages.push('Branding image upload failed.');
      }
    }
    const requestBody = {
      firstName: formik.values.profileFirstName,
      lastName: formik.values.profileLastName,
      brokerageName: formik.values.profileBrokerage,
      state: formik.values.profileState,
      photoUrl: !formik.values.deleteProfile
        ? profileImageResult?.profileImgUrl || formik.values.photoUrl || ''
        : '',
      logoUrl: !formik.values.deleteBranding
        ? brandingImageResult?.brandingImgUrl || formik.values.logoUrl || ''
        : '',
      title: formik.values.profileTitle,
      mobilePhone: formik.values.cellPhone,
      officePhone: formik.values.officePhone,
      address1: formik.values.address1,
      address2: formik.values.address2,
      city: formik.values.city,
      zip: formik.values.profileZip,
      website: formik.values.website,
      mlsAssociationId: formik.values.mlsAssociationId,
      mlsAgentId: formik.values.profileMLSAgentId,
      licenses: licenses,
      teamMembers: teamMembers,
    };
    try {
      const updateUserResponse = await userApi.updateUser(
        requestBody,
        user?.agentId?.toString() || ''
      );
      if (updateUserResponse.ok) {
        setLoading(false);
        sendMessageToShellApp({ type: 'LOAD_AGENTS_PROFILE' });
        const updateUser: AgentUser = {
          ...{
            loginEmail: '',
            firstName: '',
            lastName: '',
          },
          ...updateUserResponse.user,
        };
        setUser(updateUser);
        const currentUrl = window.location.pathname.includes(
          'marketing-request'
        )
          ? '/marketing-request/confirm-information'
          : '/professional-profile';
        navigate(currentUrl, {
          state: 'Success! Your changes have been saved.',
        });
        return;
      } else {
        commonAlertMessages.push(
          <>
            There was an error saving your changes. Contact us for{' '}
            <Link
              className="tw-text-gray-ravenswood hover:tw-text-gray-ravenswood tw-underline"
              to="/help"
              state={{
                subject: 'Profile update failure',
              }}
              data-testid="profile-update-error-alert-email-link"
            >
              help & support
            </Link>
            .
          </>
        );
      }
      dispatchTrackingEvent({
        eventName: 'updateProfile',
        user: requestBody,
        updateUserResponse,
      });
    } catch (errorMessage) {
      setLoading(false);
      if (typeof errorMessage === 'string') {
        commonAlertMessages.push(errorMessage);
      } else {
        commonAlertMessages.push(JSON.stringify(errorMessage));
      }
    }
    if (commonAlertMessages?.length) {
      setAlertCommonSeverity('error');
      setShowCommonAlert(true);
      setAlertCommonMessage(commonAlertMessages);
    }
    scrollToTop();
  };
  useEffect(() => {
    if (Object.keys(formik.errors).length > 0 && Object.keys(formik.touched).length > 0) {
      setisInvalidFormAlert(true);
    } else {
      setisInvalidFormAlert(false);
    }
  }, [formik.errors, formik.touched]);
  const formIsInvalid = (touched: any) => {
    let disabled = false;
    if (!profilePhotoValid || !brandingPhotoValid) {
      return true;
    }
    if (Object.keys(touched).length) {
      const licenseErrors: any = formik.errors.license;
      const licenseValues: any = formik.values.license;
      licenseErrors?.forEach((element: any, index: number) => {
        if (licenseValues[index]?.visible && element !== undefined) {
          if (touched[`state${index}`] || touched[`content${index}`]) {
            disabled = true;
          }
        }
      });
      if (licenseHasErrors) {
        disabled = true;
      }
      const teamMembersErrors: any = formik.errors.teamMembers;
      const teamMembersValues: any = formik.values.teamMembers;
      teamMembersErrors?.forEach((element: any, index: number) => {
        if (teamMembersValues[index]?.visible && element !== undefined) {
          if (
            touched[`firstName${index}`] ||
            touched[`lastName${index}`] ||
            touched[`email${index}`] ||
            touched[`title${index}`] ||
            touched[`phone${index}`]
          ) {
            disabled = true;
          }
        }
      });
      if (
        formik.errors.profileFirstName ||
        formik.errors.profileLastName ||
        formik.errors.cellPhone ||
        formik.errors.officePhone ||
        formik.errors.profileZip ||
        formik.errors.website
      ) {
        disabled = true;
      }
    } else {
      disabled = false;
    }
    return disabled;
  };

  const deleteTeamMember = (index: number) => {
    const filteredMembers = formik.values.teamMembers.map(
      (member: AgentUserTeamMember, ind) => {
        if (ind === index) {
          member.visible = false;
        }
        return member;
      }
    );
    formik.setFieldValue('teamMembers', filteredMembers);
    setTimeout(() => {
      formik.validateForm();
    });
  };
  const deleteLicense = (index: number) => {
    const filteredLicenses = formik.values.license.map(
      (value: AgentUserStateLicense, ind) => {
        if (ind === index) {
          value.visible = false;
          value.content = '';
          value.state = '';
        }
        return value;
      }
    );
    formik.setFieldValue('license', filteredLicenses);
    setTimeout(() => {
      formik.validateForm();
    });
  };
  const deleteHeadshot = async () => {
    const response = await imageApi.deleteImage('profile');
    if (response.ok) {
      return true;
    } else {
      setAlertCommonMessage([
        'There was an error deleting your branding image.',
      ]);
      return false;
    }
  };
  const deleteBranding = async () => {
    const response = await imageApi.deleteImage('logo');
    if (response.ok) {
      return true;
    } else {
      setAlertCommonMessage([
        'There was an error deleting your branding image.',
      ]);
      return false;
    }
  };
  const updateConfirmationConfig = (config: confirmationDialogConfig) => {
    if (config.isDelete) {
      switch (config.type) {
        case 'License':
          deleteLicense(config.index || 0);
          break;
        case 'TeamMember':
          deleteTeamMember(config.index || 0);
          break;
        case 'Headshot':
          formik.setFieldValue('deleteProfile', true);
          formik.setFieldValue('profileImageFile', undefined);
          formik.setFieldValue('photoUrl', '');
          break;
        case 'Branding':
          formik.setFieldValue('deleteBranding', true);
          formik.setFieldValue('brandingImageFile', undefined);
          formik.setFieldValue('logoUrl', '');
          break;
        default:
          break;
      }
    }
    setConfirmationConfig(config);
  };
  const setProfilePhoto = (newFile: File | undefined) => {
    formik.setFieldValue('profileImageFile', newFile);
    formik.setFieldValue('deleteProfile', false);
  };
  const setBrandingPhoto = (newFile: File | undefined) => {
    formik.setFieldValue('brandingImageFile', newFile);
    formik.setFieldValue('deleteBranding', false);
  };
  const isBrandingPhotoValid = (value: boolean) => {
    setBrandingPhotoValid(!value);
  };
  const isProfilePhotoValid = (value: boolean) => {
    setProfilePhotoValid(!value);
  };
  const getSortedLicenses = (licenseArray: AgentUserStateLicense[]) => {
    const stateFullNameMap: StateFullNameMap = usStates.reduce((map, state) => {
      map[state.abbreviation] = state.fullName;
      return map;
    }, {} as StateFullNameMap);

    licenseArray.sort((a, b) => {
      const fullNameA = stateFullNameMap[a.state] || '';
      const fullNameB = stateFullNameMap[b.state] || '';
      return fullNameA.localeCompare(fullNameB);
    });
    return licenseArray;
  };
  const handleLicenseErrorChange = (errors: boolean) => {
    setLicenseHasErrors(errors);
  };
  const setValues = formik.setValues;
  useEffect(() => {
    setValues({
      profileFirstName: user?.firstName || '',
      profileLastName: user?.lastName || '',
      profileBrokerage: user?.brokerageName || '',
      profileState: user?.state || '',
      profileImageFile: undefined,
      brandingImageFile: undefined,
      profileTitle: user?.title || '',
      cellPhone: user?.mobilePhone || '',
      officePhone: user?.officePhone || '',
      address1: user?.address1 || '',
      address2: user?.address2 || '',
      city: user?.city || '',
      profileZip: user?.zip || '',
      website: user?.website || '',
      mlsAssociation: mlsAssociations || [],
      profileMLSAgentId: user?.mlsAgentId || '',
      license: getSortedLicenses(user?.licenses || []),
      teamMembers: user?.teamMembers || [],
      logoUrl: user?.logoUrl || '',
      photoUrl: user?.photoUrl || '',
      deleteBranding: false,
      deleteProfile: false,
      mlsAssociationId: user?.mlsAssociationId?.toString(),
    });
  }, [user, mlsAssociations, setValues]);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', scrollToTop);
    };
  }, []);
  return (
    <div className="tw-pb-4 md:tw-px-0" data-testid="td-profile-edit">
      <h2 className="md:tw-mb-2 tw-font-extrabold tw-text-3xl md:tw-text-5xl md:tw-font-black tw-text-gray-ravenswood tw-tracking-tight">
        {title}
      </h2>
      <div className="tw-pb-4 md:tw-pb-6 tw-leading-normal">{subTitle}</div>
      {alertCommonMessage && showCommonAlert && (
        <Alert
          show={showCommonAlert}
          severity={alertCommonSeverity}
          showClasses={['tw-flex-grow tw-mb-4']}
          message={
            alertCommonMessage.length === 1 ? (
              alertCommonMessage[0]
            ) : (
              <ul className="tw-ml-3">
                {alertCommonMessage.map((message, index) => (
                  <li className="tw-list-disc tw-whitespace-normal" key={index}>
                    {message}
                  </li>
                ))}
              </ul>
            )
          }
          type="inline"
          controlLabel="Close"
          controlOnClick={() => {
            setAlertCommonMessage([]);
            setShowCommonAlert(false);
          }}
          isCloseIcon={true}
        />
      )}
      <div className="md:tw-py-6 md:tw-px-26 md:tw-border md:tw-border-gray-base md:tw-mt-2 md:tw-rounded-xl">
        <ImagesSection
          photoUrl={formik.values.photoUrl}
          profileImageFile={formik.values.profileImageFile}
          logoUrl={formik.values.logoUrl}
          brandingImageFile={formik.values.brandingImageFile}
          onProfilePhotoChange={(file: File | undefined) =>
            setProfilePhoto(file)
          }
          onBrandingPhotoChange={(file: File | undefined) =>
            setBrandingPhoto(file)
          }
          setConfirmationConfig={setConfirmationConfig}
          isBrandingPhotoValid={isBrandingPhotoValid}
          isProfilePhotoValid={isProfilePhotoValid}
        />
        <AgentInfoSection formik={formik} />
        <LicenseListSection
          formik={formik}
          setConfirmationConfig={setConfirmationConfig}
          deleteWithoutConfirmation={deleteLicense}
          onLicenseErrorChange={handleLicenseErrorChange}
        />
        <TeamMembersListSection
          formik={formik}
          setConfirmationConfig={setConfirmationConfig}
          deleteWithoutConfirmation={deleteTeamMember}
        />
        <div className="md:tw-col-span-2 md:tw-flex md:tw-justify-center md:tw-pb-12 tw-pt-6">
          <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-justify-center tw-w-full">
              <Button
                onClick={() => {
                  handleSubmit(formik);
                  formik.setTouched({ license: [{}] });
                }}
                isDisabled={formIsInvalid(formik.touched) || loading}
                isLoading={loading}
                className={[
                  'tw-gap-1',
                  'tw-py-3',
                  'tw-h-auto',
                  'tw-px-16',
                  'tw-w-full',
                  'md:tw-w-auto',
                ]}
                dataTestId="dt-profile-edit-submit"
              >
                <span className="tw-text-xl tw-contents">Save</span>
              </Button>
            </div>
            <Alert
              show={isInvalidFormAlert}
              severity="error"
              showClasses={['tw-mt-4','tw-items-center']}
              message="Please complete required fields."
              type="inline"
              controlOnClick={() => setisInvalidFormAlert(false)}
              isCloseIcon={true}
              paddingY="1"
            />
          </div>
        </div>
        <DeleteConfirmationDialog
          confirmationConfig={confirmationConfig}
          updateConfirmationConfig={updateConfirmationConfig}
        />
      </div>
    </div>
  );
}
