import { useEffect, useState, Dispatch, SetStateAction } from 'react';

type UseIsElementInViewReturn = [
  setElementRef: Dispatch<SetStateAction<any>>,
  isInView: boolean | null, // will be null if this hook is not ready/still initializing
  elementRef: HTMLElement | undefined
];

/*
  React custom hook that determines whether the specified element is in view. Uses IntersectionObserver in the background.
  Example usage:
  ...
  const [elementRef, setElementRef, isInView] = useIsElementInView();
  useEffect(() => console.log({elementRef, isInView}), [elementRef, isInView]);
  // you might choose to act upon isInView only if it is not null! otherwise, the element/hook may still be initializing and the state of isInView is unknown
  ...
  return (
    <div ref={setElementRef} />
  );
*/
export const useIsElementInView = (): UseIsElementInViewReturn => {
  const [elementRef, setElementRef] = useState<HTMLElement | undefined>(
    undefined
  );
  const [isInView, setIsInView] = useState<boolean | null>(null);

  useEffect(() => {
    if (elementRef && window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsInView(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.1 }
      );

      observer?.observe(elementRef);

      return () => {
        if (elementRef) {
          observer?.unobserve(elementRef);
          setIsInView(null);
        }
      };
    }
  }, [elementRef]);

  return [setElementRef, isInView, elementRef];
};
