import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { muiThemeSettings } from 'styles/mui';

import './styles/index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { AppProvider } from './contexts/AppContext';
import { UserProvider } from './contexts/UserContext';
import { ScrollToTop, Meta } from 'Components';

const theme = createTheme(muiThemeSettings);

render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <AppProvider>
          <UserProvider>
            <Meta />
            <ScrollToTop />
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </UserProvider>
        </AppProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
