type ContactInfoProps = {
  src: string;
  alt: string;
  fontSize?: 'base' | 'sm';
  iconSize?: 4 | 5 | 6;
  applySizeToIcon?: boolean;
  children: React.ReactNode;
};

export const ContactInfo = (props: ContactInfoProps) => {
  const {
    src,
    alt,
    fontSize = 'base',
    iconSize = 4,
    applySizeToIcon = true,
    children,
  } = props;
  return (
    <div className="tw-flex tw-items-start tw-gap-1 tw-pt-1 tw-break-inside-avoid-column">
      <div
        className={`tw-shrink-0 tw-w-${iconSize} tw-flex tw-items-center tw-justify-center tw-pt-0.5`}
      >
        <img
          className={applySizeToIcon ? `tw-h-${iconSize} tw-w-${iconSize}` : ''}
          src={src}
          alt={alt}
        />
      </div>
      <span className={`tw-text-${fontSize} tw-break-all`}>{children}</span>
    </div>
  );
};
