import { sortBy } from 'lodash';

import type { MLSAssociation, OptionProps } from 'types';

const convertMlsAssociationToOptionProp = (
  mlsAssociation: MLSAssociation
): OptionProps => ({
  value: mlsAssociation.id,
  label: mlsAssociation.name,
});

const convertMlsAssociationsToOptionProps = (
  mlsAssociations: MLSAssociation[]
): OptionProps[] =>
  sortBy(
    mlsAssociations.map((item) => convertMlsAssociationToOptionProp(item)),
    ['label']
  );

export { convertMlsAssociationsToOptionProps };
