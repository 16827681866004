import { Meta } from 'Components';

import { ReactComponent as GrSpinner } from 'Assets/icons/gr-spinner.svg';

const LoadingPage = (props: { title: string }) => {
  return (
    <div
      className="tw-flex tw-flex-col tw-h-full tw-justify-between tw-bg-slate-50"
      data-testid="agents-loading-page"
    >
      <Meta title={props.title} />
      <div className="tw-flex tw-justify-center tw-p-3 tw-w-full tw-h-full tw-min-h-screen">
        <GrSpinner />
      </div>
    </div>
  );
};

export { LoadingPage };
