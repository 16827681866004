import { LoanTeamMemberType, LoanTeamLoanOfficer } from 'types';

export const generateLoTestLo = (): LoanTeamLoanOfficer => {
  return {
    id: '12657',
    email: 'testlo@rate.com',
    name: 'Test LO',
    phone: '(555) 555-5555',
    nmlsId: '009097',
    employeeActive: true,
    title: 'Loan Officer',
  };
};

export const generateLoDariusDelgado = (): LoanTeamLoanOfficer => {
  return {
    id: '9097',
    email: 'testlo@rate.com',
    name: 'Darius Delgado',
    phone: '(555) 555-5555',
    nmlsId: '009097',
    employeeActive: true,
    title: 'Loan Officer',
  };
};

export const generateLoExLoanofficer = (): LoanTeamLoanOfficer => {
  return {
    id: '8684',
    name: 'Exe Loanofficer',
    email: 'exe.loanofficer@rate.com',
    phone: '(323) 777-0000',
    photoUrl:
      'https://www.rate.com/_next/image?url=https%3A%2F%2Fa.mktgcdn.com%2Fp%2FwgBlgqyTsmAouZvljtaML734vLoJ31gEI58Sbf_Y55g%2F191x275.jpg&w=256&q=75',
    nmlsId: '008684',
    employeeActive: false,
    title: 'Loan Officer',
  };
};

export const generateLoShantBanosian = (): LoanTeamLoanOfficer => {
  return {
    id: '6068',
    name: 'Shant Banosian',
    email: 'shant@rate.com',
    phone: '(323) 777-0000',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/Shant.jpg',
    nmlsId: '008684',
    employeeActive: true,
    title: 'Regional Manager',
  };
};

export const generateLoTomMaroulis = (): LoanTeamLoanOfficer => {
  return {
    id: '12083',
    name: 'Tom Maroulis',
    email: 'tmaroulis@citywidehm.com',
    photoUrl:
      'https://www.citywidehm.com/_next/image?url=https%3A%2F%2Fa.mktgcdn.com%2Fp%2FdoxmdCGlhlxi-C8E2F587M1WPOVWmsVJH7YGHJfH_wI%2F200x200.png&w=384&q=75',
    employeeActive: false,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoMattCibula = (): LoanTeamLoanOfficer => {
  return {
    id: '3273',
    name: 'Matt Cibula',
    email: 'Matt.cibula@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/mattcibula.jpg',
    employeeActive: false,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoMatthewBanasiak = (): LoanTeamLoanOfficer => {
  return {
    id: '8856',
    name: 'Matthew Banasiak',
    email: 'matthew.banasiak@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/MattBanasiak.jpg',
    employeeActive: true,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoMattTierney = (): LoanTeamLoanOfficer => {
  return {
    id: '17168',
    name: 'Matt Tierney',
    email: 'matt@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/matt.jpg',
    employeeActive: true,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoLorrainePiechnik = (): LoanTeamLoanOfficer => {
  return {
    id: '6303',
    name: 'Lorraine Piechnik',
    email: 'lorraine.piechnik@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/lorrainepiechnik.jpg',
    employeeActive: true,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoLiLi = (): LoanTeamLoanOfficer => {
  return {
    id: '3011',
    name: 'Li Li',
    email: 'lili@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/lili.jpg',
    employeeActive: true,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoJennyLewis = (): LoanTeamLoanOfficer => {
  return {
    id: '9016',
    name: 'Jenny Lewis',
    email: 'jenny.lewis@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/jennylewis.jpg',
    employeeActive: false,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLoJenniferSchenk = (): LoanTeamLoanOfficer => {
  return {
    id: '1385',
    name: 'Jennifer Schenk',
    email: 'jennifer.schenk@rate.com',
    photoUrl: 'https://www.guaranteedrate.com/images/vp/JenniferSchenk.jpg',
    employeeActive: true,
    phone: '(323) 777-0000',
    nmlsId: '008684',
    title: 'Loan Officer',
  };
};

export const generateLcTestLc = (): LoanTeamMemberType => {
  return {
    id: '99901',
    name: 'Cordina Terra',
    email: 'cordina.terra@premiarelocationmortgage.com',
    phone: '(101) 123-0001',
  };
};

export const generateMcTestMc = (): LoanTeamMemberType => {
  return {
    id: '99901',
    name: 'Morty Consula',
    email: 'Morty.Consula@premiarelocationmortgage.com',
    phone: '(202) 123-0002',
  };
};

export const generateCcTestCc = (): LoanTeamMemberType => {
  return {
    id: '99901',
    name: 'Closs Ingcoord',
    email: 'Closs.Ingcoord@premiarelocationmortgage.com',
    phone: '(303) 123-0003',
  };
};

export const generateSaTestSa = (): LoanTeamMemberType => {
  return {
    id: '99901',
    name: 'Sal Assistan',
    email: 'Sal.Assistan@premiarelocationmortgage.com',
    phone: '(404) 123-0004',
  };
};

export const generatePmTestPm = (): LoanTeamMemberType => {
  return {
    id: '99901',
    name: 'Produ Shunman',
    email: 'Produ.Shunman@premiarelocationmortgage.com',
    phone: '(505) 123-0005',
  };
};
