import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Tooltip } from 'Components';
import {
  ProfileSizeClassesSize,
  logoRectClasses,
  profileCircleClasses,
  roundedRectClasses,
} from 'styles/image';
import { image as imageApi } from 'api';
import type { ImageErrorCode, ImageValidationError, NodeOrString } from 'types';
import { filenameExtension, filenameWithoutPath, formatBytes } from 'lib/util';

import { ReactComponent as AvatarPlaceholder } from 'Assets/icons/avatar-placeholder-large.svg';
import { ReactComponent as ImageIcon } from 'Assets/icons/image.svg';
import { ReactComponent as WarningTriangleIcon } from 'Assets/icons/error-triangle.svg';

const formatImageTypeMismatchError = (
  ext: string,
  type: 'gif' | 'jpg' | 'png',
  descriptor: string
) => (
  <>
    The file extension of {descriptor} is <strong>{ext}</strong>, but it's not
    actually a <strong>{type}</strong> image.
  </>
);

const formatErrorMessage = (
  code: ImageErrorCode,
  src: string,
  ext: string,
  size: number,
  descriptor: string
): NodeOrString => {
  let message: NodeOrString = '';
  switch (code) {
    case 'notTotalExpertWhitelistedDomain':
      message = `${descriptor} currently not supported by co-marketing platform. Please edit profile to re-upload image.`;
      break;
    case 'disallowedFileExtension':
      message = `File extension is not one of the allowed file types for the ${descriptor} (i.e., jpeg, jpg, png, gif).`;
      break;
    case 'invalidFilename':
      message = (
        <>
          The {descriptor.toLowerCase()}'s file name,{' '}
          <strong>{filenameWithoutPath(src)}</strong>, is not valid. Image file
          names may only contain letters, numbers, hyphens or underscores;
          spaces are not allowed.
        </>
      );
      break;
    case 'loadFailure':
      message = `${descriptor} ${
        descriptor === 'Headshot' && 'Image'
      } could not be loaded`;
      break;
    case 'sizeTooSmall':
      if (size) {
        message = (
          <>
            <strong>{formatBytes(size)}</strong> is less than the recommended
            size (50 KB) for {descriptor}.
          </>
        );
      }
      break;
    case 'sizeTooBig':
      if (size) {
        message = (
          <>
            <strong>{formatBytes(size)}</strong> is more than the allowed size
            (1 MB) for {descriptor}.
          </>
        );
      }
      break;
    case 'typeMismatchGif':
      message = formatImageTypeMismatchError(ext, 'gif', descriptor);
      break;
    case 'typeMismatchJpg':
      message = formatImageTypeMismatchError(ext, 'jpg', descriptor);
      break;
    case 'typeMismatchPng':
      message = formatImageTypeMismatchError(ext, 'png', descriptor);
      break;
  }
  return message;
};

type PictureProps = {
  src?: string;
  alt?: string;
  descriptor: string;
  size?: ProfileSizeClassesSize;
  variant?: 'rounded' | 'rectangular' | 'roundedRectangular';
  messages?: {
    nonExistent?: NodeOrString;
    loadFailure?: NodeOrString;
  };
  loadTimeout?: number;
  validate?: boolean;
  setValidationMessage?: (message: NodeOrString) => void;
};

const Picture = (props: PictureProps) => {
  const {
    src = '',
    alt = '',
    descriptor = '',
    size = 32,
    variant = 'rounded',
    messages = {},
    validate = false,
    setValidationMessage,
  } = props;
  if (!messages.hasOwnProperty('loadFailure')) {
    messages.loadFailure = (
      <>
        Image at <strong>{src}</strong> could not be loaded.
      </>
    );
  }

  const [isPortrait, setIsPortrait] = useState(true);
  const [loading, setLoading] = useState(!!src);
  const [loadFailed, setLoadFailed] = useState(false);

  const loadFailureMessage = useMemo(
    () => messages?.loadFailure || 'Image could not be loaded',
    [messages]
  );

  const nonExistentMessage = useMemo(
    () => messages?.nonExistent || 'Image does not exist',
    [messages]
  );

  const addonFigureClasses = useMemo(() => {
    const classes = [];
    if (variant === 'rectangular' && (loadFailed || src === '')) {
      classes.push('tw-h-8');
    } else if (!loadFailed && variant === 'roundedRectangular') {
      if (isPortrait) {
        classes.push('tw-max-w-[132px]');
        classes.push('tw-h-48');
      } else {
        classes.push('tw-max-w-[280px]');
        classes.push('tw-max-h-[280px]');
      }
    }
    return classes;
  }, [isPortrait, loadFailed, variant, src]);

  const figureClasses = useMemo(
    () => [
      ...(variant === 'roundedRectangular'
        ? roundedRectClasses
        : variant === 'rounded'
        ? profileCircleClasses(size)
        : logoRectClasses),
      ...[
        'tw-flex',
        'tw-justify-center',
        'tw-items-center',
        'tw-shrink-0',
        'tw-grow-0',
      ],
      ...addonFigureClasses,
    ],
    [addonFigureClasses, size, variant]
  );

  const figureClassesWithTransition = useMemo(
    () => [
      ...figureClasses,
      'tw-transition-all',
      'tw-duration-150',
      'tw-ease-in-out',
    ],
    [figureClasses]
  );

  const imgClasses = useMemo(() => {
    let classes = figureClassesWithTransition;
    if (loading) {
      classes.push('tw-opacity-0');
    } else {
      if (loadFailed) {
        classes.push('tw-hidden');
      } else {
        classes = classes.filter((item) => item !== 'tw-opacity-0');
        classes.push('tw-opacity-1000');
      }
    }
    return classes;
  }, [figureClassesWithTransition, loadFailed, loading]);

  const tooltipTitle = useMemo(() => {
    if (src === '') {
      return nonExistentMessage;
    } else {
      if (!loading) {
        if (loadFailed) {
          return loadFailureMessage;
        } else {
          return alt;
        }
      }
    }
    return '';
  }, [alt, loading, loadFailed, loadFailureMessage, nonExistentMessage, src]);

  const [validationErrors, setValidationErrors] = useState<
    ImageValidationError[]
  >([]);

  useEffect(() => {
    if (src === '') {
      setValidationErrors([] as ImageValidationError[]);
    } else {
      const validateImage = async () => {
        const errors: ImageValidationError[] = [];
        try {
          const response = await imageApi.getImageValidation(src);
          if (!response.ok) {
            throw new Error('Unable to validate image');
          }
          if (response?.errors && response?.errors.length) {
            const ext = filenameExtension(src).toLowerCase();
            for (const error of response.errors) {
              errors.push({
                message: formatErrorMessage(
                  error.code as ImageErrorCode,
                  src,
                  ext,
                  response?.size || 0,
                  descriptor
                ),
                uniqueId: error.code,
              });
            }
          } else {
            if (typeof setValidationMessage === 'function') {
              setValidationMessage('');
            }
          }
        } catch (error) {
          errors.push({
            message: 'Unable to validate image',
            uniqueId: 'validationFailure',
          });
        }
        setValidationErrors(errors);
      };
      if (validate) {
        validateImage();
      }
      const img = new Image();
      img.src = src;
      img
        .decode()
        .then(() => {
          setLoading(false);
          if (variant === 'roundedRectangular') {
            setIsPortrait(img.height > img.width);
          }
        })
        .catch(() => {
          setLoading(false);
          setLoadFailed(true);
        });
    }
  }, [src, descriptor, setLoading, variant, validate, setValidationMessage]);

  useEffect(() => {
    if (typeof setValidationMessage === 'function') {
      if (validationErrors.length) {
        if (validationErrors.length === 1) {
          setValidationMessage(validationErrors[0].message);
        } else {
          setValidationMessage(
            <ul className="tw-ml-3">
              {validationErrors.map((err) => (
                <li
                  className="tw-list-disc tw-whitespace-normal"
                  key={err.uniqueId}
                >
                  {err.message}
                </li>
              ))}
            </ul>
          );
        }
      } else {
        setValidationMessage('');
      }
    }
  }, [validationErrors, setValidationMessage]);

  const figureStyle = src === '' ? { backgroundColor: '#d8d8d8' } : {};

  const placeholderAvatarClassNames =
    'tw-h-10 md:tw-h-[100px] tw-w-[132px] md:tw-w-[192px] tw-rounded-full';

  const PlaceholderAvatar =
    variant === 'rectangular' ? (
      <ImageIcon
        style={{
          filter:
            'invert(94%) sepia(14%) saturate(0%) hue-rotate(189deg) brightness(29%) contrast(91%)',
        }}
      />
    ) : (
      <AvatarPlaceholder className={placeholderAvatarClassNames} />
    );

  return (
    <div
      data-testid="picture-component"
      className="tw-flex tw-flex-row tw-gap-4 tw-justify-center tw-items-center tw-w-full"
    >
      <Tooltip title={tooltipTitle}>
        <figure className={classNames(figureClasses)} style={figureStyle}>
          {src === '' ? (
            PlaceholderAvatar
          ) : (
            <>
              {!loadFailed && (
                <img className={classNames(imgClasses)} src={src} alt={alt} />
              )}
            </>
          )}
          {loadFailed && (
            <WarningTriangleIcon color="warning" fontSize="large" />
          )}
        </figure>
      </Tooltip>
    </div>
  );
};

export { Picture };
