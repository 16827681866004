import { useEffect } from 'react';
import MortgageCalculatorLite from '@guaranteed-rate/react-components/dist/MortgageCalculatorLite';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'lib/hooks';
import '@guaranteed-rate/react-components/dist/styles.css';
import '@guaranteed-rate/react-components/dist/gri.css';

const applyStyles = (element: any, styles: object) => {
  if (element && element instanceof HTMLElement) {
    Object.assign(element.style, styles);
  }
  element.addEventListener('focus', () => {
    element.style.borderColor = '#90BAEC';
  });
  element.addEventListener('blur', () => {
    element.style.borderColor = '#EEF4FC';
  });
};

const setupFieldSetStyles = (width: number) => {
  const paymentFormFieldSet = document.querySelector(
    '.monthlyPaymentForm .paymentForm__fieldset'
  );
  if (!paymentFormFieldSet || !(paymentFormFieldSet instanceof HTMLElement))
    return;

  if (width > 300) {
    applyStyles(paymentFormFieldSet, {
      display: 'grid',
      paddingBottom: '1rem',
      minWidth: 'auto',
    });

    const homePrice = document.querySelector(
      '.monthlyPaymentForm .paymentForm__fieldset > div'
    );
    const commonStyles = {
      border: '1px solid #DEEAF9',
      backgroundColor: '#EEF4FC',
    };

    applyStyles(homePrice, { gridColumn: 'span 2' });
    applyStyles(document.querySelector('#homePrice'), {
      gridColumn: 'span 2',
      ...commonStyles,
    });
    applyStyles(document.querySelector('#downPaymentPercent'), commonStyles);
    applyStyles(document.querySelector('#downPaymentAmount'), commonStyles);
    applyStyles(
      document.querySelector('[role="combobox"] > div'),
      commonStyles
    );
    applyStyles(document.querySelector('#interestRate'), commonStyles);
    applyStyles(document.querySelector('#propertyTaxes'), commonStyles);
    applyStyles(document.querySelector('#insurance'), commonStyles);
    applyStyles(document.querySelector('#hoaFees'), commonStyles);
    applyStyles(document.querySelector('.icon-container'), {
      color: '#2175DA',
    });
    applyStyles(document.querySelector('.menu'), { borderColor: '#90BAEC' });

    document.querySelectorAll('.item-line-heading').forEach((label) => {
      applyStyles(label, { color: '#2175DA' });
    });
  } else {
    applyStyles(paymentFormFieldSet, { display: 'block' });
  }
};

const setupStyles = (width: number) => {
  applyStyles(document.querySelector('.subHeadingFormContainer'), {
    width: '100%',
  });
  applyStyles(document.querySelector('.paymentChart'), {
    border: '1px solid #d8d8d8',
  });
  applyStyles(document.querySelector('.paymentChart > div'), {
    paddingTop: '1rem',
  });

  const referButton = document.querySelector(
    '.monthlyPaymentForm button[type="button"]'
  );
  applyStyles(referButton, { paddingTop: '1rem' });

  setupFieldSetStyles(width);
};

export const MortgageCalculator = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    const handleReferButtonClick = (event: any) => {
      event.preventDefault();
      navigate('/refer-a-client');
    };

    const handleVeteranCheckBoxClick = () => {
      setTimeout(() => {
        const referButton = document.querySelector(
          '.monthlyPaymentForm a[href="&va=1"], .monthlyPaymentForm button'
        );
        if (referButton) {
          referButton.addEventListener('click', handleReferButtonClick);
        }
      }, 500);
    };

    setupStyles(width);

    const referButton = document.querySelector(
      '.monthlyPaymentForm button[type="button"], .monthlyPaymentForm a[href="&va=1"]'
    );
    const veteranCheckBox = document.querySelector(
      '.paymentForm__input--container input[type="checkbox"]'
    );

    if (referButton && veteranCheckBox) {
      referButton.addEventListener('click', handleReferButtonClick);
      veteranCheckBox.addEventListener('click', handleVeteranCheckBoxClick);

      return () => {
        referButton.removeEventListener('click', handleReferButtonClick);
        veteranCheckBox.removeEventListener(
          'click',
          handleVeteranCheckBoxClick
        );
      };
    }
  }, [width, navigate]);
  return (
    <div className="tw-bg-white agent-mortgage-calculator">
      <MortgageCalculatorLite
        additionalInfo=""
        calculatorSettings={{
          downPayment: 60000,
          downPaymentPercent: 20,
          interestRate: '3.75',
          loanTerm: 30,
          militaryVeteran: false,
          purchasePrice: 300000,
        }}
        contentSettings={{
          calculatorSubtitle: '',
          calculatorTitle: 'Monthly payment calculator',
          ctaButtonText: 'Refer client',
          ctaButtonURL: '',
          disclaimerText:
            "Calculated figures are just estimates. Your originating partner can get your client real figures based on today's rates.",
          disclaimerTitle: 'Refer a client',
          viewCalculatorsTitle: '',
          viewCalculatorsURL: '#',
        }}
      />
    </div>
  );
};
