import { Button, Footer, Header, Meta, PageBody, Title } from 'Components';

import rateAgentsQRCode from 'Assets/Images/Pages/Home/qrcode.jpg';

const storeURL = process.env?.REACT_APP_STORE_URL;

const RateAccountAlreadyExists = () => {
  return (
    <>
      <Meta
        title="Create account"
        primaryCategory="account"
        pageType="redirect"
      />
      <Header showNav={false} />
      <PageBody>
        <div
          className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-4 tw-w-full tw-mb-2 md:tw-mt-2"
          data-testid="create-account-page"
        >
          <Title title={<>Looks like you already have a Rate account!</>} />
          <div className="tw-p-8 md:tw-w-[40rem]">
            <p className="tw-text-base tw-mb-4 tw-text-gray-ravenswood">
              To join the Rate Agents network, merge your existing Rate account
              by following these steps:
            </p>
            <ul className="tw-list-disc tw-m-6 marker:tw-text-gray-ravenswood">
              <li>
                Log into the{' '}
                <Button
                  type="quaternary"
                  externalHref={storeURL}
                  targetBlank={true}
                >
                  Rate App
                </Button>{' '}
                with the email address you used here
              </li>
              <li>
                Click the <strong>“I’m a real estate agent!”</strong> button and
                follow the prompts
              </li>
            </ul>
            <p>And that's it!</p>
            <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center tw-gap-5">
              <div>
                <p>Don’t have the Rate app? </p>
                <p className="tw-text-base tw-text-gray-ravenswood">
                  <Button
                    type="quaternary"
                    externalHref={storeURL}
                    targetBlank={true}
                  >
                    Download
                  </Button>{' '}
                  it today!
                </p>
              </div>
              <img
                src={rateAgentsQRCode}
                alt="Download Rate Agents app"
                className="tw-w-20"
              />
            </div>
          </div>
        </div>
      </PageBody>
      <Footer />
    </>
  );
};

export { RateAccountAlreadyExists };
